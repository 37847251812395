import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import "../Overview/PetFamily/InvitePetFamily.css";
import Cookies from "js-cookie";
import axios from "../../../../axios";

export const OwnerProfileModal = ({ isOpen, isClose }) => {
  const loginToken = Cookies.get("loginToken");

  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    pincode: "",
    userId: "",
    createdAt: "",
    id: "",
  });
  const [isModalClosing, setIsModalClosing] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      setIsModalClosing(true);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    // Add logic to save the data (e.g., API call to save changes)
    try {
      await axios.patch("/user/updateuserdata", profileData, {
        headers: {
          Authorization: loginToken, // Ensure the token is prefixed with 'Bearer '
        },
      });
      // Handle success (e.g., show a success message)
    } catch (error) {
      console.error("Error saving owner profile:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const fetchOwnerProfile = async () => {
    try {
      const response = await axios.get("/user/userInfo", {
        headers: {
          Authorization: loginToken, // Ensure the token is prefixed with 'Bearer '
        },
      });
      const { message } = response.data;
      console.log("message:", message);
      setProfileData({
        firstName: message.firstName || "",
        lastName: message.lastName || "",
        email: message.email || "",
        mobile: message.mobile || "",
        city: message.city || "",
        state: message.state || "",
        pincode: message.pincode || "",
        userId: message.userId || "",
        createdAt: message.createdAt || "",
        id: message.id || "",
      });
    } catch (error) {
      console.error("Error fetching owner profile:", error);
    }
  };

  useEffect(() => {
    fetchOwnerProfile();
  }, []);

  const formattedDate = new Date(profileData.createdAt).toLocaleString();

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  return (
    <div
      className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
        <div className="modal-top-header">
          <div className="modal-title">
            <p>
              <DogPettingIcon name={"owner-profile"} /> Owner's Profile
            </p>
          </div>

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="setting-modal-description">
          <div className="modal-input-group">
            <input
              type="text"
              name="firstName"
              value={profileData.firstName}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="lastName"
              value={profileData.lastName}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="mobile"
              value={profileData.mobile}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="city"
              value={profileData.city}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="state"
              value={profileData.state}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="pincode"
              value={profileData.pincode}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="userId"
              value={profileData.userId}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
            <input
              type="text"
              name="createdAt"
              value={formattedDate}
              onChange={handleChange}
              readOnly={!isEditing}
              className={!isEditing ? "input-disabled" : ""}
            />
          </div>
          <div className="modal-control">
            {isEditing ? (
              <button onClick={handleSaveClick}>
                <DogPettingIcon name={"modal-edit"} />
                Save
              </button>
            ) : (
              <button onClick={handleEditClick}>
                <DogPettingIcon name={"modal-edit"} />
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
