import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Homenav from "../../Components/HomeNav/Homenav";
import Header from "../../Components/Header/Header";
import "./Article.css";
import { LuDot } from "react-icons/lu";
import { BsArrowLeft } from "react-icons/bs";
import axios from "../../axios";
import Cookies from "js-cookie";
import { useBookmark } from "../../context/BookmarkContext";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import Toast from "../../Components/Toast/Toast";
import { MdOutlineWatchLater } from "react-icons/md";
export const Article = () => {
  const loginToken = Cookies.get("loginToken");
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { bookmarks, addBookmark, deleteBookmark, isLoading } = useBookmark();
  const [toast, setToast] = useState(null);

  const categoryMapping = {
    health_and_wellness: "Health",
    pet_care: "Pet Care",
    training_and_behaviour: "Training",
  };

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await axios.get(`/user/viewarticle/${id}`, {
          headers: {
            Authorization: loginToken,
          },
        });
        console.log("response: ", response.data.message);
        setArticle(response.data.message);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchArticle();
  }, [id, loginToken]);

  const isBookmarked = bookmarks.some(
    (bookmark) => bookmark.articleId === parseInt(id)
  );
  console.log("isBookmarked: ", isBookmarked);
  console.log("Current article id:", id);
  console.log(
    "Bookmarked article ids:",
    bookmarks.map((bookmark) => bookmark.articleId)
  );

  const handleToggleBookmark = async () => {
    try {
      if (isBookmarked) {
        const bookmarkToDelete = bookmarks.find(
          (bookmark) => bookmark.articleId === parseInt(id)
        );
        if (bookmarkToDelete) {
          await deleteBookmark(bookmarkToDelete.id);
          setToast("Bookmark removed");
        }
      } else {
        await addBookmark(parseInt(id));
        setToast("Bookmark added");
      }
    } catch (error) {
      console.error("Error toggling bookmark:", error);
      setToast("Error updating bookmark");
    }
  };
  console.log(article, "artciles");

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching article</div>;
  }

  if (!article) {
    return <div>Article not found</div>;
  }

  const displayCategory = categoryMapping[article.category] || article.category;

  const formatDuration = (duration) => {
    const roundedDuration = parseFloat(duration).toFixed(1);
    return `${roundedDuration} min read`;
  };

  return (
    <section>
      <section
        style={{
          marginBottom: "15vh",
        }}
      >
        <Header />
      </section>
      {/* <section className="homepage-navspace">
        <Homenav />
      </section> */}
      {error ? (
        <div className="article-page-section">
          <p>No Articles to show</p>
        </div>
      ) : (
        <article className="article-page-section">
          <div className="article-header">
            <div className="go-back-article">
              <Link to={`/`}>
                <p>
                  <BsArrowLeft className="article-back-arrow" /> Go Back
                </p>
              </Link>
            </div>
            <div className="article-content">
              <span className="article-category">{displayCategory}</span>
              <h3>{article.title}</h3>
            </div>
            <div className={`bookmark-container`}>
              <button
                className="bookmark-button"
                disabled={isLoading}
                onClick={handleToggleBookmark}
              >
                <DogPettingIcon
                  name={isBookmarked ? "bookmarked" : "bookmark"}
                />
              </button>
            </div>
          </div>
          <div className="article-header-img">
            <img src={article.webImage} alt={article.title} />
            <span className="article-duration">
              <MdOutlineWatchLater />
              {formatDuration(article.duration)}
            </span>
          </div>
          <div className="article-bottom-content">
            <div className="article-paragraphs">
              {article.subHeading.map((data, index) => (
                <React.Fragment key={index}>
                  <h4>{data.title}</h4>
                  <p>{data.value}</p>
                </React.Fragment>
              ))}
            </div>
          </div>
        </article>
      )}
      {toast && <Toast message={toast} onClose={() => setToast(null)} />}
    </section>
  );
};
