import React, { useEffect, useState, useCallback } from "react";
import "./HomePage.css";

import Header from "../../Components/Header/Header";
import Homenav from "../../Components/HomeNav/Homenav";
import { CiBookmark } from "react-icons/ci";
import { FaStar, FaHeartbeat, FaChevronDown } from "react-icons/fa";
import { MdPets } from "react-icons/md";
import { SiVlcmediaplayer } from "react-icons/si";
import DogPettingIcon from "./DogPettingIcon";
import { CgDanger } from "react-icons/cg";
import { FeaturedTab } from "./FeaturedTab";
import { HomePageTab } from "./HomePageTab";
import { IoCheckmarkDoneCircle } from "react-icons/io5";
import { HomePageModal } from "./HomePageModal/HomePageModal";
import axios from "../../axios";
import Cookies from "js-cookie";
import alternateImg from "../../assets/animated-dog.avif";
import { Loading } from "../../Components/Loading/Loading";
import NotFound from "../../assets/not-found.gif";
import Bookmarkicon from "./Bookmarkicon";
import Bookmark from "./Bookmark";
import Failedgif from "../Failed/Failedgif";

const fetchBookmarkedArticlesHelper = async (loginToken) => {
  try {
    const response = await axios.get("/user/article/allbookmarks", {
      headers: { Authorization: loginToken },
    });
    console.log("Fetched Bookmarked Articles:", response.data.message);
    return response.data.message.map((bookmark) => ({
      ...bookmark.articles[0],
      isBookmarked: true,
    }));
  } catch (error) {
    console.error("Error fetching bookmarked articles:", error);
    return [];
  }
};
export default function HomePage() {
  const loginToken = Cookies.get("loginToken");

  const [switchToFound, setSwitchToFound] = useState(false);
  const [lostFoundData, setLostFoundData] = useState([]);
  const [activeCategory, setActiveCategory] = useState("Featured");
  const [articles, setArticles] = useState({
    Featured: [],
    Health: [],
    Pet_Care: [],
    Training: [],
    Bookmarked: [],
  });
  const [modal, setModal] = useState(false);
  const [lostPets, setLostPets] = useState([]);
  const [foundPets, setFoundPets] = useState([]);
  const [selectedPet, setSelectedPet] = useState(null);
  const [lostFoundLoading, setLostFoundLoading] = useState(false);
  const [bookmarkedArticles, setBookmarkedArticles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isBookmarkLoading, setIsBookmarkLoading] = useState(false);
  const [bookmarkedIds, setBookmarkedIds] = useState(new Set());
  const [isOpen, setIsOpen] = useState(false);

  const toggleData = () => {
    setSwitchToFound((prev) => !prev);
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category);
  };

  const handleModalOpening = (pet) => {
    setSelectedPet(pet);
    setModal(!modal);
  };

  const categoryMap = {
    "": ["health_and_wellness", "pet_care", "training_and_behaviour"],
    Health: ["health_and_wellness"],
    Pet_Care: ["pet_care"],
    Training: ["training_and_behaviour"],
  };
  useEffect(() => {
    fetchBookmarkedIds();
  }, []);

  const fetchBookmarkedIds = async () => {
    try {
      const response = await axios.get("/user/article/allbookmarks", {
        headers: { Authorization: loginToken },
      });
      const ids = new Set(response.data.message.map((bookmark) => bookmark.id));
      setBookmarkedIds(ids);
      console.log("Bookmarked IDs:", response);
      console.log("Fetched bookmarked IDs:", ids);
    } catch (error) {
      console.error("Error fetching bookmarked IDs:", error);
    }
  };

  const fetchArticles = () => {
    setIsLoading(true);
    const categoriesToFetch = categoryMap[activeCategory] || [];

    axios
      .get("/user/articles", {
        params: {
          category: categoriesToFetch,
          title: "",
        },
        headers: {
          Authorization: loginToken,
        },
      })
      .then((response) => {
        console.log("Fetched Articles:", response.data);

        if (Array.isArray(response.data.message)) {
          const updatedArticles = response.data.message.map((article) => ({
            ...article,
            isBookmarked: bookmarkedIds.has(article.id),
          }));
          setArticles((prevArticles) => ({
            ...prevArticles,
            [activeCategory]: updatedArticles,
          }));
        } else {
          setArticles((prevArticles) => ({
            ...prevArticles,
            [activeCategory]: [],
          }));
        }
      })
      .catch((error) => {
        console.error("Error fetching articles:", error);
        setArticles((prevArticles) => ({
          ...prevArticles,
          [activeCategory]: [],
        }));
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // useEffect(() => {
  //   if (activeCategory !== "Bookmarked") {
  //     fetchArticles();
  //   } else {
  //     fetchBookmarkedArticles();
  //   }
  // }, [loginToken, activeCategory]);

  useEffect(() => {
    console.log("All articles: ", articles);
  }, [articles]);

  // ... (previous code remains the same)
  const fetchBookmarkedArticles = useCallback(async () => {
    setIsBookmarkLoading(true);
    try {
      const bookmarkedArticles = await fetchBookmarkedArticlesHelper(
        loginToken
      );
      setArticles((prevArticles) => ({
        ...prevArticles,
        Bookmarked: bookmarkedArticles,
      }));
      setBookmarkedIds(
        new Set(bookmarkedArticles.map((article) => article.id))
      );
    } catch (error) {
      console.error("Error fetching bookmarked articles:", error);
    } finally {
      setIsBookmarkLoading(false);
    }
  }, [loginToken]);

  const handleBookmarkChange = useCallback(
    async (articleId) => {
      try {
        const isCurrentlyBookmarked = bookmarkedIds.has(articleId);
        const newBookmarkStatus = !isCurrentlyBookmarked;

        if (newBookmarkStatus) {
          await axios.post(
            `/user/article/addtobookmark/${articleId}`,
            {},
            { headers: { Authorization: loginToken } }
          );
        } else {
          await axios.delete(`/user/article/deletebookmark/${articleId}`, {
            headers: { Authorization: loginToken },
          });
        }

        console.log("Bookmark status updated:", newBookmarkStatus);

        // Update bookmarkedIds immediately
        setBookmarkedIds((prevIds) => {
          const updatedIds = new Set(prevIds);
          if (newBookmarkStatus) {
            updatedIds.add(articleId);
          } else {
            updatedIds.delete(articleId);
          }
          return updatedIds;
        });

        // Update articles state immediately
        setArticles((prevArticles) => {
          const updatedArticles = { ...prevArticles };
          Object.keys(updatedArticles).forEach((category) => {
            updatedArticles[category] = updatedArticles[category].map(
              (article) =>
                article.id === articleId
                  ? { ...article, isBookmarked: newBookmarkStatus }
                  : article
            );
          });

          // If removing from bookmarks, remove the article from the Bookmarked category
          if (!newBookmarkStatus) {
            updatedArticles.Bookmarked = updatedArticles.Bookmarked.filter(
              (article) => article.id !== articleId
            );
          }

          // If adding a bookmark, add the article to the Bookmarked category
          if (newBookmarkStatus) {
            const bookmarkedArticle = Object.values(updatedArticles)
              .flat()
              .find((article) => article.id === articleId);

            if (
              bookmarkedArticle &&
              !updatedArticles.Bookmarked.some(
                (article) => article.id === articleId
              )
            ) {
              updatedArticles.Bookmarked = [
                ...updatedArticles.Bookmarked,
                { ...bookmarkedArticle, isBookmarked: true },
              ];
            }
          }

          return updatedArticles;
        });
      } catch (error) {
        console.error("Error updating bookmark:", error);
      }
    },
    [bookmarkedIds, articles, loginToken]
  );

  useEffect(() => {
    if (activeCategory === "Bookmarked") {
      fetchBookmarkedArticles();
    } else {
      fetchArticles();
    }
  }, [loginToken, activeCategory, fetchBookmarkedArticles]);
  const renderTabContent = () => {
    if (isLoading) {
      return <Loading color="#eee" size="50px" />;
    }

    const currentArticles = articles[activeCategory] || [];

    // if (currentArticles.length === 0) {
    //   return (
    //     <div className="no-articles">No articles found in this category.</div>
    //   );
    // }

    switch (activeCategory) {
      case "Health":
      case "Pet_Care":
      case "Training":
        return (
          <HomePageTab
            articles={currentArticles}
            onBookmarkChange={handleBookmarkChange}
            bookmarkedIds={bookmarkedIds}
          />
        );
      case "Bookmarked":
        return (
          <Bookmark
            articles={currentArticles}
            onBookmarkChange={handleBookmarkChange}
            bookmarkedIds={bookmarkedIds}
          />
        );

      default:
        return (
          <FeaturedTab
            articles={articles?.Featured}
            onBookmarkChange={handleBookmarkChange}
          />
        );
    }
  };

  useEffect(() => {
    const fetchLostPetInfo = async () => {
      try {
        const response = await axios.get("/nfc/lostedpets", {
          headers: {
            Authorization: loginToken,
          },
        });
        setLostPets(response.data.message);
      } catch (error) {
        console.error("Error fetching lost pet info:", error);
      }
    };

    const fetchFoundPetInfo = async () => {
      try {
        const response = await axios.get("/nfc/foundedpets", {
          headers: {
            Authorization: loginToken,
          },
        });
        setFoundPets(response.data.message);
      } catch (error) {
        console.error("Error fetching found pet info:", error);
      }
    };
    const fetchData = async () => {
      setLostFoundLoading(true); // Set loading to true before fetching
      await Promise.all([fetchLostPetInfo(), fetchFoundPetInfo()]);

      setTimeout(() => {
        setLostFoundLoading(false); // Set loading to false after fetching
      }, 300);
    };

    fetchData();
  }, [loginToken]);

  const renderPets = (pets) => {
    if (pets.length === 0) {
      return (
        <div>
          <Failedgif message="No pets found." />
        </div>
      );
    }

    return pets.map((data, index) => {
      const date = data.foundstatus
        ? new Date(data.foundDate)
        : new Date(data.updatedAt);
      const formattedDate = `${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}`;
      const statusText = data.foundstatus
        ? `Found on ${formattedDate}`
        : `Lost on ${formattedDate}`;

      const animationDelay = `${index * 0.3}s`;
      return (
        <div
          className="lost-found-card"
          key={data?.id}
          onClick={() => handleModalOpening(data)}
          style={{ "--animation-delay": animationDelay }}
        >
          <div
            className="left-img-card"
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <img
              src={data?.petinfo?.petProfileImage || alternateImg}
              alt={data?.petinfo?.petName}
            />
          </div>
          <div className="left-content-card">
            <h3>{data?.petinfo?.petName}</h3>
            <p>
              {statusText}
              {data?.foundstatus ? (
                <IoCheckmarkDoneCircle className="found-info-icon" />
              ) : (
                <CgDanger className="info-icon" />
              )}
            </p>
          </div>
        </div>
      );
    });
  };
  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  // foundDate, updatedAt

  return (
    <section onClick={handleuserclose}>
      <section style={{ marginBottom: "15vh" }}>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
      {/* <section className="homepage-navspace" > */}
      {/* <section>
        <Homenav />
      </section> */}
      <section className="home-page-section">
        <div className="home-section-left">
          <div className="home-left-top">
            <div className="top-nav-links">
              <p
                className={`top-nav-link ${
                  activeCategory === "Featured" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Featured")}
              >
                Featured
                <FaStar />
              </p>
              <p
                className={`top-nav-link ${
                  activeCategory === "Health" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Health")}
              >
                Health <FaHeartbeat />
              </p>
              <p
                className={`top-nav-link ${
                  activeCategory === "Pet_Care" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Pet_Care")}
              >
                Pet Care <MdPets />
              </p>
              <p
                className={`top-nav-link ${
                  activeCategory === "Training" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Training")}
              >
                Training <SiVlcmediaplayer />
              </p>
            </div>
            <div className="language-option">
              <p className="top-nav-link">
                En <FaChevronDown />
              </p>
              <p
                className={`top-nav-link-bookmark ${
                  activeCategory === "Bookmarked" ? "active" : ""
                }`}
                onClick={() => handleCategoryChange("Bookmarked")}
              >
                <DogPettingIcon name="bookmarkicon" />
              </p>
            </div>
          </div>
          {/* <FeaturedTab DUMMY_ARTICLES={DUMMY_ARTICLES} /> */}
          {renderTabContent()}
        </div>
        <div className="home-section-right">
          <div className="top-right">
            <button
              className={!switchToFound ? "active" : ""}
              onClick={() => setSwitchToFound(false)}
            >
              <DogPettingIcon name={"Lost"} active={!switchToFound} />
              Lost
            </button>
            <button
              className={switchToFound ? "active" : ""}
              onClick={() => setSwitchToFound(true)}
            >
              <DogPettingIcon name="Found" active={switchToFound} />
              Found
            </button>
          </div>
          <div className="bottom-right">
            {lostFoundLoading ? (
              <Loading color="#eee" size="50px" />
            ) : (
              <div className="lost-found-cards">
                {switchToFound ? renderPets(foundPets) : renderPets(lostPets)}
              </div>
            )}
            {modal && (
              <HomePageModal
                isClose={handleModalOpening}
                className={selectedPet?.foundstatus ? "found" : "lost"}
                isOpen={modal}
                headerText={
                  selectedPet?.foundstatus
                    ? "I’m Reunited with my owner, thank you!"
                    : "I’m Lost! Please help shiro find his owner"
                }
                header={true}
                aboutDiv={true}
                petInfo={selectedPet}
              />
            )}
          </div>
        </div>
      </section>
    </section>
  );
}
