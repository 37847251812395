import React, { useState } from "react";
import DogPettingIcon from "../../../../Pages/Homepage/DogPettingIcon";
import "./Checkout.css";

export default function Checkout({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [isClosing1, setIsClosing1] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);

  // Array to store multiple addresses
  const [addresses, setAddresses] = useState([
    {
      name: "Mike",
      address: "No1 A, Nattart street, Velachery , Chennai-00042",
      phone: "+91 9342099409",
    },
  ]);

  const [newAddress, setNewAddress] = useState({
    name: "",
    phone: "",
    nationality: "",
    pincode: "",
    state: "",
    district: "",
    city: "",
  });

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handleClose1 = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setAddNewAddress((prev) => !prev);
    }, 1000);
  };

  const handleAddNewAddress = () => {
    setAddNewAddress((prev) => !prev);
  };

  const handleAddAddress = () => {
    // Add new address to the list
    setAddresses((prevAddresses) => [...prevAddresses, newAddress]);
    // Reset the new address form
    setNewAddress({
      name: "",
      phone: "",
      nationality: "",
      pincode: "",
      state: "",
      district: "",
      city: "",
    });
    handleClose1(); // Close the address form modal
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewAddress((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="checkout-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-checkout-title">
            <DogPettingIcon name={"cart-checkout"} />
            <h2>Proceed to Check - Out</h2>
          </div>
        </div>
        <div className="checkout-delivery-details">
          <div>
            <h2> Delivery to</h2>
            <div className="delivery-details">
              {addresses.map((address, index) => (
                <div key={index} className="delivery-details-address">
                  <h4>{address.name}</h4>
                  <h5>{address.address}</h5>
                  <p>Phone No. {address.phone}</p>
                  <div className="delivery-details-edit">
                    <p>Edit</p>
                  </div>
                </div>
              ))}
              <div
                className="delivery-details-addaddress"
                onClick={handleAddNewAddress}
              >
                <DogPettingIcon name="add-address" />
                <h2>Add Address</h2>
              </div>
            </div>
          </div>
        </div>
        <div className="shopcart-checkoutbuttons">
          <button className="shop-cart-button">
            <DogPettingIcon name="check" />
            &ensp; Pay Now $1200
          </button>
        </div>
        {addNewAddress && (
          <div
            className={`modal-overlay ${isClosing1 ? "fade-out" : ""}`}
            onClick={handleOverlayClick}
          >
            <div className={`modal-container ${isClosing1 ? "close" : ""}`}>
              <div className="checkout-container">
                <div className="modal-close" onClick={handleClose1}>
                  <DogPettingIcon
                    name={"closemodal"}
                    className="modal-close-icon"
                  />
                </div>
                <div className="shop-checkout-title">
                  <h2>Add Address</h2>
                </div>
                <div className="addaddress-input-details">
                  <div className="addaddress-input">
                    <p>Name</p>
                    <input
                      type="text"
                      name="name"
                      value={newAddress.name}
                      onChange={handleInputChange}
                      placeholder="Full Name"
                    />
                    <input
                      type="text"
                      name="phone"
                      value={newAddress.phone}
                      onChange={handleInputChange}
                      placeholder="Phone Number"
                    />
                  </div>
                  <div
                    className="addaddress-input"
                    style={{ marginBottom: "11vh" }}
                  >
                    <p>Address</p>
                    <input
                      type="text"
                      name="nationality"
                      value={newAddress.nationality}
                      onChange={handleInputChange}
                      placeholder="Nationality"
                    />
                    <input
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={newAddress.address}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="pincode"
                      value={newAddress.pincode}
                      onChange={handleInputChange}
                      placeholder="Pin code"
                    />
                    <input
                      type="text"
                      name="state"
                      value={newAddress.state}
                      onChange={handleInputChange}
                      placeholder="State"
                    />
                    <input
                      type="text"
                      name="district"
                      value={newAddress.district}
                      onChange={handleInputChange}
                      placeholder="District"
                    />
                    <input
                      type="text"
                      name="city"
                      value={newAddress.city}
                      onChange={handleInputChange}
                      placeholder="City"
                    />
                  </div>
                </div>
              </div>
              <div className="addnewaddress-checkoutbuttons">
                <button onClick={handleClose1}>Remove</button>
                <button onClick={handleAddAddress}>Add</button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
