import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import Login from "../../../LoginPage/Component/Login/Login";
import Signup from "../../../LoginPage/Component/Signup/Signup";
import { useState } from "react";
import { RiCloseCircleFill } from "react-icons/ri";
import { IoClose } from "react-icons/io5";
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function Auth({ open, handleClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const [showTogglebtn, setShowTogglebtn] = useState(true);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div>
      <Modal
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-15px",
        }}
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="whitebox">
          <div>
            {showTogglebtn ? (
              <div className="togglebtn-bg">
                <div className="togglebtn-outer-div">
                  {/* <div className="togglebtn"> */}
                  <div
                    className={`slider ${
                      isLogin ? "slider-login" : "slider-signup"
                    }`}
                  ></div>
                  <button
                    className={
                      isLogin ? "togglebtn-active" : "togglebtn-non-active"
                    }
                    onClick={handleToggle}
                  >
                    Login
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className={
                      !isLogin ? "togglebtn-active" : "togglebtn-non-active"
                    }
                    onClick={handleToggle}
                  >
                    New User
                  </button>
                  {/* </div> */}
                </div>
              </div>
            ) : (
              ""
            )}

            {isLogin ? (
              <>
                <Login setShowTogglebtn={setShowTogglebtn} />
              </>
            ) : (
              <>
                <Signup setShowTogglebtn={setShowTogglebtn} />
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}
