import React, { useState, useEffect, useRef } from "react";
import "./Reminder.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import CalendarComponent from "./Calender";
import { GiAlarmClock } from "react-icons/gi";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField, MenuItem, IconButton, InputAdornment } from "@mui/material";
import { IoCheckbox } from "react-icons/io5";
import dayjs from "dayjs";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import Delete from "../../../../../Components/Delete/Delete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Reminder = ({ isOpen, onClose, redBubble2, petId }) => {
  const [showInputModal, setShowInputModal] = useState(false);
  const [reminderName, setReminderName] = useState("");
  const [description, setDescription] = useState("");
  const [reminderDate, setReminderDate] = useState(dayjs());
  const [reminderTime, setReminderTime] = useState(dayjs());
  const [reminders, setReminders] = useState({});
  const [selectedDate, setSelectedDate] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [remindersForSelectedDate, setRemindersForSelectedDate] = useState([]);
  const [editingReminderIndex, setEditingReminderIndex] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [reminderToDeleteIndex, setReminderToDeleteIndex] = useState(null);
  const [isDeleteActive, setIsDeleteActive] = useState(true);
  const [repeatOption, setRepeatOption] = useState("once");
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [customReminder, setCustomReminder] = useState("");

  const [showRepeatOptions, setShowRepeatOptions] = useState(false);
  const [showWeeklyModal, setShowWeeklyModal] = useState(false);
  const [showMonthlyModal, setShowMonthlyModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [isClosing1, setIsClosing1] = useState(false);

  const [isSmallClosing, setIsSmallClosing] = useState(false);
  const [reminderType, setReminderType] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [customReminderType, setCustomReminderType] = useState("");
  const [currentEditDate, setCurrentEditDate] = useState(null);
  const [error, setError] = useState("");
  const errorRef = useRef(null);

  const loginToken = Cookies.get("loginToken");

  const { login } = useSelector((state) => state);
  const userInfo = login.details.userInfo;

  useEffect(() => {
    fetchReminders();
  }, [selectedDate]);
  
  useEffect(() => {
    if (error && errorRef.current) {
      errorRef.current.focus();
    }
  }, [error]);

  useEffect(() => {
    console.log("Use effect Selected Date:", selectedDate);
    console.log("Use effect Reminders:", reminders);
    if (reminders[selectedDate]) {
      setRemindersForSelectedDate(reminders[selectedDate]);
      console.log(
        "Use effect Reminders for selected date:",
        reminders[selectedDate]
      );
    } else {
      console.log(`No reminders found for selected date ${selectedDate}`);
      setRemindersForSelectedDate([]);
    }
  }, [reminders, selectedDate]);

  const handleReminderChange = (event) => {
    const value = event.target.value;
    if (value === "Other") {
      setIsOtherSelected(true);
      setReminderType("");
      setCustomReminderType("");
    } else {
      setIsOtherSelected(false);
      setReminderType(value);
      setCustomReminderType("");
    }
  };
  const handleCustomReminderChange = (event) => {
    const value = event.target.value;
    setCustomReminderType(value);
    setReminderType(value); // Update reminderType with custom value
  };
  const handleDropdownClick = () => {
    setIsOtherSelected(false);
    setReminderType(""); // Reset to open the dropdown again
    setCustomReminderType("");
  };
  const handleDeleteClick = () => {
    setShowDeleteConfirmation(true);
  };
  const handleDeleteCancel = () => {
    setShowDeleteConfirmation(false);
  };
  const fetchReminders = async () => {
    try {
      const response = await axios.get("/home/getreminder", {
        headers: { Authorization: loginToken },
      });

      console.log("Fetched reminders:", response.data.message);

      if (response.status === 200) {
        const fetchedReminders = response.data.message;
        const remindersByDate = {};

        // Mapping of day strings to their respective numeric values
        const dayMapping = {
          SUN: 0,
          MON: 1,
          TUE: 2,
          WED: 3,
          THU: 4,
          FRI: 5,
          SAT: 6,
        };

        fetchedReminders.forEach((reminder) => {
          let dates = [];

          // Remove extra quotation marks from repeatReminder value
          const repeatReminder = reminder.repeatReminder.replace(/['"]/g, "");

          if (repeatReminder === "once") {
            // Handle 'once' reminders
            dates.push(dayjs(reminder.dateforOnce).format("YYYY-MM-DD"));
          } else if (repeatReminder === "daily") {
            // Handle 'daily' reminders - push reminder for every day indefinitely
            for (let i = 0; i < 365 * 10; i++) {
              dates.push(dayjs().add(i, "day").format("YYYY-MM-DD"));
            }
          } else if (repeatReminder === "weekly") {
            // Handle 'weekly' reminders - repeat every week on specified days
            reminder.dayOfWeek.forEach((day) => {
              const dayNumber = dayMapping[day]; // Convert day string to numeric value
              for (let i = 0; i < 365 * 10; i += 7) {
                const reminderDate = dayjs()
                  .day(dayNumber)
                  .add(i, "day")
                  .format("YYYY-MM-DD");
                console.log(
                  `Adding date for ${day} on ${i} days from now: ${reminderDate}`
                );
                dates.push(reminderDate);
              }
            });
          } else if (repeatReminder === "monthly") {
            // Handle 'monthly' reminders - repeat every month on specified dates
            reminder.dateOfMonth.forEach((day) => {
              for (let i = 0; i < 12 * 10; i++) {
                dates.push(
                  dayjs().date(day).add(i, "month").format("YYYY-MM-DD")
                );
              }
            });
          }

          dates.forEach((date) => {
            if (!remindersByDate[date]) remindersByDate[date] = [];

            remindersByDate[date].push({
              id: reminder.id,
              description: reminder.description,
              name: reminder.reminder,
              type: reminder.reminderType,
              time: dayjs(reminder.dateforOnce).format("HH:mm"),
              isCompleted: reminder.isCompleted || false,
              repeatOption: repeatReminder,
            });
          });
        });

        console.log("Processed reminders by date:", remindersByDate);
        setReminders(remindersByDate);

        // Update reminders for the selected date
        const currentDate = dayjs().format("YYYY-MM-DD");
        if (remindersByDate[currentDate]) {
          setRemindersForSelectedDate(remindersByDate[currentDate]);
          console.log(
            `Reminders found for current date ${currentDate}:`,
            remindersByDate[currentDate]
          );
        } else {
          console.log(`No reminders found for current date ${currentDate}`);
          setRemindersForSelectedDate([]);
        }
      }
    } catch (error) {
      console.error("Error fetching reminders:", error);
    }
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      handleClose();
      handleSmallClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000);
  };

  const handleSmallClose = () => {
    setIsClosing1(true);
    setIsSmallClosing(true);
    setTimeout(() => {
      setIsClosing1(false);
      setShowInputModal(false);
      setEditingReminderIndex(null);
    }, 1000);
  };

  const handleCalendarDateClick = (date) => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    setSelectedDate(formattedDate);
  };

  const handleCheckboxClick = async (index) => {
    const reminderToCheck = remindersForSelectedDate[index];
    const reminderId = reminderToCheck.id;
    const newCheckedState = !reminderToCheck.isCompleted;

    try {
      if (newCheckedState) {
        // Only send the request if the reminder is being checked (not unchecked)
        const response = await axios.post(
          "/home/addcheckedreminders",
          {
            reminderId: reminderId,
            checkedDate: dayjs().format("YYYY-MM-DDTHH:mm:ssZ"),
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        console.log("Reminder checked successfully:", response.data);
      }

      // Update local state
      setRemindersForSelectedDate((prevReminders) =>
        prevReminders.map((reminder) =>
          reminder.id === reminderId
            ? { ...reminder, isCompleted: newCheckedState }
            : reminder
        )
      );

      // Update the main reminders state
      setReminders((prevReminders) => {
        const updatedReminders = { ...prevReminders };
        Object.keys(updatedReminders).forEach((date) => {
          updatedReminders[date] = updatedReminders[date].map((reminder) =>
            reminder.id === reminderId
              ? { ...reminder, isCompleted: newCheckedState }
              : reminder
          );
        });
        return updatedReminders;
      });
    } catch (error) {
      console.error("Error checking reminder:", error);
      // Optionally, show an error message to the user
    }
  };
  const handleAddReminderClick = () => {
    setEditingReminderIndex(null);
    setShowInputModal(true);
    resetFormFields();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setReminderName(value);
      setError(""); // Clear error when valid input is provided
    } else {
      setError("Name should contain only letters and spaces.");
      setTimeout(() => setError(""), 3000);
    }
  };
  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    // Only allow letters and spaces
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setDescription(value);
      setError(""); // Clear error when valid input is provided
    } else {
      setError("Description should contain only letters and spaces.");
      setTimeout(() => setError(""), 3000);
    }
  };

  const handleRepeatOptionChange = (e) => {
    const value = e.target.value;
    setRepeatOption(value);
    if (value === "weekly") {
      setShowWeeklyModal(true);
    } else if (value === "monthly") {
      setShowMonthlyModal(true);
    }
  };

  const generateReminderDates = () => {
    const reminderDates = [];
    const startDate = dayjs(reminderDate);

    switch (repeatOption) {
      case "once":
        reminderDates.push(startDate.format("YYYY-MM-DD"));
        break;
      case "daily":
        for (let i = 0; i < 365; i++) {
          reminderDates.push(startDate.add(i, "day").format("YYYY-MM-DD"));
        }
        break;
      case "weekly":
        for (let i = 0; i < 52; i++) {
          selectedDays.forEach((day) => {
            const date = startDate.add(i, "week").day(day);
            reminderDates.push(date.format("YYYY-MM-DD"));
          });
        }
        break;
      case "monthly":
        for (let i = 0; i < 12; i++) {
          selectedDates.forEach((date) => {
            const reminderDate = startDate.add(i, "month").date(date);
            if (reminderDate.isValid()) {
              reminderDates.push(reminderDate.format("YYYY-MM-DD"));
            }
          });
        }
        break;
    }

    return reminderDates;
  };

  const handleConfirmClick = async () => {
    if (!reminderType || !reminderName || !description) {
      setError("Please fill in all required fields.");
      setTimeout(() => setError(""), 3000);
      return;
    }

    // Check if reminderName and description contain only letters and spaces
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!nameRegex.test(reminderName) || !nameRegex.test(description)) {
      setError("Name and description should contain only letters and spaces.");
      setTimeout(() => setError(""), 3000);
      return;
    }
    const timeString = reminderTime.format("HH:mm:ss");
    const reminderDateTime = reminderDate.format("YYYY-MM-DD HH:mm:ss");

    let reminderData = {
      userName: userInfo.firstName,
      petId: petId.id,
      description: description,
      reminder: reminderName,
      reminderType: reminderType,
      repeatReminder: repeatOption,
      time: timeString,
      dayOfWeek: [],
      dateOfMonth: [],
      reminderdate: reminderDateTime,
    };

    switch (repeatOption) {
      case "weekly":
        reminderData.dayOfWeek = selectedDays;
        break;
      case "monthly":
        reminderData.dateOfMonth = selectedDates.map(Number);
        break;
    }

    console.log("Sending reminder data:", reminderData);

    try {
      let response;
      if (editingReminderIndex !== null) {
        const reminderId = remindersForSelectedDate[editingReminderIndex].id;
        response = await axios.post(
          `/home/updatereminder/${reminderId}`,
          reminderData,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        console.log("Reminder updated successfully:", response.data);
      } else {
        response = await axios.post("/home/reminderadd", reminderData, {
          //
          headers: {
            Authorization: loginToken,
          },
        });
        console.log("Reminder added successfully:", response.data);
      }

      const updatedReminder = {
        id:
          response.data.id ||
          remindersForSelectedDate[editingReminderIndex]?.id,
        description: description,
        name: reminderName,
        time: reminderTime.format("HH:mm"),
        isCompleted: false,
        repeatOption: repeatOption,
        type: reminderType,
        date: reminderDate.format("YYYY-MM-DD"),
      };

      // Immediate state update
      setReminders((prevReminders) => {
        const updatedReminders = { ...prevReminders };
        const reminderDate = updatedReminder.date;

        if (editingReminderIndex !== null) {
          // Remove the old reminder from all dates
          Object.keys(updatedReminders).forEach((date) => {
            updatedReminders[date] = updatedReminders[date].filter(
              (reminder) => reminder.id !== updatedReminder.id
            );
          });
        }

        // Add or update the reminder
        if (!updatedReminders[reminderDate]) {
          updatedReminders[reminderDate] = [];
        }
        updatedReminders[reminderDate].push(updatedReminder);

        return updatedReminders;
      });

      // Update remindersForSelectedDate
      setRemindersForSelectedDate((prevReminders) => {
        if (editingReminderIndex !== null) {
          if (currentEditDate === reminderDate.format("YYYY-MM-DD")) {
            return prevReminders.map((reminder) =>
              reminder.id === updatedReminder.id ? updatedReminder : reminder
            );
          } else {
            return prevReminders.filter(
              (reminder) => reminder.id !== updatedReminder.id
            );
          }
        } else {
          if (selectedDate === reminderDate.format("YYYY-MM-DD")) {
            return [...prevReminders, updatedReminder];
          } else {
            return prevReminders;
          }
        }
      });

      // If the reminder is recurring, add it to all relevant dates
      if (repeatOption !== "once") {
        const dates = generateReminderDates();
        dates.forEach((date) => {
          if (date !== updatedReminder.date) {
            updateRemindersState(date, { ...updatedReminder, date });
          }
        });
      }

      resetFormFields();
      handleSmallClose();

      // Update the selected date to the new reminder date
      setSelectedDate(reminderDate.format("YYYY-MM-DD"));
      setError("");
    } catch (error) {
      console.error("Error adding/updating reminder:", error);
      // Optionally, show an error message to the user
    }
  };

  const updateRemindersState = (date, reminder) => {
    setReminders((prevReminders) => {
      const updatedReminders = { ...prevReminders };
      if (!updatedReminders[date]) {
        updatedReminders[date] = [];
      }
      updatedReminders[date].push(reminder);
      return updatedReminders;
    });

    if (selectedDate === date) {
      setRemindersForSelectedDate((prev) => [...prev, reminder]);
    }
  };

  const resetFormFields = () => {
    setReminderName("");
    setReminderType("");
    setDescription("");
    setReminderDate(dayjs());
    setReminderTime(dayjs());
    setRepeatOption("once");
    setSelectedDays([]);
    setSelectedDates([]);
  };

  const handleEditClick = (index) => {
    console.log("Editing index:", index);
    console.log("remindersForSelectedDate:", remindersForSelectedDate);

    if (remindersForSelectedDate && remindersForSelectedDate[index]) {
      const reminderToEdit = remindersForSelectedDate[index];
      console.log("Reminder to Edit:", reminderToEdit);

      setReminderName(reminderToEdit.name);
      setDescription(reminderToEdit.description);
      setReminderType(reminderToEdit.type);
      setReminderDate(dayjs(reminderToEdit.date));
      setCurrentEditDate(reminderToEdit.date);

      const predefinedTypes = [
        "Vaccination",
        "Deworming",
        "Grooming",
        "Training",
        "Exercise",
        "Health Checkup",
      ];
      if (predefinedTypes.includes(reminderToEdit.type)) {
        setReminderType(reminderToEdit.type);
        setIsOtherSelected(false);
        setCustomReminderType("");
      } else {
        setReminderType(reminderToEdit.type);
        setIsOtherSelected(true);
        setCustomReminderType(reminderToEdit.type);
      }

      // Handle the date
      const currentDate = dayjs(); // Use current date if reminderToEdit.date is not available
      setReminderDate(dayjs(reminderToEdit.date || currentDate));

      // Handle the time
      const [hours, minutes] = reminderToEdit.time.split(":");
      const timeWithDate = currentDate
        .hour(parseInt(hours))
        .minute(parseInt(minutes));
      setReminderTime(timeWithDate);

      setRepeatOption(reminderToEdit.repeatOption);

      setEditingReminderIndex(index);
      setShowInputModal(true);
      setShowDeleteConfirmation(false);
    } else {
      console.error("Reminder to edit is not found at index:", index);
      console.error("remindersForSelectedDate:", remindersForSelectedDate);
    }
  };
  const handleDeleteConfirm = async () => {
    if (editingReminderIndex === null) return;

    const reminderToDelete = remindersForSelectedDate[editingReminderIndex];
    const reminderId = reminderToDelete.id;

    try {
      await axios.delete(`/home/deletereminder/${reminderId}`, {
        headers: {
          Authorization: loginToken,
        },
      });

      console.log(`Reminder with id ${reminderId} deleted successfully`);

      // Update the reminders state
      setReminders((prevReminders) => {
        const updatedReminders = { ...prevReminders };
        Object.keys(updatedReminders).forEach((date) => {
          updatedReminders[date] = updatedReminders[date].filter(
            (reminder) => reminder.id !== reminderId
          );
        });
        return updatedReminders;
      });

      // Update remindersForSelectedDate
      setRemindersForSelectedDate((prevReminders) =>
        prevReminders.filter((_, index) => index !== editingReminderIndex)
      );

      // Close the modal and reset the editing state
      handleSmallClose();
      setEditingReminderIndex(null);
      setShowDeleteConfirmation(false);

      // Optionally, you can show a success message to the user
      // For example: setSuccessMessage("Reminder deleted successfully");
    } catch (error) {
      console.error("Error deleting reminder:", error);
      // Optionally, you can show an error message to the user
      // For example: setErrorMessage("Failed to delete reminder. Please try again.");
    }
  };

  const handleWeeklyModalClose = () => {
    setShowWeeklyModal(false);
  };

  const handleMonthlyModalClose = () => {
    setShowMonthlyModal(false);
  };

  const handleWeeklyModalConfirm = (selectedDays) => {
    setSelectedDays(selectedDays);
    setShowWeeklyModal(false);
  };

  const handleMonthlyModalConfirm = (selectedDates) => {
    setSelectedDates(selectedDates);
    setShowMonthlyModal(false);
  };

  console.log("remindersForSelectedDate:", remindersForSelectedDate);
  console.log("reminders:", reminders);
  console.log("selectedDate:", selectedDate);

  useEffect(() => {
    console.log("Use effect Selected Date:", selectedDate);
    console.log("Use effect Reminders:", reminders);
    if (reminders[selectedDate]) {
      setRemindersForSelectedDate(reminders[selectedDate]);
      console.log(
        "Use effect Reminders for selected date:",
        reminders[selectedDate]
      );
    } else {
      console.log(`No reminders found for selected date ${selectedDate}`);
      setRemindersForSelectedDate([]);
    }
  }, [reminders, selectedDate]);

  const renderReminders = () => {
    return (
      <div className="reminder-events">
        {remindersForSelectedDate && remindersForSelectedDate.length > 0 ? (
          remindersForSelectedDate.map((reminder, index) => (
            <div key={index} className="reminder-event-list">
              <div className="reminder-event-list-name">
                <h5 className={reminder.isCompleted ? "completed" : ""}>
                  {reminder.name}
                </h5>
                <p>{reminder.time}</p>
              </div>
              <div className="reminder-event-list-icons">
                <p className="edit-icon" onClick={() => handleEditClick(index)}>
                  <DogPettingIcon name={"edit"} />
                </p>
                <p
                  className={`checkbox-icon ${
                    reminder.isCompleted ? "completed" : ""
                  }`}
                  onClick={() => handleCheckboxClick(index)}
                >
                  <IoCheckbox className="checkbox-icon" />
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="no-reminder-text">
            <p>No reminders found</p>
          </div>
        )}
      </div>
    );
  };

  if (!isOpen) return null;
  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-content-reminder">
          <div className="reminder-picker-title">
            <GiAlarmClock className="alarm-icon" />
            <h2>Reminder</h2>
          </div>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div>
            <CalendarComponent
              onDateClick={handleCalendarDateClick}
              reminders={reminders}
              selectedDate={selectedDate}
            />
          </div>
          {renderReminders()}
          <button
            className="addreminder-button"
            onClick={handleAddReminderClick}
          >
            <DogPettingIcon name={"save"} />
            <p>Add Reminder</p>
          </button>
        </div>
      </div>
      {showInputModal && (
        <div
          className={`modal-overlay ${isClosing1 ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing1 ? "close" : ""}`}>
            <div className="modal-content-reminder2">
              <div className="reminder-picker-title">
                <GiAlarmClock className="alarm-icon" />
                <h2>
                  {editingReminderIndex !== null
                    ? "Edit Reminder"
                    : "Add Reminder"}
                </h2>
              </div>
              <div className="modal-close" onClick={handleSmallClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              <div className="addreminder-inputfields">
                <div>
                  {!isOtherSelected ? (
                    <TextField
                      id="reminder-type"
                      label="Reminder Type"
                      select
                      variant="outlined"
                      value={reminderType}
                      onChange={handleReminderChange}
                      placeholder="Reminder Type"
                      sx={{
                        width: "22vw",
                        backgroundColor: "#f5f5f5",
                        borderRadius: ".5vw",
                        "& .MuiInputBase-root": {
                          fontSize: "2vh",
                          height: "9vh",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "2vh",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: "1vw",
                          transition:
                            "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          '&[data-shrink="true"]': {
                            opacity: 0,
                            visibility: "hidden",
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: "3vh",
                        },
                        "& .MuiInputBase-input": {
                          padding: "2vh",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          fontSize: "2vh",
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Removes hover effect
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Removes focus effect
                          },
                        },
                      }}
                    >
                      <MenuItem value="">Reminder Type</MenuItem>
                      <MenuItem value="Vaccination">Vaccination</MenuItem>
                      <MenuItem value="Deworming">Deworming</MenuItem>
                      <MenuItem value="Grooming">Grooming</MenuItem>
                      <MenuItem value="Training">Training</MenuItem>
                      <MenuItem value="Exercise">Exercise</MenuItem>
                      <MenuItem value="Health Checkup">Health Checkup</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  ) : (
                    <TextField
                      id="custom-reminder"
                      label="Reminder Type"
                      variant="outlined"
                      placeholder="Enter custom reminder"
                      value={reminderType}
                      onChange={(e) => setReminderType(e.target.value)}
                      sx={{
                        width: "22vw",
                        backgroundColor: "#f5f5f5",
                        borderRadius: ".5vw",
                        "& .MuiInputBase-root": {
                          fontSize: "2vh",
                          height: "9vh",
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "2vh",
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: "1vw",
                          transition:
                            "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                          '&[data-shrink="true"]': {
                            opacity: 0,
                            visibility: "hidden",
                          },
                        },
                        "& .MuiSvgIcon-root": {
                          fontSize: "3vh",
                        },
                        "& .MuiInputBase-input": {
                          padding: "2vh",
                          "&::placeholder": {
                            opacity: 1,
                          },
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          fontSize: "2vh",
                          border: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Removes hover effect
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none", // Removes focus effect
                          },
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleDropdownClick}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>
                {/* <TextField
                  id="reminder-type"
                  variant="outlined"
                  sx={{
                    width: "22vw",
                    paddingRight: ".5vw",
                    backgroundColor: "#f5f5f5",
                    borderRadius: ".5vw",
                    "& .MuiInputBase-root": {
                      fontSize: "2vh",
                      height: "9vh",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "2vh",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "1.5vw",
                      transition:
                        "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      '&[data-shrink="true"]': {
                        opacity: 0,
                        visibility: "hidden",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "3vh",
                    },
                    "& .MuiInputBase-input": {
                      padding: "2vh",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: "2vh",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes hover effect
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes focus effect
                      },
                    },
                  }}
                  value={reminderType}
                  onChange={(e) => setReminderType(e.target.value)}
                  inputProps={{ maxLength: 50 }}
                  select
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value="">Reminder Type</option>
                  <option value="Appointment">Appointment</option>
                  <option value="Medication">Medication</option>
                  <option value="Training">Training</option>
                  <option value="Vaccination">Vaccination</option>
                </TextField> */}
                <TextField
                  id="outlined-basic"
                  label="Reminder Name"
                  variant="outlined"
                  sx={{
                    width: "22vw",
                    backgroundColor: "#f5f5f5",
                    borderRadius: ".5vw",
                    "& .MuiInputBase-root": {
                      fontSize: "2vh",
                      height: "9vh",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "2vh",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "1vw",
                      transition:
                        "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      '&[data-shrink="true"]': {
                        opacity: 0,
                        visibility: "hidden",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "3vh",
                    },
                    "& .MuiInputBase-input": {
                      padding: "2vh",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: "2vh",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes hover effect
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes focus effect
                      },
                    },
                  }}
                  value={reminderName}
                  onChange={handleChange}
                  inputProps={{ maxLength: 20 }}
                />

                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  sx={{
                    width: "22vw",
                    backgroundColor: "#f5f5f5",
                    borderRadius: ".5vw",
                    "& .MuiInputBase-root": {
                      fontSize: "2vh",
                      height: "9vh",
                    },
                    "& .MuiInputLabel-root": {
                      fontSize: "2vh",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: "1vw",
                      transition:
                        "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                      '&[data-shrink="true"]': {
                        opacity: 0,
                        visibility: "hidden",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      fontSize: "3vh",
                    },
                    "& .MuiInputBase-input": {
                      padding: "2vh",
                      "&::placeholder": {
                        opacity: 1,
                      },
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      fontSize: "2vh",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-root": {
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes hover effect
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none", // Removes focus effect
                      },
                    },
                  }}
                  value={description}
                  onChange={handleDescriptionChange}
                  inputProps={{ maxLength: 100 }}
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Select Date"
                    value={reminderDate}
                    onChange={(newValue) => setReminderDate(newValue)}
                    sx={{
                      width: "22vw",
                      paddingRight: "1vw",
                      backgroundColor: "#f5f5f5",
                      borderRadius: ".5vw",
                      "& .MuiInputBase-root": {
                        fontSize: "2vh",
                        height: "9vh",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "2vh",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "1.5vw",
                        transition:
                          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        '&[data-shrink="true"]': {
                          opacity: 0,
                          visibility: "hidden",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "3vh",
                      },
                      "& .MuiInputBase-input": {
                        padding: "2vh",
                        "&::placeholder": {
                          opacity: 1,
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: "2vh",
                        border: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes hover effect
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes focus effect
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    label="Select Time"
                    value={reminderTime}
                    onChange={(newValue) => setReminderTime(newValue)}
                    sx={{
                      width: "22vw",
                      paddingRight: "1vw",
                      backgroundColor: "#f5f5f5",
                      borderRadius: ".5vw",
                      "& .MuiInputBase-root": {
                        fontSize: "2vh",
                        height: "9vh",
                      },
                      "& .MuiInputLabel-root": {
                        fontSize: "2vh",
                        top: "50%",
                        transform: "translateY(-50%)",
                        left: "1.5vw",
                        transition:
                          "opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                        '&[data-shrink="true"]': {
                          opacity: 0,
                          visibility: "hidden",
                        },
                      },
                      "& .MuiSvgIcon-root": {
                        fontSize: "3vh",
                      },
                      "& .MuiInputBase-input": {
                        padding: "2vh",
                        "&::placeholder": {
                          opacity: 1,
                        },
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        fontSize: "2vh",
                        border: "none",
                      },
                      "& .MuiOutlinedInput-root": {
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes hover effect
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none", // Removes focus effect
                        },
                      },
                    }}
                  />
                </LocalizationProvider>
                <div className="reminders-input-field">
                  <h2>Repeat Reminders </h2>
                  <div className="repeat-radio-group">
                    <label>
                      <input
                        type="radio"
                        value="once"
                        checked={repeatOption === "once"}
                        onChange={handleRepeatOptionChange}
                      />
                      &nbsp; Once
                    </label>
                    &ensp;
                    <label>
                      <input
                        type="radio"
                        value="daily"
                        checked={repeatOption === "daily"}
                        onChange={handleRepeatOptionChange}
                      />
                      &nbsp; Daily
                    </label>
                    &ensp;
                    <label>
                      <input
                        type="radio"
                        value="weekly"
                        checked={repeatOption === "weekly"}
                        onChange={handleRepeatOptionChange}
                      />
                      &nbsp; Weekly
                    </label>
                    &ensp;
                    <label>
                      <input
                        type="radio"
                        value="monthly"
                        checked={repeatOption === "monthly"}
                        onChange={handleRepeatOptionChange}
                      />
                      &nbsp; Monthly
                    </label>
                  </div>
                </div>
                <div className="add-reminder-btn">
                  {editingReminderIndex !== null && (
                    <>
                      <button
                        className="delete-button"
                        onClick={handleDeleteClick}
                      >
                        <DogPettingIcon name={"delete"} /> Delete Reminder
                      </button>
                      <button
                        className="confirm-button"
                        onClick={handleConfirmClick}
                      >
                        <DogPettingIcon name={"check"} /> update
                      </button>
                    </>
                  )}
                  {editingReminderIndex === null && (
                    <button
                      className="confirm-button"
                      onClick={handleConfirmClick}
                    >
                      <DogPettingIcon name={"check"} /> confirm
                    </button>
                  )}
                </div>
                {showDeleteConfirmation && (
                  <Delete
                    handleDelete={handleDeleteConfirm}
                    rejectDelete={handleDeleteCancel}
                    message="Are you sure you want to delete this reminder?"
                  />
                )}
                {error && (
                  <p ref={errorRef} tabIndex="-1" className="error-message">
                    {error}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showWeeklyModal && (
        <div
          className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className="weekly-modal-container">
            <div className="reminder-picker-title">
              <h2>Select Day</h2>
            </div>
            <div className="weekly-modal-content">
              <div className="weekly-days-selection">
                {["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"].map(
                  (day, index) => (
                    <label key={index} className="day-label">
                      <input
                        type="checkbox"
                        value={day}
                        checked={selectedDays.includes(day)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedDays((prev) => [...prev, day]);
                          } else {
                            setSelectedDays((prev) =>
                              prev.filter((d) => d !== day)
                            );
                          }
                        }}
                      />
                      <p className="day-label-text">{day}</p>
                    </label>
                  )
                )}
              </div>
              <div className="modal-close" onClick={handleWeeklyModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="confirm-button"
                onClick={() => handleWeeklyModalConfirm(selectedDays)}
              >
                <DogPettingIcon name={"check"} /> Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showMonthlyModal && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="monthly-modal-container">
            <div className="reminder-picker-title">
              <h2>Select Day</h2>
            </div>
            <div className="monthly-modal-content">
              <div className="monthly-dates-selection">
                {Array.from({ length: 31 }, (_, i) => i + 1).map((date) => (
                  <label key={date} className="day-label">
                    <input
                      type="checkbox"
                      value={date}
                      checked={selectedDates.includes(date)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedDates((prev) => [...prev, date]);
                        } else {
                          setSelectedDates((prev) =>
                            prev.filter((d) => d !== date)
                          );
                        }
                      }}
                    />
                    <p className="day-label-text-monthly">{date}</p>
                  </label>
                ))}
              </div>
              <div className="modal-close" onClick={handleMonthlyModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>
            </div>
            <div className="modal-buttons">
              <button
                className="confirm-button"
                onClick={() => handleMonthlyModalConfirm(selectedDates)}
              >
                <DogPettingIcon name={"check"} /> Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reminder;
