import React, { useState } from "react";
import "./LoginPage.css";
import Header from "../../Components/Header/Header";
import Login from "./Component/Login/Login";
import Signup from "./Component/Signup/Signup";

export default function LoginPage() {
  const [isLogin, setIsLogin] = useState(true);
  const [showTogglebtn, setShowTogglebtn] = useState(true);

  const handleToggle = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div>
      <section>
        <Header />
      </section>

      <section className="loginbox">
        <div className="whitebox">
          <div style={{ height: "100%" }}>
            {showTogglebtn && (
              <div className="togglebtn-bg">
                <div className="togglebtn-outer-div">
                  {/* <div className="togglebtn"> */}
                  <div
                    className={`slider ${
                      isLogin ? "slider-login" : "slider-signup"
                    }`}
                  ></div>
                  <button
                    className={
                      isLogin ? "togglebtn-active" : "togglebtn-non-active"
                    }
                    onClick={handleToggle}
                  >
                    Login
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className={
                      !isLogin ? "togglebtn-active" : "togglebtn-non-active"
                    }
                    onClick={handleToggle}
                  >
                    New User
                  </button>
                  {/* </div> */}
                </div>
              </div>
            )}

            <div>
              {isLogin ? (
                <div>
                  <Login setShowTogglebtn={setShowTogglebtn} />
                </div>
              ) : (
                <div>
                  <Signup setShowTogglebtn={setShowTogglebtn} />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
