import React, { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import "./BillofSale.css";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { LuUpload } from "react-icons/lu";
import { AiFillFilePdf } from "react-icons/ai";
import Delete from "../../../../../Components/Delete/Delete";
import { IoDocumentText } from "react-icons/io5";
import axios from "../../../../../axios";
import Success from "../../../Success/Success";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

export default function BillofSale({ isOpen, onClose, petId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [identity1, setIdentity1] = useState("");
  const [identity2, setIdentity2] = useState("");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState({
    name: "",
    size: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [fileId, setFileId] = useState(null);

  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const [isClosing1, setIsClosing1] = useState(false);

  const petInfo = login.details.petInfo;
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchPetDocuments = async () => {
      try {
        const response = await axios.get(
          `/petinfo/getpetdocuments/${petId.id}`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );

        const { petDocuments } = response.data;
        // Assuming petDocuments is an array of documents with a docType
        const billOfSaleDocs = petDocuments.filter(
          (doc) => doc.docType === "BillofSaleFile"
        );

        if (billOfSaleDocs.length > 0) {
          const mostRecentDoc = billOfSaleDocs[billOfSaleDocs.length - 1]; // The last document is considered the most recent
          console.log("Most Recent Document:", mostRecentDoc);
          setFileUrl(mostRecentDoc.url);
          setUploadedFile({
            name: mostRecentDoc.fileName,
            size: mostRecentDoc.size || 0,
          });
          setFileId(mostRecentDoc.id);
        } else {
          console.log("No document found");
          setFileUrl("");
          setUploadedFile({ name: "", size: 0 });
        }
      } catch (error) {
        console.error("Error fetching pet documents:", error);
      }
    };

    fetchPetDocuments();

    const currentPet = petInfo.find((pet) => pet.id === petId.id);
    if (currentPet) {
      setIdentity1(currentPet.petPrize || "");
      setIdentity2(currentPet.boughtFrom || "");
    }
  }, [isOpen, petId.id, loginToken, petInfo]);

  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleClose();
      handleUploadModalClose();
    }
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 1000); // Match the duration of the closing animation
  };

  const handleUploadClick = () => {
    if (!uploadedFile.name) {
      setIsUploadModalOpen(true);
    }
  };

  const handleUploadModalClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setIsUploadModalOpen(false);
      setUploadStatus("");
      setSelectedFiles([]);
    }, 1000);
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleFileDelete = () => {
    setDeleteModal(true);
  };

  const handleFileClick = (url) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      console.error("No valid URL provided");
    }
  };

  const handleConfirmDelete = async () => {
    if (fileId) {
      try {
        await axios.post(
          "/petinfo/deletePetDocument",
          { selectedIds: [fileId] },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        // Update state after successful deletion
        setUploadedFile({ name: "", size: 0 });
        setFileUrl("");
        setFileId(null);
        dispatch({ type: "refresh" });
        console.log("File deleted successfully");
      } catch (error) {
        console.error("Error deleting file:", error);
        // Optionally, show an error message
      }
    }
    setDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setDeleteModal(false);
  };

  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      try {
        const file = selectedFiles[0];
        const url = await uploadFileToS3(file);
        if (url) {
          const response = await axios.post(
            "/petinfo/addpetdocuments",
            {
              documentList: [
                {
                  docType: "BillofSaleFile",
                  fileType: "pdf",
                  url: url,
                  fileName: file.name,
                },
              ],
              petPrize: identity1,
              boughtFrom: identity2,
              petId: petId.id,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );
          console.log(" addpetdocuments added:", response);
          setUploadStatus("success");
          dispatch({ type: "refresh" });
        } else {
          setUploadStatus("failed");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setUploadStatus("failed");
      }
    }
    setTimeout(() => {
      setUploadStatus("");
      setIsUploadModalOpen(false);
    }, 3000);
  };

  const handleEditSaveClick = async () => {
    setIsEditing(!isEditing);

    if (isEditing) {
      try {
        await axios.post(
          `/petinfo/updatebillofsalefield/${petId.id}`,
          {
            petPrize: identity1,
            boughtFrom: identity2,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        );
        dispatch({ type: "refresh" });
      } catch (error) {
        console.error("Error updating fields:", error);
      }
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="billofsale-container">
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="billofsale-title">
            <DogPettingIcon name={"bill-of-sale"} />
            <h2>Bill of Sale</h2>
          </div>
          <div className="billofsale-input-group">
            <input
              type="text"
              placeholder="Purchase cost"
              value={identity1}
              onChange={(e) => setIdentity1(e.target.value)}
              disabled={!isEditing}
            />
            <input
              type="text"
              placeholder="Breeder or Pet Store Name"
              value={identity2}
              onChange={(e) => setIdentity2(e.target.value)}
              disabled={!isEditing}
            />
            {uploadedFile.name && (
              <div className="billofsale-uploaded-file-preview">
                <div
                  className="uploaded-file-preview-title"
                  onClick={() => handleFileClick(fileUrl)}
                  style={{ cursor: "pointer" }}
                >
                  <h4>
                    <AiFillFilePdf
                      style={{
                        fontSize: "clamp(1.5rem, 1.5vw ,5rem)",
                      }}
                    />
                  </h4>
                  <div className="billofsale-uploaded-file-preview-name">
                    <h5
                      style={{
                        cursor: "pointer",
                        textDecoration: "none",
                      }}
                    >
                      {uploadedFile.name}
                    </h5>
                    {/* <p>{(uploadedFile.size / 1024 / 1024).toFixed(2)} MB</p> */}
                  </div>
                </div>
                <div
                  className="billofsale-uploaded-file-preview-edit"
                  onClick={handleFileDelete}
                  style={{ cursor: "pointer" }}
                >
                  <DogPettingIcon name="delete" />
                </div>
              </div>
            )}
          </div>
          <div className="billofsale-btn">
            <button className="billofsale-button" onClick={handleEditSaveClick}>
              <DogPettingIcon name={isEditing ? "check" : "pencil-edit"} />
              <p>{isEditing ? "Save" : "Edit"}</p>
            </button>

            <button
              className={`billofsale-button ${
                uploadedFile.name ? "disabled" : ""
              }`}
              onClick={handleUploadClick}
              disabled={uploadedFile.name}
            >
              <LuUpload style={{ fontSize: "clamp(1.3rem, 1.5vw ,3.5rem)" }} />
              <p>{uploadedFile.name ? "File Uploaded" : "Upload"}</p>
            </button>
          </div>
        </div>
      </div>

      {isUploadModalOpen && (
        <div
          className={`modal-overlay ${isClosing1 ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing1 ? "close" : ""}`}>
            <div className="add-new-documents">
              <div className="modal-close" onClick={handleUploadModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              {uploadStatus === "success" ? (
                // <div className="uploaded-success">
                //   <img
                //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                //     alt="Success"
                //     title="Success"
                //   />
                //   <p>Upload successfully!</p>
                // </div>
                <Success
                  message="Upload successfully!"
                  header="Success"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                />
              ) : uploadStatus === "failed" ? (
                // <div className="uploaded-failed">
                //   <img
                //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                //     alt="Failed"
                //     title="Failed"
                //   />
                //   <p>Oops! No files found.</p>
                // </div>
                <Success
                  message="Oops! No files found."
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                />
              ) : (
                <div className="add-new-documents-content">
                  <div className="records-Upload-title">
                    <p>
                      <IoDocumentText style={{ fontSize: "1.5rem" }} />
                    </p>
                    <h2>Upload Documents</h2>
                  </div>
                  <div className="modal-close" onClick={handleUploadModalClose}>
                    <DogPettingIcon
                      name={"closemodal"}
                      className="modal-close-icon"
                    />
                  </div>

                  <div className="upload-doc-files">
                    <label htmlFor="fileInputUpload">
                      <p>
                        <DogPettingIcon name="upload-doc" />
                      </p>
                      <div className="upload-doc-files-title">
                        <h5>
                          <LuUpload /> <h6>Upload</h6>
                        </h5>
                        <p>Maximum Size: 3MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      id="fileInputUpload"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </div>
                  {selectedFiles.length > 0 && (
                    <div className="uploaded-preview-list">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-preview">
                          <div className="uploaded-file-preview-title">
                            <h4>
                              <AiFillFilePdf
                                style={{
                                  fontSize: "clamp(1.5rem, 1.5vw ,5rem)",
                                }}
                              />
                            </h4>
                            <div className="uploaded-file-preview-name">
                              <h5
                                style={{
                                  cursor: "pointer",
                                  textDecoration: "none",
                                }}
                              >
                                {file.name}
                              </h5>
                              <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {selectedFiles.length > 0 && (
                    <div className="upload-file-btn" onClick={handleFileUpload}>
                      <p>
                        <LuUpload
                          style={{ fontSize: "clamp(1.3rem, 1.5vw ,3.5rem)" }}
                        />
                      </p>
                      <p>Upload</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {deleteModal && (
        <Delete
          handleDelete={handleConfirmDelete}
          rejectDelete={handleCancelDelete}
          message="Do you really want to remove this file?"
        />
      )}
    </div>
  );
}
