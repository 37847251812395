import React, { useEffect, useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import "./Settingsmodal.css";
import axios from "../../../../axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { RabiesVaccination } from "./RabiesVaccination";

export const AddPetModal = ({ isOpen, isClose }) => {
  const loginToken = Cookies.get("loginToken");
  const dispatch = useDispatch();

  const [petName, setPetName] = useState("");
  const [petBreed, setPetBreed] = useState("");
  const [petGender, setPetGender] = useState("");
  const [petBirthday, setPetBirthday] = useState("");
  const [petRegisteredDate, setPetRegisteredDate] = useState("");
  const [petKernelNumber, setPetKernelNumber] = useState("");
  const [isRabiesVaccinated, setIsRabiesVaccinated] = useState(null);
  const [vaccinationSlot, setVaccinationSlot] = useState("");
  const [petWeight, setPetWeight] = useState("");
  const [petDoctorName, setPetDoctorName] = useState("");
  const [doctorContactNumber, setDoctorContactNumber] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [petType, setPetType] = useState(null);
  const [isRabiesVaccinationOpen, setIsRabiesVaccinationOpen] = useState(false);
  const [breedList, setBreedList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const { refresh } = useSelector((state) => state);
  const [showVaccinationOpt, setShowVaccinationOpt] = useState(false);

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
      setIsModalClosing((prev) => !prev);
    }
  };

  const handleRabiesVaccinationChange = (status) => {
    setIsRabiesVaccinated(status);
    setIsRabiesVaccinationOpen(true);
  };

  // In your pet type selection handler:
  const handlePetTypeSelect = (type) => {
    setPetType(type === petType ? null : type);
    setShowVaccinationOpt(true);
  };
  const validateInputs = () => {
    const newErrors = {};
    const errorMessages = [];
    const requiredFields = {
      petName: "Pet name",
      petBreed: "Pet breed",
      petGender: "Pet gender",
      petBirthday: "Pet birthday",
      petRegisteredDate: "Registered date",
      petKernelNumber: "Kernel and club number",
      petWeight: "Pet weight",
      petDoctorName: "Pet doctor's name",
      doctorContactNumber: "Doctor's contact",
      doctorAddress: "Doctor's address",
      petType: "Pet type",
      vaccinationSlot: "Vaccination Slot",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!eval(field)) {
        newErrors[field] = `${label} is required.`;
        errorMessages.push(`${label} is required.`);
      }
    });

    setErrors(newErrors);
    setErrorMessages(errorMessages);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      errorMessages.forEach((error, index) => {
        setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: error,
          }));

          if (index === errorMessages.length - 1) {
            setTimeout(() => {
              setErrors({});
            }, 3000);
          }
        }, index * 3000);
      });
      return;
    }

    const petInfo = {
      petName,
      petBreed,
      sex: petGender,
      birthDate: petBirthday,
      kennelClubRegisterdDate: petRegisteredDate,
      kennelClubNumber: petKernelNumber,
      rabiesVaccinated: isRabiesVaccinated,
      vaccinationSlot,
      petWeight,
      vetDoctorName: petDoctorName,
      vetDoctorNumber: doctorContactNumber,
      vetDoctorLocation: doctorAddress,
      petType,
    };

    try {
      const response = await axios.post("/petinfo/secondPetaddInfo", petInfo, {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("Pet Info Added Successfully:", response.data);

      // Clear all fields and close modal after successful submission
      setPetName("");
      setPetBreed("");
      setPetGender("");
      setPetBirthday("");
      setPetRegisteredDate("");
      setPetKernelNumber("");
      setIsRabiesVaccinated(null);
      setVaccinationSlot("");
      setPetWeight("");
      setPetDoctorName("");
      setDoctorContactNumber("");
      setDoctorAddress("");
      setPetType(null);
      dispatch({ type: "refresh" });
      isClose();
    } catch (error) {
      console.error("Error adding pet info:", error);
      setErrors({ submit: "Failed to add pet info. Please try again." });
    }
  };

  const getBreedList = async () => {
    try {
      const response = await axios.get("/user/getBreedlist", {
        headers: {
          Authorization: loginToken,
        },
      });
      setBreedList(response.data.message);
    } catch (error) {
      console.error("Error fetching breed list:", error);
    }
  };

  useEffect(() => {
    getBreedList();
  }, []);

  const handleClose = () => {
    setIsModalClosing(true);
    setTimeout(() => {
      setIsModalClosing(false);
      isClose();
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <>
      <div
        className={`modal-overlay ${isModalClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isModalClosing ? "close" : ""}`}>
          <div className="modal-top-header">
            <div className="modal-title">
              <p>
                <DogPettingIcon name={"add-pet"} /> Add Pet
              </p>
            </div>
            <div className="modal-close" onClick={handleClose}>
              <DogPettingIcon
                name={"closemodal"}
                className="modal-close-icon"
              />
            </div>
          </div>
          <div className="setting-modal-description">
            <div className="add-pet">
              <div className="add-pet-header">
                <h3>Choose your pet type</h3>
                <div className="pet-selection-box">
                  {["Dog or Canids", "Cat or Feline", "Others"].map((type) => (
                    <div
                      key={type}
                      className={`pet-box ${
                        petType === type ? "pet-selected" : ""
                      }`}
                      onClick={() => handlePetTypeSelect(type)}
                    >
                      <p>
                        <DogPettingIcon name={type.toLowerCase()} /> {type}
                      </p>
                    </div>
                  ))}
                </div>
                {errors.petType && (
                  <div className="error-message">{errors.petType}</div>
                )}
              </div>
              <div className="add-pet-center">
                {[
                  { name: "petName", placeholder: "Name", type: "text" },
                  {
                    name: "petBreed",
                    placeholder: "Select Breed",
                    type: "select",
                    options: breedList,
                  },
                  {
                    name: "petBirthday",
                    placeholder: "Date Of Birth",
                    type: "date",
                  },
                  {
                    name: "petGender",
                    placeholder: "Select Gender",
                    type: "select",
                    options: [
                      { id: "male", BreedName: "Male" },
                      { id: "female", BreedName: "Female" },
                    ],
                  },
                  {
                    name: "petRegisteredDate",
                    placeholder: "Registered Date",
                    type: "date",
                  },
                  {
                    name: "petKernelNumber",
                    placeholder: "Kernel and club number",
                    type: "text",
                  },
                ].map((field) => (
                  <div key={field.name} className="add-pet-input-group">
                    {field.type === "select" ? (
                      <select
                        value={eval(field.name)}
                        onChange={(e) =>
                          eval(
                            `set${
                              field.name.charAt(0).toUpperCase() +
                              field.name.slice(1)
                            }`
                          )(e.target.value)
                        }
                        aria-label={field.placeholder}
                        className={!eval(field.name) ? "placeholder" : ""}
                      >
                        <option value="" disabled>
                          {field.placeholder}
                        </option>
                        {field.options.map((option) => (
                          <option
                            key={option.id}
                            value={option.BreedName || option.id}
                          >
                            {option.BreedName || option}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <input
                        type={field.type === "date" ? "text" : field.type}
                        placeholder={field.placeholder}
                        value={eval(field.name)}
                        onChange={(e) =>
                          eval(
                            `set${
                              field.name.charAt(0).toUpperCase() +
                              field.name.slice(1)
                            }`
                          )(e.target.value)
                        }
                        onFocus={
                          field.type === "date"
                            ? (e) => (e.target.type = "date")
                            : undefined
                        }
                        onBlur={
                          field.type === "date"
                            ? (e) =>
                                (e.target.type = e.target.value
                                  ? "date"
                                  : "text")
                            : undefined
                        }
                        aria-label={field.placeholder}
                      />
                    )}
                    {errors[field.name] && (
                      <div className="error-message">{errors[field.name]}</div>
                    )}
                  </div>
                ))}
              </div>

              {petType && (
                <div
                // className={`vaccination-opt ${
                //   showVaccinationOpt ? "professional-animate" : ""
                // }`}
                >
                  <div className="add-pet-center-header">
                    <h3>
                      {petType === "Dog or Canids"
                        ? "Is your Dog rabies vaccinated?"
                        : "Is your pet Vaccinated?"}
                    </h3>
                  </div>
                  <div className="selection-input">
                    <label>
                      <input
                        type="radio"
                        name="rabiesVaccination"
                        checked={isRabiesVaccinated === true}
                        onChange={() => handleRabiesVaccinationChange(true)}
                      />
                      &nbsp; Yes
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="rabiesVaccination"
                        checked={isRabiesVaccinated === false}
                        onChange={() => handleRabiesVaccinationChange(false)}
                      />
                      &nbsp; No
                    </label>
                  </div>
                </div>
              )}

              <div className="add-pet-center">
                {[
                  {
                    name: "vaccinationSlot",
                    placeholder: "Book a slot for vaccination",
                    type: "date",
                  },
                  {
                    name: "petWeight",
                    placeholder: "Pet Weight",
                    type: "text",
                  },
                  {
                    name: "petDoctorName",
                    placeholder: "Pet Doctor's Name",
                    type: "text",
                  },
                  {
                    name: "doctorContactNumber",
                    placeholder: "Doctor's Contact Number",
                    type: "text",
                  },
                  {
                    name: "doctorAddress",
                    placeholder: "Doctor's Address",
                    type: "text",
                  },
                ].map((field) => (
                  <div key={field.name} className="add-pet-input-group">
                    <input
                      type={field.type === "date" ? "text" : field.type}
                      placeholder={field.placeholder}
                      value={eval(field.name)}
                      onChange={(e) =>
                        eval(
                          `set${
                            field.name.charAt(0).toUpperCase() +
                            field.name.slice(1)
                          }`
                        )(e.target.value)
                      }
                      onFocus={
                        field.type === "date"
                          ? (e) => (e.target.type = "date")
                          : undefined
                      }
                      onBlur={
                        field.type === "date"
                          ? (e) =>
                              (e.target.type = e.target.value ? "date" : "text")
                          : undefined
                      }
                      aria-label={field.placeholder}
                    />
                    {errors[field.name] && (
                      <div className="error-message">{errors[field.name]}</div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="modal-control">
              <button onClick={handleSubmit}>
                <DogPettingIcon name={"accept"} />
                Add Pet
              </button>
            </div>
          </div>
        </div>
      </div>
      {isRabiesVaccinationOpen && (
        <RabiesVaccination
          isOpen={isRabiesVaccinationOpen}
          isClose={() => setIsRabiesVaccinationOpen(false)}
          vaccinated={isRabiesVaccinated}
          petType={petType}
        />
      )}
    </>
  );
};
