import React, { useEffect, useState } from "react";
import "./ProductStore.css";
import { TiThSmall } from "react-icons/ti";
import { AiFillMedicineBox } from "react-icons/ai";
import { PiDevicesBold } from "react-icons/pi";
import { IoMdCheckboxOutline } from "react-icons/io";
import Header from "../../Components/Header/Header";
import Home from "./Components/Home/Home";
import FoodMedicine from "./Components/FoodMedicine/FoodMedicine";
import Devices from "./Components/Devices/Devices";
import Order from "./Components/Order/Order";
import Cart from "./Components/Cart/Cart";
import Homenav from "../.././Components/HomeNav/Homenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { faCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FaHeart } from "react-icons/fa";
import Wishlist from "./Components/Wishlist/Wishlist";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "../../axios";
import Cookies from "js-cookie";
import Auth from "./Components/Auth/Auth";
import { useSelector } from "react-redux";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: -15,
    padding: "13px 9px 13px 9px  ",
    borderRadius: "15px",
    fontSize: "15px",
  },
}));

const loginToken = Cookies.get("loginToken");

export default function ProductStore() {
  const { login } = useSelector((state) => state);

  const [activeSection, setActiveSection] = useState("all");
  const [backButton, setbackButton] = useState(false);
  const [cartCount, setcartCount] = useState("");
  const [cartStatus, setcartStatus] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [refresh, setrefresh] = useState(false);
  const [wlrefresh, setwlrefresh] = useState(false);
  const [prevSection, setprevSection] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [open, setopen] = useState(false);
  const [tokenVerified, settokenVerified] = useState(true);
  console.log("LOGIN DATA", login?.details?.petInfo);

  const restrictedSections = ["cart", "wishlist", "order"];
  const availableSections = ["all", "foodMed", "devices", "home"]; // Add any other sections that are not restricted

  const handleButtonClick = (section) => {
    if (restrictedSections.includes(section) && !tokenVerified) {
      // Set active section to one of the available sections
      const newSection = availableSections.find(
        (sec) => !restrictedSections.includes(sec)
      );
      setopen(true);
      setActiveSection("");
    } else {
      setActiveSection(section);
      setprevSection(section);
    }
  };
  useEffect(() => {
    axios
      .get("/auth/verifyToken", {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((response) => {
        // console.log("RESPONSE :",response)
      })
      .catch((error) => {
        if (error.response.status == 401) {
          settokenVerified(false);
        }
      });
  });
  useEffect(() => {
    axios
      .get("/shop/cartcount", {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status == "200") {
          setcartCount(response.data.totalCount);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [cartStatus]);

  let wishlist;
  useEffect(() => {
    axios
      .get("/shop/wishlistproducts", {
        headers: { Authorization: loginToken },
      })
      .then((response) => {
        if (response.data.status == "200") {
          wishlist = response.data.message;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("/shop/searchproduct", {
        params: {
          ProductName: searchQuery,
          category: searchCategory ? searchCategory : [],
        },
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.status == "200") {
          const productResults = response.data.message?.map((product) => ({
            ...product,
            wlstatus: wishlist?.some(
              (wishlistItem) => wishlistItem?.ProductId == product.id
            ),
          }));
          setSearchResults(productResults);
          setrefresh(!refresh);
        }
      })
      .catch((error) => {
        console.error("Error searching:", error);
      });
  }, [searchQuery, searchCategory, wlrefresh]);
  console.log(activeSection);

  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div onClick={handleuserclose}>
      <section style={{ marginBottom: "15vh" }}>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
      {/* <section className="homepage-navspace">
        <Homenav />
      </section> */}
      <section className="product-pet-home">
        <h1>Coming Soon</h1>
        <img
          className="store-coming-soon"
          src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/coming+soon+store.png"
        />
        <p>
          Exciting news ahead! While our web app gears up for launch, why not
          seize the moment and get our mobile app from the Play Store or Apple
          Store? Stay tuned for more updates. Happy downloading!
        </p>
        <div className="store-coming-soon-applink">
          <a
            href="https://apps.apple.com/in/app/peteye/id6450916668"
            target="_blank"
          >
            <img src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/app-store-badge+1.png" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.peteye.app&pli=1"
            target="_blank"
          >
            <img src="https://peteye-drive.s3.ap-south-1.amazonaws.com/landing+page/google-play-badge+1.png" />
          </a>
        </div>
      </section>
      {/* <section className="store">
        <div className="storeleft">
          <div className="arrow">
            <FontAwesomeIcon
              icon={faCircleLeft}
              onClick={() => setbackButton(true)}
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />{" "}
            &ensp;&ensp;
            <FontAwesomeIcon
              icon={faCircleRight}
              style={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          </div>

          <div
            className={`${
              activeSection === "all" ? "storeleftbtn-active" : "storeleftbtn"
            }`}
          >
            <button
              onClick={() => {
                handleButtonClick("all");
                setSearchCategory(["all"]);
                setSearchResults([]);
              }}
            >
              <TiThSmall
                className={`${
                  activeSection === "all" ? "svg-icon-active" : "svg-icon"
                }`}
              />
              <p>All</p>
            </button>
          </div>

          <div
            className={`${
              activeSection === "foodMed"
                ? "storeleftbtn-active"
                : "storeleftbtn"
            }`}
          >
            <button
              onClick={() => {
                handleButtonClick("foodMed");
                setSearchCategory(["Food", "Medicine"]);
                setSearchResults([]);
              }}
            >
              <AiFillMedicineBox
                className={`${
                  activeSection === "foodMed" ? "svg-icon-active" : "svg-icon"
                }`}
              />
              <p>Food / Medicine</p>
            </button>
          </div>

          <div
            className={`${
              activeSection === "devices"
                ? "storeleftbtn-active"
                : "storeleftbtn"
            }`}
          >
            <button
              onClick={() => {
                handleButtonClick("devices");
                setSearchCategory(["Device"]);
                setSearchResults([]);
              }}
            >
              <PiDevicesBold
                className={`${
                  activeSection === "devices" ? "svg-icon-active" : "svg-icon"
                }`}
              />
              <p>Devices</p>
            </button>
          </div>

          <div
            className={`${
              activeSection === "order" ? "storeleftbtn-active" : "storeleftbtn"
            }`}
          >
            <button
              onClick={() => {
                handleButtonClick("order");
                setSearchCategory(["order"]);
              }}
            >
              <IoMdCheckboxOutline
                className={`${
                  activeSection === "order" ? "svg-icon-active" : "svg-icon"
                }`}
              />
              <p>My order</p>
            </button>
          </div>

          <div
            className={`${
              activeSection === "cart" ? "storeleftbtn-active" : "storeleftbtn"
            }`}
          >
            <button onClick={() => handleButtonClick("cart")}>
              {tokenVerified ? (
                <StyledBadge
                  className={`${
                    activeSection === "cart" ? "svg-icon-active" : "svg-icon"
                  }`}
                  overlap="circular"
                  badgeContent={cartCount ? cartCount : "0"}
                  color="error"
                >
                  <ShoppingCartIcon />
                </StyledBadge>
              ) : (
                <ShoppingCartIcon
                  className={`${
                    activeSection === "cart" ? "svg-icon-active" : "svg-icon"
                  }`}
                />
              )}

              <p>Cart</p>
            </button>
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <div className="search">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              name=""
              id=""
              placeholder="Search..."
            />
            <div
              onClick={() => handleButtonClick("wishlist")}
              className="wishlist"
            >
              <FaHeart style={{ color: "red" }} />
            </div>
          </div>
          <div className="storerightbox">
            <>
              {activeSection === "all" && <Home />}
              {activeSection === "foodMed" && (
                <FoodMedicine
                  setcartStatus={setcartStatus}
                  cartStatus={cartStatus}
                  searchResults={searchResults}
                  Refresh={refresh}
                  wlrefresh={wlrefresh}
                  setwlrefresh={setwlrefresh}
                />
              )}
              {activeSection === "devices" && (
                <Devices
                  setcartStatus={setcartStatus}
                  cartStatus={cartStatus}
                  searchResults={searchResults}
                  Refresh={refresh}
                  wlrefresh={wlrefresh}
                  setwlrefresh={setwlrefresh}
                />
              )}
              {activeSection === "order" && tokenVerified ? <Order /> : ""}
              {activeSection === "wishlist" && (
                <Wishlist
                  setcartStatus={setcartStatus}
                  cartStatus={cartStatus}
                />
              )}
              {activeSection === "cart" && tokenVerified ? (
                <Cart
                  backButton={backButton}
                  setbackButton={setbackButton}
                  setcartStatus={setcartStatus}
                  cartStatus={cartStatus}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </div>
      </section> */}
      {/* <section>
                <Homenav/>
            </section> */}

      {open ? <Auth open={open} handleClose={setopen} /> : ""}
    </div>
  );
}
