import React, { useState, useEffect } from "react";
import "./Nfc.css";
import Header from "../../Components/Header/Header";
import Homenav from "../../Components/HomeNav/Homenav";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { GiStopSign } from "react-icons/gi";
import { BsLink } from "react-icons/bs";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import { ChoosePetModal } from "./ChoosePetModal";
import { ConnectPetModal } from "./ConnectPetModal";
import axios from "../../axios";
import Cookies from "js-cookie";
import { Map } from "./Map";
import { useSelector } from "react-redux";
import { ConnectedPetModal } from "./ConnectedPetModal";
import { Sessions } from "./Sessions";

export default function Nfc() {
  const loginToken = Cookies.get("loginToken");
  const [connectorModal, setConnectorModal] = useState(false);
  const [choosePetModal, setChoosePetModal] = useState(false);
  const [petModal, setPetModal] = useState(false);
  const [qrCode, setqrCode] = useState("");
  const { login } = useSelector((state) => state);
  const [connectedPet, setConnectedPet] = useState([]);
  const [connectedPetProfile, setConnectedPetProfile] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const petInfo = login.details.petInfo;

  console.log(petInfo);

  const toggleConnectorModal = () => {
    setConnectorModal((prev) => !prev);
  };

  const selectPetModal = () => {
    setChoosePetModal((prev) => !prev);
  };

  const openPetModal = () => {
    setPetModal((prev) => !prev);
  };

  const fetchConnectedPets = async () => {
    try {
      const response = await axios.get("/nfc/connectedpets", {
        headers: {
          Authorization: loginToken,
        },
      });
      const connectedPets = response?.data?.message;
      console.log("Connected pets:", connectedPets);

      if (!Array.isArray(connectedPets)) {
        console.error(
          "Expected connectedPets to be an array, but got:",
          connectedPets
        );
        return;
      }
      setConnectedPetProfile(connectedPets);
      setConnectedPet(connectedPets.map((pet) => pet.petinfo));
    } catch (error) {
      console.error("Error fetching connected pets:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConnectedPets();
  }, []);

  const updateConnectedPets = async () => {
    try {
      // Fetch updated connected pets
      await fetchConnectedPets();
    } catch (error) {
      console.error("Error updating connected pets:", error);
    }
  };
  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  console.log("Connected pets:", connectedPet);
  console.log("Connected pet profile:", connectedPetProfile);

  return (
    <div onClick={handleuserclose}>
      <section style={{ marginBottom: "15vh" }}>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
      {/* <section className="homepage-navspace">
        <Homenav />
      </section> */}
      <section className="nfc-page-section">
        <section className="pet-connection-section">
          <div className="connected-pets">
            <div className="pet-images">
              <div className="connector" onClick={toggleConnectorModal}>
                <BsLink className="connector-icon" />
                <p>Connect Device</p>
              </div>
              <div className="pet-image">
                {loading ? (
                  <div className="skeleton">
                    <div className="skeleton-circle"></div>
                    <div className="skeleton-text"></div>
                  </div>
                ) : connectedPet.length > 0 ? (
                  connectedPet.map((pet) => (
                    <React.Fragment key={pet.id}>
                      <img
                        src={
                          pet.petProfileImage ||
                          "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/Rectangle+3(4).png"
                        }
                        alt={pet.petName}
                        onClick={openPetModal}
                      />
                      <p>{pet.petName}</p>
                    </React.Fragment>
                  ))
                ) : (
                  <>
                    <div className="skeleton">
                      <div className="skeleton-circle"></div>
                      <div className="skeleton-text"></div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Live - Past Sessions */}
          <Sessions />
        </section>
        <section className="pet-map-section">
          <Map />
        </section>
      </section>
      {/* Modals */}
      {connectorModal && (
        <ConnectPetModal
          isClose={toggleConnectorModal}
          header={true}
          className={"looking"}
          headerText={"Looking for code"}
          selectPetModal={selectPetModal}
          setqrCode={setqrCode}
        />
      )}
      {choosePetModal && (
        <ChoosePetModal
          isClose={selectPetModal}
          petInfo={petInfo}
          qrCode={qrCode}
          selectPetModal={selectPetModal}
          updateConnectedPets={updateConnectedPets}
          toggleConnectorModal={toggleConnectorModal}
        />
      )}
      {petModal && (
        <ConnectedPetModal
          isClose={openPetModal}
          isOpen={true}
          className={"normal"}
          connectedPet={connectedPetProfile}
          openPetModal={openPetModal}
          updateConnectedPets={updateConnectedPets}
        />
      )}
    </div>
  );
}
