import React from "react";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import SuccessModal from "./successModal";

function Terms({ settermsComplete }) {
  const signupToken = Cookies.get("signupToken");

  const { petInfo, userInfo, petProof, medRecord, refresh } = useSelector(
    (state) => state
  );

  const pdf =
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Terms+%26+condition/Terms-and-Conditions-for-APP-and-WEB-202.pdf";
  const navigate = useNavigate();

  const [checked, setChecked] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let value = {
      userInfo: {
        Mobilenumber: userInfo.userInfo.Mobilenumber,
        SecondaryMobilenumber: userInfo.userInfo.SecondaryMobilenumber,
        firstName: userInfo.userInfo.firstName,
        lastName: userInfo.userInfo.lastName,
        Pincode: userInfo.userInfo.Pincode,
        Nationality: userInfo.userInfo.Nationality,
        State: userInfo.userInfo.State,
        District: userInfo.userInfo.District,
        City: userInfo.userInfo.City,
        Area: userInfo.userInfo.Area,
        HouseFloorNo: userInfo.userInfo.HouseFloorNo,
        Address2: userInfo.userInfo.Address2,
        Login_os: "",
      },
      petInfo: {
        petName: petInfo.petInfo.petName,
        petType: petInfo.petInfo.petType,
        petBreed: petInfo.petInfo.petBreed,
        birthDate: petInfo.petInfo.birthDate,
        petWeight: petInfo.petInfo.petWeight + "KG",
        sex: petInfo.petInfo.sex,
        kennelClubNumber: petInfo.petInfo.kennelClubNumber,
        kennelClubRegisterdDate: petInfo.petInfo.kennelClubRegisterdDate,
        vetDoctorName: petInfo.petInfo.vetDoctorName,
        vetDoctorNumber: petInfo.petInfo.vetDoctorNumber,
        vetDoctorLocation: petInfo.petInfo.vetDoctorLocation,
        Vaccination: petInfo.petInfo.Vaccination,
        nonvaccination: petInfo.petInfo.nonvaccination,
      },
      petProof: petProof?.petProof,
      petMedicalHistory: [
        ...(medRecord?.medRecord?.medRecord
          ? medRecord.medRecord.medRecord
          : []),
        {
          petFrontImage: medRecord?.medRecord?.images?.frontImg || "",
          petBackImage: medRecord?.medRecord?.images?.backImg || "",
          petRightImage: medRecord?.medRecord?.images?.rightImg || "",
          petLeftImage: medRecord?.medRecord?.images?.leftImg || "",
        },
      ],
    };

    console.log(value);

    axios
      .post(
        "/user/add",
        { ...value },
        {
          headers: {
            Authorization: signupToken,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status == "200") {
          Cookies.set("loginToken", signupToken, { expires: expirationDate });
          settermsComplete(true);
          setOpen(!open);
          setTimeout(() => {
            dispatch({ type: "refresh" });
            window.location.reload();
            navigate("/");
          }, 4000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div style={{ marginTop: "-5vh" }}>
      <div style={{ position: "relative", width: "100%", paddingTop: "45%" }}>
        <embed
          src={pdf + "#toolbar=0"}
          type="application/pdf"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ display: "flex", alignItems: "center", gap: ".5vw" }}>
          <div className="checkbox-terms">
            <Checkbox
              checked={checked}
              onChange={handleCheckboxChange}
              color="default"
              inputProps={{ "aria-label": "Checkbox demo" }}
              sx={{
                "& .MuiSvgIcon-root": {
                  fontSize: {
                    xs: "0.5rem", // Extra-small screens
                    sm: ".5rem", // Small screens
                    md: "1.5rem", // Medium screens
                    lg: "2vw", // Large screens
                    xl: "2vw", // Extra-large screens
                  },
                },
              }}
            />
          </div>
          <p
            style={{
              fontSize: "clamp(0.8rem, .8vw, 2.5rem)",
              lineHeight: "2.5vh",
              marginTop: ".7vw",
            }}
          >
            I Acknowledge that i am the legal owner of the pet and agreeing to
            any terms and conditions associated with the registration process.
          </p>
        </div>
        <div
          className="petInfo-modal-btn"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "3vh",
          }}
        >
          <button type="submit" disabled={!checked}>
            Agree
          </button>
        </div>
      </form>
      <SuccessModal open={open} handleClose={handleClose} />
    </div>
  );
}

export default Terms;
