import React, { useEffect, useState } from "react";
import DogPettingIcon from "../Homepage/DogPettingIcon";
import { GiStopSign } from "react-icons/gi";
import Cookies from "js-cookie";
import axios from "../../axios";
import Failedgif from "../Failed/Failedgif";

export const Sessions = () => {
  const loginToken = Cookies.get("loginToken");

  const [livePastSessions, setLivePastSessions] = useState(false);
  const [liveSessions, setLiveSessions] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const getSessions = async () => {
      setLoading(true);
      setError("");
      try {
        const [liveResponse, pastResponse] = await Promise.all([
          axios.get("/nfc/activesessions", {
            headers: {
              Authorization: loginToken,
            },
          }),
          axios.get("/nfc/scannedhistory", {
            headers: {
              Authorization: loginToken,
            },
          }),
        ]);

        setLiveSessions(liveResponse.data.message || []);
        setPastSessions(pastResponse.data.message || []);
      } catch (error) {
        setError("Failed to fetch sessions. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    getSessions();
  }, [loginToken]);

  console.log("Live Sessions:", liveSessions, "Past Sessions:", pastSessions);
  const renderSessions = (sessions, type) => {
    if (loading) {
      return (
        <p
          className="rule-valid"
          style={{
            width: "max-content",
          }}
        >
          Loading {type} sessions...
        </p>
      );
    }

    if (error) {
      return <p className="error-message">{error}</p>;
    }

    if (sessions.length === 0) {
      return (
        <p className="error-message">
          No {type.toLowerCase()} sessions available.
        </p>
      );
    }

    return sessions.map((session) => (
      <div className="live-past-sessions-box" key={session.id}>
        <div className="live-past-sessions-img">
          <img
            src={session.image || "default-image-url.png"}
            alt={session.name || "Pet"}
          />
          <div className="about-pet-nfc">
            <h3>{session.name || "Unknown"}</h3>
            <p className={type === "Live" ? "live" : "past"}>{type} Session</p>
          </div>
        </div>
        <div className="live-past-sessions-control">
          <GiStopSign className="live-past-sessions-icon" />
        </div>
      </div>
    ));
  };

  return (
    <div className="live-past-sessions">
      <div
        className={`live-past-sessions-toggle ${
          livePastSessions ? "past-tab-active" : ""
        }`}
      >
        <button
          className={!livePastSessions ? "active" : ""}
          onClick={() => setLivePastSessions(false)}
        >
          <DogPettingIcon name={"Lost"} active={livePastSessions} />
          Live Session
        </button>
        <button
          className={livePastSessions ? "active" : ""}
          onClick={() => setLivePastSessions(true)}
        >
          <DogPettingIcon name={"Found"} active={!livePastSessions} />
          Past Session
        </button>
      </div>

      <div className="live-past-sessions-content">
        {livePastSessions
          ? renderSessions(pastSessions, "Past")
          : renderSessions(liveSessions, "Live")}
      </div>
    </div>
  );
};
