import React, { useState } from "react";

export default function CartItems({ category, items }) {
  const [cartData, setCartData] = useState(items);

  const handleWeightChange = (id, selectedWeight) => {
    const newCartData = cartData.map((item) => {
      if (item.id === id) {
        const weightOption = item.weightOptions.find(
          (option) => option.value === selectedWeight
        );
        return {
          ...item,
          selectedWeight,
          price: item.basePrice * weightOption.priceMultiplier, // Update price based on multiplier
        };
      }
      return item;
    });
    setCartData(newCartData);
  };

  const handleQuantityChange = (id, delta) => {
    const newCartData = cartData.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          quantity: Math.max(1, item.quantity + delta), // Ensure quantity doesn't go below 1
        };
      }
      return item;
    });
    setCartData(newCartData);
  };

  return (
    <div className="shopcart-item">
      <h1>{category}</h1>
      <div className="shop-cart-items">
        {cartData.map((item) => (
          <div className="shop-cart-item" key={item.id}>
            <div className="shop-cart-item-image">
              <img src={item.src} alt={item.name} />
            </div>
            <div className="shop-cartitemdetails">
              <div>
                <h3>{item.name}</h3>
                <p>{item.description}</p>
              </div>
              <div className="shop-cart-item-price-quantity">
                {/* Dropdown for selecting quantity (weight) */}
                <select
                  className="shop-cart-item-weight"
                  value={item.selectedWeight}
                  onChange={(e) => handleWeightChange(item.id, e.target.value)}
                >
                  {item.weightOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="shop-cart-item-details">
              <div className="shop-cart-item-quantity">
                {/* Quantity Controls */}
                <button
                  className="quantity-btn"
                  onClick={() => handleQuantityChange(item.id, -1)}
                >
                  -
                </button>
                <span className="quantity-display">{item.quantity}</span>
                <button
                  className="quantity-btn"
                  onClick={() => handleQuantityChange(item.id, 1)}
                >
                  +
                </button>
              </div>
              <p>{item.price}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
