import React, { useState, useEffect } from "react";
import "./ProductActions.css";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import Failedgif from "../../../Pages/Failed/Failedgif";
import foodimage from "../image 16426.png";
import CartItems from "./CartItems";
import WishlistItems from "./WishlistItems";
import { LuUpload } from "react-icons/lu";
import { IoDocumentText } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import Prescription from "./Prescription";
import Checkout from "./Checkout/Checkout";

import { AiFillFilePdf } from "react-icons/ai";
import axios from "../../../axios";
import Success from "../../../Pages/Profile/Success/Success";
import Delete from "../../../Components/Delete/Delete";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";

export default function ProductActions({ isOpen, isClose }) {
  const [isClosing, setIsClosing] = useState(false);
  const [activeTab, setActiveTab] = useState("cart");
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [isClosing1, setIsClosing1] = useState(false);
  const [prescriptionOpen, setPrescriptionOpen] = useState(false);
  const [checkoutOpen, setCheckoutOpen] = useState(false);
  const loginToken = Cookies.get("loginToken");
  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;
  const dispatch = useDispatch();

  // Initialize cartData as an empty array
  // const [cartData, setCartData] = useState([
  //   {
  //     id: 1,
  //     basePrice: 20,
  //     category: "Food & Others",
  //     price: 20,
  //     name: "Dog Food",
  //     price: "$20",
  //     description: "High-protein dog food",
  //     src: foodimage,
  //     quantity: 1,
  //     selectedWeight: "500g",
  //     weightOptions: [
  //       { value: "500g", label: "500g", priceMultiplier: 1 },
  //       { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
  //       { value: "2kg", label: "2kg", priceMultiplier: 2 },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     basePrice: 20,
  //     price: 20,
  //     category: "Food & Others",
  //     name: "Leash",
  //     price: "$15",
  //     description: "Durable dog leash",
  //     src: foodimage,
  //     quantity: 1,
  //     selectedWeight: "500g",
  //     weightOptions: [
  //       { value: "500g", label: "500g", priceMultiplier: 1 },
  //       { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
  //       { value: "2kg", label: "2kg", priceMultiplier: 2 },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     basePrice: 20,
  //     name: "Dog Bed",
  //     category: "Medicines",
  //     price: "$50",
  //     description: "Comfortable dog bed",
  //     src: foodimage,
  //     quantity: 1,
  //     selectedWeight: "500g",
  //     weightOptions: [
  //       { value: "500g", label: "500g", priceMultiplier: 1 },
  //       { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
  //       { value: "2kg", label: "2kg", priceMultiplier: 2 },
  //     ],
  //   },
  //   {
  //     id: 4,
  //     basePrice: 20,
  //     name: "Chew Toy",
  //     category: "Medicines",
  //     price: "$10",
  //     description: "Rubber chew toy",
  //     src: foodimage,
  //     quantity: 1,
  //     selectedWeight: "500g",
  //     weightOptions: [
  //       { value: "500g", label: "500g", priceMultiplier: 1 },
  //       { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
  //       { value: "2kg", label: "2kg", priceMultiplier: 2 },
  //     ],
  //   },
  //   {
  //     id: 5,
  //     basePrice: 20,
  //     category: "Vaccines",
  //     name: "Shampoo",
  //     price: "$8",
  //     description: "Dog grooming shampoo",
  //     src: foodimage,
  //     quantity: 1,
  //     selectedWeight: "500g",
  //     weightOptions: [
  //       { value: "500g", label: "500g", priceMultiplier: 1 },
  //       { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
  //       { value: "2kg", label: "2kg", priceMultiplier: 2 },
  //     ],
  //   },
  // ]);
  const [cartData, setCartData] = useState([]);
  useEffect(() => {
    if (isOpen) {
      fetchCartItems();
    }
  }, [isOpen]);

  // Filter cart items by category
  // const foodItems = cartData.filter(
  //   (item) => item.category === "Food & Others"
  // );
  // const medicineItems = cartData.filter(
  //   (item) => item.category === "Medicines"
  // );
  // const vaccineItems = cartData.filter((item) => item.category === "Vaccines");

  const [wishlistData] = useState([
    {
      id: 1,
      name: "Cat Food",
      basePrice: 20,
      category: "Medicines",
      price: "$18",
      description: "Healthy cat food",
      src: foodimage,
      quantity: 1,
      weightOptions: [
        { value: "500g", label: "500g", priceMultiplier: 1 },
        { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
        { value: "2kg", label: "2kg", priceMultiplier: 2 },
      ],
    },
    {
      id: 2,
      basePrice: 20,
      name: "Cat Tower",
      category: "Vaccines",
      price: "$45",
      description: "Multi-level cat tower",
      src: foodimage,
      quantity: 1,
      weightOptions: [
        { value: "500g", label: "500g", priceMultiplier: 1 },
        { value: "1kg", label: "1kg", priceMultiplier: 1.5 },
        { value: "2kg", label: "2kg", priceMultiplier: 2 },
      ],
    },
    // ... (more items)
  ]);
  const fetchCartItems = async () => {
    try {
      const response = await axios.get("/shop/gettocart", {
        headers: {
          Authorization: loginToken,
        },
      });
      setCartData(response.data);

      console.log("Cart items:", response.data);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      // isClose();
      handleClose();
      handleUploadModalClose();
    }
  };
  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      const url = response.data.Message ? response.data.Message.getPeteye : "";
      console.log("File uploaded successfully:", url);
      return url;
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };
  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      try {
        const file = selectedFiles[0];
        const url = await uploadFileToS3(file);
        if (url) {
          const response = await axios.post(
            "/petinfo/addpetdocuments",
            {
              documentList: [
                {
                  docType: "AdoptionPaperFile",
                  fileType: "pdf",
                  url: url,
                  fileName: file.name,
                },
              ],
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          );
          console.log(" addpetdocuments added:", response);
          setUploadStatus("success");
          setUploadedFile(selectedFiles[0]);
          dispatch({ type: "refresh" });
        } else {
          setUploadStatus("failed");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        setUploadStatus("failed");
      }
    }
    setTimeout(() => {
      setUploadStatus("");
      setIsUploadModalOpen(false);
    }, 3000);
  };
  const handleUploadModalClose = () => {
    setIsClosing1(true);
    setTimeout(() => {
      setIsClosing1(false);
      setIsUploadModalOpen(false);
      setUploadStatus("");
      setSelectedFiles([]);
    }, 1000);
    // Clear selectedFiles here to ensure it resets
  };

  const handleFileSelect = (e) => {
    const files = Array.from(e.target.files);
    setSelectedFiles(files);
  };

  const handleFileDelete = () => {
    setDeleteModal(true);
  };

  if (!isOpen) return null;

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const handleConfirmDelete = async () => {
    // if (fileId) {
    //   try {
    //     await axios.post(
    //       "/petinfo/deletePetDocument",
    //       { selectedIds: [fileId] },
    //       {
    //         headers: {
    //           Authorization: loginToken,
    //         },
    //       }
    //     );
    //     // Update state after successful deletion
    //     setUploadedFile(null);
    //     setFileUrl("");
    //     setFileId(null);
    //     dispatch({ type: "refresh" });
    //     console.log("File deleted successfully");
    //   } catch (error) {
    //     console.error("Error deleting file:", error);
    //     // Optionally, show an error message
    //   }
    // }
    // setDeleteModal(false);
  };

  const handleCancelDelete = () => {
    setDeleteModal(false);
  };

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000);
  };

  const handlePrescription = () => {
    setPrescriptionOpen((prev) => !prev);
  };
  const handleCheckout = () => {
    setCheckoutOpen((prev) => !prev);
  };
  const renderCartItems = () => {
    const categories = ["Food & Others", "Medicines", "Vaccines"];
    return categories.map((category) => (
      <CartItems
        key={category}
        category={category}
        items={cartData.filter((item) => item.category === category)}
        onUpdateCart={fetchCartItems}
      />
    ));
  };

  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`modal-container ${isClosing ? "close" : ""}`}>
          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
          <div className="shop-cart-wishlist">
            <div
              className={`shop-cart-toggle weight-toggle ${
                activeTab === "cart"
                  ? "family-tab-active"
                  : "community-tab-active"
              }`}
            >
              <button
                className={activeTab === "cart" ? "active" : ""}
                onClick={() => handleTabClick("cart")}
              >
                <DogPettingIcon
                  name={
                    activeTab === "cart"
                      ? "header-addtocart-activeicon"
                      : "header-addtocart"
                  }
                />
                Cart
              </button>
              <button
                className={activeTab === "wishlist" ? "active" : ""}
                onClick={() => handleTabClick("wishlist")}
              >
                <DogPettingIcon
                  name={
                    activeTab === "wishlist"
                      ? "shop-wishlistbutton-activeicon"
                      : "shop-wishlistbutton-nonactiveicon"
                  }
                />
                Wishlist
              </button>
            </div>
          </div>

          <div className="shop-cart-wishlistmodal-content">
            {/* Conditionally render data based on active tab */}
            {activeTab === "cart" ? (
              <>
                <div className="cart-items">
                  {cartData.length ? (
                    <div className="shop-cart-itemslist">
                      {/* <div>
                        <CartItems category="Food & Others" items={foodItems} />
                        <CartItems category="Medicines" items={medicineItems} />
                        <CartItems category="Vaccines" items={vaccineItems} />
                      </div>
                      <div className="cart-prescription">
                        {uploadedFile ? (
                          <>
                            <p>Prescription Uploaded</p>
                            <span onClick={() => setIsUploadModalOpen(true)}>
                              <DogPettingIcon name="cart-upload-prescriptionicon" />
                            </span>
                          </>
                        ) : (
                          <>
                            <p>Add Prescription</p>
                            <span onClick={() => setIsUploadModalOpen(true)}>
                              <DogPettingIcon name="cart-upload-prescriptionicon" />
                            </span>
                          </>
                        )}
                      </div>
                      <div className="shop-cart-ordersummary">
                        <h3>Order Summary</h3>
                        <div className="shop-cart-ordersummary-details">
                          <div className="shop-cart-ordersummary-list">
                            <h6>Subtotal</h6>
                            <p>₹ 100</p>
                          </div>
                          <div className="shop-cart-ordersummary-list">
                            <h6>Discount</h6>
                            <p>₹ 100</p>
                          </div>
                          <div className="shop-cart-ordersummary-list">
                            <h6>GST</h6>
                            <p>₹ 100</p>
                          </div>
                          <div className="shop-cart-ordersummary-list">
                            <h6>Shipping</h6>
                            <p>₹ 100</p>
                          </div>
                          <div className="shop-cart-line"></div>
                          <div className="shop-cart-ordersummary-list">
                            <h6>Total</h6>
                            <p>₹ 400</p>
                          </div>
                        </div>
                        <div className="shop-cart-ordersummary-list cart-total-savings">
                          <h6>Your total savings</h6>
                          <p>₹ 400</p>
                        </div>
                      </div> */}
                      <div>{renderCartItems()}</div>
                    </div>
                  ) : (
                    <Failedgif message="Oops! Your Cart is empty." />
                  )}
                </div>
                {uploadedFile ? (
                  <div className="shopcart-checkoutbuttons">
                    <button
                      className="shop-cart-button"
                      onClick={handlePrescription}
                    >
                      <DogPettingIcon name="check" />
                      &ensp; Checkout
                    </button>
                  </div>
                ) : (
                  <div className="shopcart-checkoutbuttons">
                    <button
                      className="shop-cart-button"
                      onClick={handleCheckout}
                    >
                      <DogPettingIcon name="check" />
                      &ensp; Checkout
                    </button>
                  </div>
                )}
              </>
            ) : (
              <div className="cart-items">
                {wishlistData.length ? (
                  <>
                    <WishlistItems items={wishlistData} />
                  </>
                ) : (
                  <Failedgif message="Oops! Your Wishlist is empty." />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {prescriptionOpen && (
        <Prescription
          isClose={handlePrescription}
          isOpen={handlePrescription}
        />
      )}
      {checkoutOpen && (
        <Checkout isClose={handleCheckout} isOpen={handleCheckout} />
      )}
      {isUploadModalOpen && (
        <div
          className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
          onClick={handleOverlayClick}
        >
          <div className={`modal-container ${isClosing ? "close" : ""}`}>
            <div className="add-new-documents">
              <div className="modal-close" onClick={handleUploadModalClose}>
                <DogPettingIcon
                  name={"closemodal"}
                  className="modal-close-icon"
                />
              </div>

              {uploadStatus === "success" ? (
                <Success
                  message="Upload successfully!"
                  header="Success"
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                />
              ) : uploadStatus === "failed" ? (
                <Success
                  message="Oops! No files found."
                  gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                />
              ) : (
                <div className="add-new-documents-content">
                  <div className="records-Upload-title">
                    <p>
                      <IoDocumentText
                        style={{ fontSize: "clamp(1.3rem, 1.5vw ,4rem)" }}
                      />
                    </p>
                    <h2>Upload Documents</h2>
                  </div>

                  <div className="upload-doc-files">
                    <label htmlFor="fileInputUpload">
                      <p>
                        <DogPettingIcon name="upload-doc" />
                      </p>
                      <div className="upload-doc-files-title">
                        <h5>
                          <LuUpload /> <h6>Upload</h6>
                        </h5>
                        <p>Maximum Size: 3MB</p>
                      </div>
                    </label>
                    <input
                      type="file"
                      accept="application/pdf"
                      id="fileInputUpload"
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                    />
                  </div>

                  {selectedFiles.length > 0 && (
                    <div className="uploaded-preview-list">
                      {selectedFiles.map((file, index) => (
                        <div key={index} className="uploaded-file-preview">
                          <div className="uploaded-file-preview-title">
                            <h4>
                              <AiFillFilePdf
                                style={{
                                  fontSize: "clamp(1.5rem, 1.5vw ,5rem)",
                                }}
                              />
                            </h4>
                            <div className="uploaded-file-preview-name">
                              <h5>{file.name}</h5>
                              <p>{(file.size / 1024 / 1024).toFixed(2)} MB</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  {selectedFiles.length > 0 && (
                    <div className="upload-file-btn" onClick={handleFileUpload}>
                      <p>
                        <LuUpload
                          style={{ fontSize: "clamp(1.3rem, 1.5vw ,3.5rem)" }}
                        />
                      </p>
                      <p>Upload</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Delete Confirmation Modal */}
      {deleteModal && (
        <Delete
          handleDelete={handleConfirmDelete}
          rejectDelete={handleCancelDelete}
          message="Do you really want to remove this file?"
        />
      )}
    </div>
  );
}
