import React, { useState } from "react";
import "./PetMedical.css";
import { BsPaperclip } from "react-icons/bs";
import { IoIosCheckmarkCircle } from "react-icons/io";
import DeclarationModal from "./DeclarationModal";
import MedicalModal from "./MedicalModal";
import axios from "../../../../../axios";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import AlertBox from "../../../../../Components/Alert/Alert";

function PetMedical({ setmediComplete, setopenTerms, setopenPetMedical }) {
  const dispatch = useDispatch();
  const signupToken = Cookies.get("signupToken");

  const { petInfo, userInfo, petProof, medRecord } = useSelector(
    (state) => state
  );
  console.log("Registration DETAILS", petInfo, userInfo, petProof, medRecord);

  const [medHistory, setMedHistory] = useState("");
  const [genHistory, setGenHistory] = useState("");
  const [frontImg, setFrontImg] = useState("");
  const [backImg, setBackImg] = useState("");
  const [rightImg, setRightImg] = useState("");
  const [leftImg, setLeftImg] = useState("");
  const [open, setOpen] = useState(false);
  const [openMedModal, setOpenMedModal] = useState(false);
  const [uploadedURI, setuploadedURI] = useState("");

  const [openAlert, setopenAlert] = useState(false);
  const [Open, setopen] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const handleSkip = () => {
    handleOpen();
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const handleCloseMedModal = () => setOpenMedModal(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const uploadPromises = [];
    if (medHistory.veterinaryVisitHis) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.veterinaryVisitHis },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (medHistory.PastSurgeries) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.PastSurgeries },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (medHistory.bloodFecalTest) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.bloodFecalTest },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (medHistory.dietDetails) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.dietDetails },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (medHistory.medicationDetails) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.medicationDetails },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (medHistory.prescriptions) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: medHistory.prescriptions },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (genHistory) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: genHistory },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (frontImg) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: frontImg },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (backImg) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: backImg },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (rightImg) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: rightImg },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }
    if (leftImg) {
      uploadPromises.push(
        axios.post(
          "/file/uploadFile",
          { file: leftImg },
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: signupToken,
            },
          }
        )
      );
    }

    Promise.all(uploadPromises)
      .then((response) => {
        console.log(response);
        let MedUri = [],
          ImgURI = [];
        let uniqueUploadedMedURI,
          uniqueUploadedImgURI,
          MedUniqueSet,
          ImgUniqueSet;
        response.forEach((response, index) => {
          if (response?.data?.Message?.getPeteye) {
            const link = response?.data?.Message?.getPeteye;
            const parts = link.split("/");
            const filename = parts.pop();
            const checkFileName = (fileType, fileName, medHistory, link) => {
              if (medHistory[fileType]?.name?.replace(/\s/g, "") === fileName) {
                MedUri.push({
                  url: link,
                  fileType: medHistory[fileType]?.type,
                  fileName: fileType,
                  docType: fileType,
                });
              }
            };
            const checkGenHistory = (fileType, fileName, file, link) => {
              if (fileType?.name?.replace(/\s/g, "") === fileName) {
                MedUri.push({
                  url: link,
                  fileType: fileType.type,
                  fileName: file,
                  docType: "GeneticalHistory",
                });
              }
            };
            const checkFile = (fileType, fileName, file, link) => {
              if (fileType?.name?.replace(/\s/g, "") === fileName) {
                console.log(file, ":", link);
                ImgURI.push({
                  fileName: file,
                  url: link,
                  type: fileType.type,
                  date: new Date(),
                });
              }
            };

            checkFileName(
              "Veterinary Visit History",
              filename,
              medHistory,
              link
            );
            checkFileName("Past surgeries", filename, medHistory, link);
            checkFileName(
              "Deworming Records & Sterilization Status",
              filename,
              medHistory,
              link
            );
            checkFileName(
              "Details on Diet & Exercise",
              filename,
              medHistory,
              link
            );
            checkFileName("Diagnostic Tests", filename, medHistory, link);
            checkFileName("Prescriptions", filename, medHistory, link);
            checkGenHistory(genHistory, filename, "Genetic History", link);
            checkFile(frontImg, filename, "Front Image", link);
            checkFile(backImg, filename, "Back Image", link);
            checkFile(rightImg, filename, "Right Image", link);
            checkFile(leftImg, filename, "Left Image", link);
          }
        });
        MedUniqueSet = new Set(MedUri.map(JSON.stringify));
        uniqueUploadedMedURI = Array.from(MedUniqueSet).map(JSON.parse);
        ImgUniqueSet = new Set(ImgURI.map(JSON.stringify));
        uniqueUploadedImgURI = Array.from(ImgUniqueSet).map(JSON.parse);
        let petImage = {};
        uniqueUploadedImgURI.forEach((item, index) => {
          const key = item.fileName;
          petImage[key] = {
            url: item.url,
            type: item.type,
            date: new Date().toISOString(),
          };
        });

        dispatch({
          type: "medRecord",
          payload: {
            medRecord: { medRecord: uniqueUploadedMedURI, images: petImage },
          },
        });
        handleOpen();
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.data?.status == 400) {
          setopenAlert(!openAlert);
          setErrMessage(err?.response?.data?.message);
        } else {
          setopenAlert(!openAlert);
          setErrMessage("Something went wrong");
        }
      });
  };

  return (
    <section className="petMed-sec1">
      <form onSubmit={handleSubmit}>
        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {medHistory ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Medical History
                </div>
              )}
            </label>
          </div>

          <div>
            <label className="add-document-label">
              <p onClick={() => setOpenMedModal(true)}>
                <BsPaperclip /> Attach file
              </p>
            </label>
            {/* <input id="med-file-upload" type='file' onChange={(e) => setMedHistory(e.target.files[0])} style={{ display: 'none' }} /> */}
          </div>

          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${medHistory ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {genHistory ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Genetic History
                </div>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="gen-file-upload" className="add-document-label">
              <p>
                <BsPaperclip /> Attach file
              </p>
            </label>
            <input
              id="gen-file-upload"
              accept=".png, .jpg, .jpeg, .pdf"
              type="file"
              onChange={(e) => setGenHistory(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${genHistory ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <p className="petMed-p">Pet Image</p>

        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {frontImg ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Front Side
                </div>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="front-file-upload" className="add-document-label">
              <p>
                <BsPaperclip /> Attach file
              </p>
            </label>
            <input
              id="front-file-upload"
              accept=".png, .jpg, .jpeg, .pdf"
              type="file"
              onChange={(e) => setFrontImg(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${frontImg ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {backImg ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Back Side
                </div>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="back-file-upload" className="add-document-label">
              <p>
                <BsPaperclip /> Attach file
              </p>
              <input
                id="back-file-upload"
                accept=".png, .jpg, .jpeg, .pdf"
                type="file"
                onChange={(e) => setBackImg(e.target.files[0])}
                style={{ display: "none" }}
              />
            </label>
          </div>
          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${backImg ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {rightImg ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Right Side
                </div>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="right-file-upload" className="add-document-label">
              <p>
                <BsPaperclip /> Attach file
              </p>
            </label>
            <input
              id="right-file-upload"
              accept=".png, .jpg, .jpeg, .pdf"
              type="file"
              onChange={(e) => setRightImg(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${rightImg ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <div className="petMed-sub">
          <div className="petMed-img-field">
            <label className="add-document-label">
              {leftImg ? (
                "file uploaded"
              ) : (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  Left side
                </div>
              )}
            </label>
          </div>
          <div>
            <label htmlFor="left-file-upload" className="add-document-label">
              <p>
                <BsPaperclip /> Attach file
              </p>
            </label>
            <input
              id="left-file-upload"
              accept=".png, .jpg, .jpeg, .pdf"
              type="file"
              onChange={(e) => setLeftImg(e.target.files[0])}
              style={{ display: "none" }}
            />
          </div>
          <IoIosCheckmarkCircle
            style={{
              fontSize: "20px",
              color: `${leftImg ? "#65C294" : "black"}`,
            }}
          />
        </div>

        <div className="petIden-btn">
          <button
            style={{
              backgroundColor: medHistory && genHistory ? "black" : "#3939398a",
            }}
            type="submit"
          >
            Next
          </button>
        </div>
      </form>
      <div className="petIden-Sk-btn">
        <button onClick={() => handleSkip()}>Skip</button>
      </div>
      <MedicalModal
        open={openMedModal}
        handleClose={handleCloseMedModal}
        setMedHistory={setMedHistory}
      />
      <DeclarationModal
        open={open}
        handleClose={handleClose}
        setmediComplete={setmediComplete}
        setopenTerms={setopenTerms}
        setopenPetMedical={setopenPetMedical}
      />
      {openAlert ? (
        <AlertBox
          open={true}
          setOpen={setopen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      ) : (
        ""
      )}
    </section>
  );
}

export default PetMedical;
