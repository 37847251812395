import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import Store from "./Store/Store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BookmarkProvider } from "./context/BookmarkContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BookmarkProvider>
      <Provider store={Store}>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <App />
        </GoogleOAuthProvider>
      </Provider>
    </BookmarkProvider>
  </React.StrictMode>
);
