import React, { useState } from "react";
import "./Settings.css";
import DogPettingIcon from "../../../Homepage/DogPettingIcon";
import { OwnerProfileModal } from "./OwnerProfileModal";
import { PrivacySecurityModal } from "./PrivacySecurityModal";
import { AddPetModal } from "./AddPetModal";
import { PetLicensesModal } from "./PetLicenseModal";
import { InviteOthersModal } from "./InviteOthersModal";
import { LogoutModal } from "./LogoutModal";

const Settings = () => {
  const [activeModal, setActiveModal] = useState(null);

  const settingsData = [
    {
      id: 1,
      img: <DogPettingIcon name={"owner-profile"} />,
      data: "Owner Profile",
      disabled: false,
    },
    {
      id: 2,
      img: <DogPettingIcon name={"privacy"} />,
      data: "Privacy & Security",
      disabled: false,
    },
    {
      id: 3,
      img: <DogPettingIcon name={"add-pet"} />,
      data: "Add Pet",
      disabled: true,
    },
    {
      id: 4,
      img: <DogPettingIcon name={"pet-license"} />,
      data: "Pet Licenses",
      disabled: false,
    },
    {
      id: 5,
      img: <DogPettingIcon name={"petowner-transfer"} />,
      data: "Transfer Ownership",
      disabled: false,
    },
    {
      id: 6,
      img: <DogPettingIcon name={"logout"} />,
      data: "Log Out",
      disabled: false,
    },
  ];

  const openModal = (id) => {
    if (!settingsData.find((item) => item.id === id).disabled) {
      setActiveModal(id);
    }
  };

  const closeModal = () => {
    setActiveModal(null);
  };

  return (
    <div>
      <div className="grid-container">
        {settingsData.map((item) => (
          <div
            key={item.id}
            className={`grid-item ${item.disabled ? "disabled" : ""}`}
            onClick={() => openModal(item.id)}
          >
            <div className="svgicon">{item.img}</div>
            <h3>{item.data}</h3>
          </div>
        ))}
      </div>
      {activeModal === 1 && (
        <OwnerProfileModal isOpen={activeModal === 1} isClose={closeModal} />
      )}
      {activeModal === 2 && (
        <PrivacySecurityModal isOpen={activeModal === 2} isClose={closeModal} />
      )}
      {activeModal === 3 && (
        <AddPetModal isOpen={activeModal === 3} isClose={closeModal} />
      )}
      {activeModal === 4 && (
        <PetLicensesModal isOpen={activeModal === 4} isClose={closeModal} />
      )}
      {activeModal === 5 && (
        <InviteOthersModal isOpen={activeModal === 5} isClose={closeModal} />
      )}
      {activeModal === 6 && (
        <LogoutModal isOpen={activeModal === 6} isClose={closeModal} />
      )}
    </div>
  );
};

export default Settings;
