import { useEffect, useState } from "react";
import { FaClock } from "react-icons/fa";
import { BsCalendarDateFill } from "react-icons/bs";
import "./HomePageTab.css";
import { Link } from "react-router-dom";
import DogPettingIcon from "./DogPettingIcon";
import { useBookmark } from "../../context/BookmarkContext";
import Toast from "../../Components/Toast/Toast";

export const HomePageTab = ({ articles: initialArticles }) => {
  const [articles, setArticles] = useState(initialArticles);
  const {
    bookmarks,
    addBookmark,
    deleteBookmark,
    isLoading,
    error: bookmarkError,
    isInitialized,
    fetchBookmarks,
  } = useBookmark();
  const [toast, setToast] = useState(null);
  const [bookmarkStatusError, setBookmarkStatusError] = useState(false);

  useEffect(() => {
    setArticles(initialArticles);
  }, [initialArticles]);

  useEffect(() => {
    if (isInitialized && bookmarkError) {
      console.error("Bookmark error detected:", bookmarkError);
      setBookmarkStatusError(true);
    } else {
      setBookmarkStatusError(false);
    }
  }, [bookmarkError, isInitialized]);

  const handleToggleBookmark = async (article) => {
    if (bookmarkStatusError) {
      setToast(
        "Bookmark functionality is currently unavailable. Please try again later."
      );
      return;
    }

    const isBookmarked = bookmarks.some(
      (bookmark) => bookmark.articleId === article.id
    );
    try {
      let success;
      if (isBookmarked) {
        const bookmarkToDelete = bookmarks.find(
          (bookmark) => bookmark.articleId === article.id
        );
        if (bookmarkToDelete) {
          success = await deleteBookmark(bookmarkToDelete.id);
        }
      } else {
        success = await addBookmark(article.id);
      }

      if (success) {
        setToast(isBookmarked ? "Bookmark removed" : "Bookmark added");
      } else {
        throw new Error("Failed to update bookmark");
      }
    } catch (error) {
      console.error("Error toggling bookmark:", error);
      setToast("Error updating bookmark. Please try again.");
      // Retry fetching bookmarks in case the error was due to initialization issues
      fetchBookmarks();
    }
  };

  const trimText = (text, maxLength) => {
    if (typeof text === "string") {
      return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
    } else if (Array.isArray(text) && text.length > 0) {
      return text[0].length > maxLength
        ? text[0].slice(0, maxLength) + "..."
        : text[0];
    } else {
      console.error("Invalid text format:", text);
      return "";
    }
  };

  const trimTitle = (title) => trimText(title, 45);
  const trimDesc = (desc) => trimText(desc, 65);

  const formatDuration = (duration) => {
    const roundedDuration = parseFloat(duration).toFixed(1);
    return `${roundedDuration} min read`;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="tab-cards">
        {articles.map((article) => {
          const isBookmarked = bookmarks.some(
            (bookmark) => bookmark.articleId === article.id
          );
          return (
            <div className="tab-card" key={article.id} id={`#${article.niche}`}>
              <Link to={`/article/${article.id}`}>
                <div className="tab-card-img">
                  <img src={article.webImage} alt={article.title} />
                </div>
                <div className="card-data-content">
                  <div className="tab-card-content">
                    <h3>{trimTitle(article.title)}</h3>
                    <p>{trimDesc(article?.summary[0])}</p>
                  </div>
                </div>
              </Link>
              <div>
                <div className="tab-card-footer">
                  <p>
                    <FaClock /> {formatDuration(article.duration)}
                  </p>
                  <p>
                    <BsCalendarDateFill /> {formatDate(article.createdAt)}
                  </p>
                  <p className="tab-card-bookmark">
                    <div className={`bookmark-container`}>
                      <button
                        className="bookmark-button"
                        disabled={isLoading || !isInitialized}
                        onClick={() => handleToggleBookmark(article)}
                      >
                        <DogPettingIcon
                          name={isBookmarked ? "bookmarked" : "bookmark"}
                        />
                      </button>
                      {bookmarkStatusError && (
                        <span
                          className="bookmark-error-indicator"
                          title="Bookmark functionality is limited"
                        >
                          ⚠️
                        </span>
                      )}
                    </div>
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {toast && <Toast message={toast} onClose={() => setToast(null)} />}
    </>
  );
};
