import React, { useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import DogPettingIcon from "../../Pages/Homepage/DogPettingIcon";
import Notification from "./Notification/Notification";
import Faqs from "./Faqs/Faqs";
import { OwnerProfileModal } from "../../Pages/Profile/Petprofile/Settings/OwnerProfileModal";
import { PrivacySecurityModal } from "../../Pages/Profile/Petprofile/Settings/PrivacySecurityModal";
import { LogoutModal } from "../../Pages/Profile/Petprofile/Settings/LogoutModal";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";

const loginToken = Cookies.get("loginToken");

const logo =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Header-footerwebp/image16407.webp";

const Header = ({ isOpen, setIsOpen }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [homeSubmenuOpen, setHomeSubmenuOpen] = useState(false);
  const [NotificationOpen, setNotificationOpen] = useState(false);
  const [FaqOpen, setFaqOpen] = useState(false);
  const { login } = useSelector((state) => state);
  const userInfo = login?.details?.userInfo;
  const [profileOpen, setProfileOpen] = useState(false);
  const [PrivacySecurityOpen, setPrivacySecurityOpen] = useState(false);
  const [LogoutOpen, setLogoutOpen] = useState(false);
  const location = useLocation();

  const toggleDropdown = (e) => {
    setIsOpen((prev) => !prev);
  };
  console.log(loginToken, "loginToken");
  console.log(userInfo, "userInfo");

  const handleNotification = () => {
    setNotificationOpen((prev) => !prev);
  };

  const handleFaq = () => {
    setFaqOpen((prev) => !prev);
  };

  const handleProfile = () => {
    setProfileOpen((prev) => !prev);
  };

  const handlePrivacySecurity = () => {
    setPrivacySecurityOpen((prev) => !prev);
  };

  const handleLogout = () => {
    setLogoutOpen((prev) => !prev);
  };

  const navItems = [
    { path: "/", label: "Home" },
    { path: "/nfc", label: "PetLink" },
    { path: "/store", label: "Shop" },
    { path: "/profile", label: "Profile" },
  ];

  return (
    <div>
      <header className="mainHeader">
        <img className="logo" src={logo} alt="peteye logo" title="" />
        {userInfo && loginToken ? (
          <>
            <div>
              <div className="header-homenav">
                {navItems.map((item) => (
                  <Link
                    key={item.path}
                    style={{ textDecoration: "none" }}
                    to={item.path}
                  >
                    <div
                      className={`header-homenav-text ${
                        location.pathname === item.path ? "active" : ""
                      }`}
                    >
                      <p>{item.label}</p>
                    </div>
                  </Link>
                ))}
                <div className="header-profileline"></div>
                <a className="header-website" href="https://www.peteye.pet/">
                  Website
                </a>
              </div>
            </div>
            <div className="links">
              <div className="header-bellfaq-icons">
                <span>
                  <DogPettingIcon name={"header-search"} />
                </span>
                <Link to="/store">
                  <span>
                    <DogPettingIcon name={"header-shop-cart"} />
                  </span>
                </Link>
                <span onClick={toggleDropdown}>
                  <DogPettingIcon name={"header-user"} />
                </span>
                <div className={`dropdown-menu ${isOpen ? "open" : ""}`}>
                  <div className="dropdown-content">
                    <div onClick={handleProfile} className="dropdown-item">
                      Profile
                    </div>
                    <div onClick={handleNotification} className="dropdown-item">
                      Notification
                    </div>
                    <div
                      onClick={handlePrivacySecurity}
                      className="dropdown-item"
                    >
                      Privacy & Security
                    </div>
                    <div onClick={handleFaq} className="dropdown-item">
                      FAQ
                    </div>
                    <div onClick={handleLogout} className="dropdown-item">
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="header-bellfaq-icons">
            <span>
              <DogPettingIcon name={"header-search"} />
            </span>
            <Link to="/store">
              <span>
                <DogPettingIcon name={"header-shop-cart"} />
              </span>
            </Link>
            <span>
              <DogPettingIcon name={"header-user"} />
            </span>
            <div className="header-profileline"></div>
            <a className="header-website" href="https://www.peteye.pet/">
              Website
            </a>
          </div>
        )}
      </header>
      {NotificationOpen && (
        <Notification
          isOpen={handleNotification}
          isClose={handleNotification}
        />
      )}
      {FaqOpen && <Faqs isOpen={handleFaq} isClose={handleFaq} />}
      {profileOpen && (
        <OwnerProfileModal isOpen={handleProfile} isClose={handleProfile} />
      )}
      {PrivacySecurityOpen && (
        <PrivacySecurityModal
          isOpen={handlePrivacySecurity}
          isClose={handlePrivacySecurity}
        />
      )}
      {LogoutOpen && (
        <LogoutModal isOpen={handleLogout} isClose={handleLogout} />
      )}
    </div>
  );
};

export default Header;
