import React, { useState, useEffect } from "react";
import "./VerificationOTP.css";
import OtpInput from "react-otp-input";
import RegHeader from "../Registration/RegHeader/RegHeader";
import NewPassword from "../ForgotPassword/NewPassword";
import { useSelector } from "react-redux";
import axios from "../../../../axios";
import AlertBox from "../../../../Components/Alert/Alert";
import Cookies from "js-cookie";

function VerificationOTP({ status, value }) {
  const { user } = useSelector((state) => state);
  const [otp, setOtp] = useState("");
  const [showReg, setShowReg] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [timer, setTimer] = useState(90);
  const [canResend, setCanResend] = useState(false);
  const signupToken = Cookies.get("signupToken");

  useEffect(() => {
    startTimer();
  }, []);

  const startTimer = () => {
    setCanResend(false);
    setTimer(90);
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 0) {
          clearInterval(interval);
          setCanResend(true);
          return 0;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const isEmail = (value) => {
    return value.includes("@");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (status == "confirmVf") {
      axios
        .post(
          "/auth/verifyOtpEmail",
          { otp: otp },
          {
            headers: {
              Authorization: signupToken,
            },
          }
        )
        .then((response) => {
          if (response.data.status == "400") {
            setOpenAlert(true);
            setErrMessage(response.data.message);
            return;
          }
          if (response.data.status == "200") {
            status === "confirmVf" ? setShowReg(true) : setShowNewPass(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  console.log("SignUp Token: ", signupToken);

  const handleResendOtp = () => {
    if (!canResend) return;
    console.log("SignUp Token: ", signupToken);
    const endpoint = isEmail(value)
      ? "/auth/sendOtpEmail"
      : "/auth/sendOtpMobile";
    console.log({ [isEmail(value) ? "email" : "mobile"]: value });
    axios
      .get(
        endpoint,
        // { [isEmail(value) ? "email" : "mobile"]: value },
        {
          headers: {
            Authorization: signupToken,
          },
        }
      )
      .then((response) => {
        if (response.data.status == "200") {
          startTimer();
        } else {
          setOpenAlert(true);
          setErrMessage(response.data.message || "Failed to resend OTP");
        }
      })
      .catch((err) => {
        console.log(err);
        setOpenAlert(true);
        setErrMessage("An error occurred while resending OTP");
      });
  };

  return (
    <>
      {showReg ? (
        <RegHeader />
      ) : showNewPass ? (
        <NewPassword />
      ) : (
        <>
          <div className="confm-vf-div">
            <h3>Verification OTP</h3>
            <p>
              We have sent the verification code to your{" "}
              {isEmail(value) ? "email address" : "mobile number"}
            </p>
            <h4>{value}</h4>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="vf-otp-box">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>&nbsp;</span>}
                renderInput={(props) => (
                  <input
                    {...props}
                    style={{
                      width: "2.5vw",
                      height: "7vh",
                      fontSize: "clamp(0.8rem, 1.2vw, 3rem)",
                      margin: "0 .5vw",
                      textAlign: "center",
                      background: "#F1F5F9",
                      borderRadius: ".5vw",
                      border: "none",
                    }}
                  />
                )}
              />
            </div>
            <div className="vf-otp-p">
              <p
                style={{
                  color: "#707070",
                  fontSize: "clamp(0.8rem, 1vw, 3rem)",
                }}
              >
                {canResend ? (
                  <>
                    Didn't receive any OTP?{" "}
                    <strong
                      style={{
                        color: "#000",
                        cursor: "pointer",
                        fontSize: "clamp(0.8rem, 1vw, 3rem)",
                      }}
                      onClick={handleResendOtp}
                    >
                      Resend Now
                    </strong>
                  </>
                ) : (
                  `Resend OTP in ${timer} seconds`
                )}
              </p>
            </div>
            <div className="vf-otp-btn">
              <button type="submit">Continue</button>
            </div>
          </form>
        </>
      )}
      {openAlert && (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setOpenAlert}
          errMessage={errMessage}
        />
      )}
    </>
  );
}

export default VerificationOTP;
