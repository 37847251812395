import React, { useEffect, useState } from "react";
import "./PetInfo.css";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../../../axios";
import { petInfoValidateForm } from "./PetInfoValidation";
import AlertBox from "../../../../../Components/Alert/Alert";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import { RabiesVaccination } from "../../../../Profile/Petprofile/Settings/RabiesVaccination";
import PetInfoModal from "./PetInfoModal";
import AddPetModal from "./AddPetModal";

const dog_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/dogIcon.png";
const cat_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/catIcon.png";
const other_Img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/OtherIcon.png";

function PetInfo({
  setopenPetInfo,
  setopenPetIdentity,
  setinfoCompleted,
  setopenTerms,
}) {
  const dispatch = useDispatch();
  const [petName, setPetName] = useState("");
  const [petBreed, setPetBreed] = useState("");
  const [petGender, setPetGender] = useState("");
  const [petBirthday, setPetBirthday] = useState("");
  const [petRegisteredDate, setPetRegisteredDate] = useState("");
  const [petKernelNumber, setPetKernelNumber] = useState("");
  const [isRabiesVaccinated, setIsRabiesVaccinated] = useState(null);
  const [vaccinationSlot, setVaccinationSlot] = useState("");
  const [petWeight, setPetWeight] = useState("");
  const [petDoctorName, setPetDoctorName] = useState("");
  const [doctorContactNumber, setDoctorContactNumber] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [petType, setPetType] = useState(null);
  const [isRabiesVaccinationOpen, setIsRabiesVaccinationOpen] = useState(false);
  const [breedList, setBreedList] = useState([]);
  const [errors, setErrors] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [openAlert, setopenAlert] = useState(false);
  const [openA, setOpenA] = useState(true);
  const [errMessage, setErrMessage] = useState("");
  const [vaccinationData, setVaccinationData] = useState(null);
  const [open, setOpen] = useState(false);
  const [OpenAddPet, setOpenAddPet] = useState(false);
  const [VaccinationList, setVaccinationList] = useState([]);
  const [modalFormData, setModalFormData] = useState([]);
  const [AddPetData, setAddPetData] = useState("");

  const loginToken = Cookies.get("loginToken");
  const signupToken = Cookies.get("signupToken");

  const getPetTypeForAPI = (type) => {
    if (type === "Dog or Canids") return "Dog";
    if (type === "Cat or Feline") return "Cat";
    return "Other";
  };

  useEffect(() => {
    getBreedList();
  }, []);

  const getBreedList = async () => {
    try {
      const response = await axios.get("/user/getBreedlist", {
        headers: {
          Authorization: signupToken,
        },
      });
      setBreedList(response.data.message);
    } catch (error) {
      console.error("Error fetching breed list:", error);
    }
  };

  const handlePetTypeSelect = (type) => {
    setPetType(type === petType ? null : type);
    setIsRabiesVaccinated(null);
    setVaccinationData(null);
  };

  const handleRabiesVaccinationChange = (status) => {
    setIsRabiesVaccinated(status);
    setIsRabiesVaccinationOpen(true);
  };

  const handleVaccinationDataChange = (data) => {
    setVaccinationData(data);
    setIsRabiesVaccinationOpen(false);
  };

  const validateInputs = () => {
    const newErrors = {};
    const errorMessages = [];
    const requiredFields = {
      petName: "Pet name",
      petBreed: "Pet breed",
      petGender: "Pet gender",
      petBirthday: "Pet birthday",
      petRegisteredDate: "Registered date",
      petKernelNumber: "Kernel and club number",
      petWeight: "Pet weight",
      petDoctorName: "Pet doctor's name",
      doctorContactNumber: "Doctor's contact",
      doctorAddress: "Doctor's address",
      petType: "Pet type",
    };

    Object.entries(requiredFields).forEach(([field, label]) => {
      if (!eval(field)) {
        newErrors[field] = `${label} is required.`;
        errorMessages.push(`${label} is required.`);
      }
    });

    setErrors(newErrors);
    setErrorMessages(errorMessages);

    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      errorMessages.forEach((error, index) => {
        setTimeout(() => {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: error,
          }));
          if (index === errorMessages.length - 1) {
            setTimeout(() => {
              setErrors({});
            }, 3000);
          }
        }, index * 3000);
      });
      return;
    }

    console.log(vaccinationData, "vaccinationData from PetInfo");

    if (!vaccinationData) {
      // Alert the user if there's no vaccination data
      setopenAlert(true);
      setErrMessage(
        "Problem in vaccination. Please provide vaccination information."
      );
      return; // Stop the form submission
    }

    const petInfo = {
      petName,
      petType: getPetTypeForAPI(petType),
      petBreed,
      birthDate: petBirthday,
      petWeight,
      sex: petGender,
      kennelClubNumber: petKernelNumber,
      KennelClubRegisterdDate: petRegisteredDate,
      vetDoctorName: petDoctorName,
      vetDoctorNumber: doctorContactNumber,
      vetDoctorLocation: doctorAddress,
    };

    // Attach the vaccination data received from RabiesVaccination component
    if (vaccinationData.Vaccination) {
      petInfo.Vaccination = vaccinationData.Vaccination;
    }

    // Always include nonvaccination, even if it's an empty string
    petInfo.nonvaccination = vaccinationData.nonvaccination || "";

    console.log("petInfo:", petInfo);
    const formValidation = petInfoValidateForm(petInfo);
    if (formValidation === true) {
      dispatch({ type: "petInfo", payload: { petInfo: petInfo } });
      setopenPetInfo(false);
      setopenTerms(true);
      setinfoCompleted(true);
    } else {
      setopenAlert(true);
      setErrMessage(formValidation);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenAddPet = () => setOpenAddPet(true);
  const handleCloseAddPet = () => setOpenAddPet(false);

  return (
    <section>
      <form onSubmit={handleSubmit}>
        <div className="add-pet">
          <div className="add-pet-header">
            <h3>Choose your pet type</h3>
            <div className="pet-selection-box">
              {["Dog or Canids", "Cat or Feline", "Others"].map((type) => (
                <div
                  key={type}
                  className={`pet-box ${
                    petType === type ? "pet-selected" : ""
                  }`}
                  onClick={() => handlePetTypeSelect(type)}
                >
                  <p>
                    <DogPettingIcon name={type.toLowerCase()} /> {type}
                  </p>
                </div>
              ))}
            </div>
            {errors.petType && (
              <div className="error-message">{errors.petType}</div>
            )}
          </div>
          <div className="add-pet-center" style={{ padding: "5px" }}>
            {[
              { name: "petName", placeholder: "Name", type: "text" },
              {
                name: "petBreed",
                placeholder: "Select Breed",
                type: "select",
                options: breedList,
              },
              {
                name: "petBirthday",
                placeholder: "Date Of Birth",
                type: "date",
              },
              {
                name: "petGender",
                placeholder: "Select Gender",
                type: "select",
                options: [
                  { id: "Male", BreedName: "Male" },
                  { id: "Female", BreedName: "Female" },
                ],
              },
              {
                name: "petRegisteredDate",
                placeholder: "Kennel Club Registered Date",
                type: "date",
              },
              {
                name: "petKernelNumber",
                placeholder: "Kennel Club Number",
                type: "text",
              },
            ].map((field) => (
              <div key={field.name} className="add-pet-input-group">
                {field.type === "select" ? (
                  <select
                    value={eval(field.name)}
                    onChange={(e) =>
                      eval(
                        `set${
                          field.name.charAt(0).toUpperCase() +
                          field.name.slice(1)
                        }`
                      )(e.target.value)
                    }
                    aria-label={field.placeholder}
                    className={!eval(field.name) ? "placeholder" : ""}
                    style={{ width: "18vw" }}
                  >
                    <option value="" disabled>
                      {field.placeholder}
                    </option>
                    {field.options.map((option) => (
                      <option
                        key={option.id}
                        value={option.BreedName || option.id}
                      >
                        {option.BreedName || option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type={field.type === "date" ? "text" : field.type}
                    placeholder={field.placeholder}
                    value={eval(field.name)}
                    style={{ width: "18vw" }}
                    onChange={(e) =>
                      eval(
                        `set${
                          field.name.charAt(0).toUpperCase() +
                          field.name.slice(1)
                        }`
                      )(e.target.value)
                    }
                    onFocus={
                      field.type === "date"
                        ? (e) => (e.target.type = "date")
                        : undefined
                    }
                    onBlur={
                      field.type === "date"
                        ? (e) =>
                            (e.target.type = e.target.value ? "date" : "text")
                        : undefined
                    }
                    aria-label={field.placeholder}
                  />
                )}
                {errors[field.name] && (
                  <div className="error-message">{errors[field.name]}</div>
                )}
              </div>
            ))}
          </div>

          {petType && (
            <div>
              <div className="add-pet-center-header">
                <h3>
                  {petType === "Dog or Canids"
                    ? "Is your Dog rabies vaccinated?"
                    : "Is your pet Vaccinated?"}
                </h3>
              </div>
              <div className="selection-input">
                <label>
                  <input
                    type="radio"
                    name="rabiesVaccination"
                    checked={isRabiesVaccinated === true}
                    onChange={() => handleRabiesVaccinationChange(true)}
                  />
                  &nbsp; Yes
                </label>
                <label>
                  <input
                    type="radio"
                    name="rabiesVaccination"
                    checked={isRabiesVaccinated === false}
                    onChange={() => handleRabiesVaccinationChange(false)}
                  />
                  &nbsp; No
                </label>
              </div>
            </div>
          )}

          <div className="add-pet-center">
            {[
              {
                name: "vaccinationSlot",
                placeholder:
                  petType === "Dog or Canids"
                    ? "Select Date for Vaccination"
                    : "Book a slot for vaccination",
                type: "date",
              },
              {
                name: "petWeight",
                placeholder: "Pet Weight (e.g., 30kg)",
                type: "text",
              },
              {
                name: "petDoctorName",
                placeholder: "Pet Doctor's Name",
                type: "text",
              },
              {
                name: "doctorContactNumber",
                placeholder: "Doctor's Contact Number",
                type: "text",
              },
              {
                name: "doctorAddress",
                placeholder: "Doctor's Address",
                type: "text",
              },
            ].map((field) => (
              <div key={field.name} className="add-pet-input-group">
                <input
                  type={field.type === "date" ? "text" : field.type}
                  placeholder={field.placeholder}
                  value={eval(field.name)}
                  style={{ width: "18vw" }}
                  onChange={(e) =>
                    eval(
                      `set${
                        field.name.charAt(0).toUpperCase() + field.name.slice(1)
                      }`
                    )(e.target.value)
                  }
                  onFocus={
                    field.type === "date"
                      ? (e) => (e.target.type = "date")
                      : undefined
                  }
                  onBlur={
                    field.type === "date"
                      ? (e) =>
                          (e.target.type = e.target.value ? "date" : "text")
                      : undefined
                  }
                  aria-label={field.placeholder}
                />
                {errors[field.name] && (
                  <div className="error-message">{errors[field.name]}</div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="modal-control">
          <button type="submit">
            <DogPettingIcon name={"accept"} />
            Add Pet
          </button>
        </div>
      </form>

      {isRabiesVaccinationOpen && (
        <RabiesVaccination
          isOpen={isRabiesVaccinationOpen}
          isClose={() => setIsRabiesVaccinationOpen(false)}
          vaccinated={isRabiesVaccinated}
          petType={petType}
          onVaccinationDataChange={handleVaccinationDataChange}
        />
      )}

      <AddPetModal
        open={OpenAddPet}
        handleClose={handleCloseAddPet}
        setAddPetData={setAddPetData}
      />
      {openAlert && (
        <AlertBox
          open={true}
          setOpen={setOpenA}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      )}
    </section>
  );
}

export default PetInfo;
