import { createContext, useContext, useState, useEffect } from "react";
import axios from "../axios";
import Cookies from "js-cookie";

const initialState = {
  bookmarks: [],
  isLoading: false,
  error: null,
  isInitialized: false,
  addBookmark: () => {},
  deleteBookmark: () => {},
  fetchBookmarks: () => {},
};

const BookmarkContext = createContext(initialState);

export const BookmarkProvider = ({ children }) => {
  const [bookmarks, setBookmarks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const getLoginToken = () => Cookies.get("loginToken");

  const fetchBookmarks = async (retryCount = 0) => {
    const loginToken = getLoginToken();
    if (!loginToken) {
      console.log("No login token available, skipping bookmark fetch");
      setIsInitialized(true);
      return;
    }

    console.log("Fetching bookmarks...");
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get("/user/article/allbookmarks", {
        headers: {
          Authorization: loginToken,
        },
      });
      console.log("Fetched Bookmarked Articles:", response.data);
      if (Array.isArray(response.data.message)) {
        setBookmarks(response.data.message);
        console.log("Bookmarks set:", response.data.message);
      } else {
        console.log("No bookmarks found");
        setBookmarks([]);
      }
      setIsInitialized(true);
    } catch (error) {
      console.error("Error fetching bookmarked articles:", error);
      if (error.response) {
        console.log(
          "Error response:",
          error.response.status,
          error.response.data
        );
        if (error.response.status === 404) {
          console.log("404 error - likely no bookmarks found");
          setBookmarks([]);
          setIsInitialized(true);
        } else if (error.response.status === 401 && retryCount < 3) {
          console.log("Unauthorized, retrying in 1 second...");
          setTimeout(() => fetchBookmarks(retryCount + 1), 1000);
          return;
        } else {
          setError("Failed to fetch bookmarks");
          setIsInitialized(true);
        }
      } else if (error.request) {
        console.log("Error request:", error.request);
        setError("Network error while fetching bookmarks");
        setIsInitialized(true);
      } else {
        console.log("Error", error.message);
        setError("An unexpected error occurred");
        setIsInitialized(true);
      }
    } finally {
      setIsLoading(false);
      console.log("Fetch bookmarks completed");
    }
  };

  useEffect(() => {
    fetchBookmarks();
  }, []);

  const addBookmark = async (articleId) => {
    const loginToken = getLoginToken();
    if (!loginToken) {
      console.error("No login token available");
      setError("You must be logged in to add bookmarks");
      return false;
    }

    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `/user/article/addtobookmark/${articleId}`,
        {},
        {
          headers: { Authorization: loginToken },
        }
      );
      console.log(response.data.message);
      console.log(`Bookmark added for article ${articleId}`);
      await fetchBookmarks();
      return true;
    } catch (err) {
      const errorMessage = `Failed to add bookmark: ${
        err.response?.data?.message || err.message
      }`;
      setError(errorMessage);
      console.error("Error adding bookmark:", errorMessage);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const deleteBookmark = async (bookmarkId) => {
    const loginToken = getLoginToken();
    if (!loginToken) {
      console.error("No login token available");
      setError("You must be logged in to delete bookmarks");
      return false;
    }

    setIsLoading(true);
    setError(null);
    try {
      await axios.delete(`/user/article/deletebookmark/${bookmarkId}`, {
        headers: { Authorization: loginToken },
      });
      console.log(`Bookmark removed for bookmark ${bookmarkId}`);
      await fetchBookmarks();
      return true;
    } catch (err) {
      const errorMessage = `Failed to delete bookmark: ${
        err.response?.data?.message || err.message
      }`;
      setError(errorMessage);
      console.error("Error deleting bookmark:", err);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    bookmarks,
    isLoading,
    error,
    isInitialized,
    addBookmark,
    deleteBookmark,
    fetchBookmarks,
  };

  return (
    <BookmarkContext.Provider value={value}>
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmark = () => {
  const context = useContext(BookmarkContext);
  if (context === undefined) {
    throw new Error("useBookmark must be used within a BookmarkProvider");
  }
  return context;
};
