import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Cookies from "js-cookie";

import "./PetInfo.css";
import { useDispatch, useSelector } from "react-redux";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import axios from "../../../../../axios";
import { RabiesVaccination } from "../../../../Profile/Petprofile/Settings/RabiesVaccination";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function AddPetModal({
  open,
  handleClose,
  setAddPetData,
  isClose,
}) {
  const [pet, setpet] = React.useState("");

  const resetForm = () => {
    setpet("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pet.trim() !== "") {
      setAddPetData(pet);
      resetForm();
      handleClose(!open);
    }
  };

  return (
    <div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleSubmit}>
          <Box sx={style} className="petInfo-modal">
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Select your pet
            </Typography>

            <FormControl className="textfield-size-reg" size="small">
              <InputLabel
                id="demo-select-small-label"
                style={{
                  fontSize: "14px",
                  position: "absolute",
                  top: "30%",
                  left: "10%",
                  transform: pet ? "translate(0, 6px) scale(0)" : "none",
                }}
              >
                Gender
              </InputLabel>

              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={pet}
                placeholder="Select"
                onChange={(e) => setpet(e.target.value)}
                sx={{
                  "& .MuiSelect-root": {
                    border: "none", // Remove border
                    outline: "none", // Remove outline
                    fontSize: "14px",
                  },
                  "& .MuiInputBase-input": {
                    fontSize: "14px",
                    paddingTop: "15px", // Adjust padding as needed
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none", // Remove border on focus
                  },
                }}
              >
                <MenuItem value={"Rabbit"}>Horse</MenuItem>
                <MenuItem value={"Buffalo"}>Rabbit</MenuItem>
                <MenuItem value={"Cow"}>Cow</MenuItem>
              </Select>
            </FormControl>

            <div className="petInfo-modal-btn2">
              <button type="submit">Save</button>
            </div>
          </Box>
        </form>
      </Modal> */}
    </div>
  );
}
