import React, { useState } from "react";
import "./RegHeader.css";
import PetOwnerDetails from "../PetOwnerDetails/PetOwnerDetails";
import PetInfo from "../PetInfo/PetInfo";
import PetIdentity from "../PetIdentity/PetIdentity";
import PetMedical from "../PetMedical/PetMedical";
import Terms from "../Term&Con/Terms";
import { FaCheck } from "react-icons/fa6";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";

function RegHeader() {
  const [openPetInfo, setopenPetInfo] = useState(false);
  const [openPetIdentity, setopenPetIdentity] = useState(false);
  const [openPetMedical, setopenPetMedical] = useState(false);
  const [openTerms, setopenTerms] = useState(false);
  const [openOwner, setopenOwner] = useState(true);
  const [infoCompleted, setinfoCompleted] = useState(false);
  const [idenComplete, setidenComplete] = useState(false);
  const [mediComplete, setmediComplete] = useState(false);
  const [ownerComplete, setownerComplete] = useState(false);
  const [termsComplete, settermsComplete] = useState(false);
  console.log("ownerComplete", ownerComplete);
  return (
    <>
      <div className="reg-outer">
        <div className="header-outer">
          <div className="header-inner">
            <div className="header-circle-div">
              <div
                className={`${
                  ownerComplete
                    ? "header-circle-completed"
                    : openOwner
                    ? "header-circle-curr"
                    : "header-circle"
                }`}
              >
                {ownerComplete ? (
                  // <FaCheck className="check-icon" />
                  <p className="signupcheckicon">
                    <DogPettingIcon name={"signupcheckicon"} />
                  </p>
                ) : (
                  <span>
                    <DogPettingIcon name={"petownerdetailsicon"} />
                  </span>
                )}{" "}
              </div>
            </div>

            <div
              className={`${
                ownerComplete ? "header-line-completed" : "header-line"
              }`}
            ></div>

            <div className="header-circle-div">
              <div
                className={`${
                  infoCompleted
                    ? "header-circle-completed"
                    : openPetInfo
                    ? "header-circle-curr"
                    : "header-circle"
                }`}
              >
                {infoCompleted ? (
                  <p className="signupcheckicon">
                    <DogPettingIcon name={"signupcheckicon"} />
                  </p>
                ) : (
                  <span>
                    <DogPettingIcon name={"petinfoicon"} />
                  </span>
                )}{" "}
              </div>
            </div>

            <div
              className={`${
                infoCompleted ? "header-line-completed" : "header-line"
              }`}
            ></div>

            {/* <div className='header-circle-div'>
        <div className={`${idenComplete ? 'header-circle-completed' : openPetIdentity ? 'header-circle-curr' :'header-circle'}`}>{idenComplete ? <FaCheck className='check-icon' />:<span>3</span>} </div>
        </div>
        
        <div className={`${idenComplete ? 'header-line-completed' :'header-line'}`}></div>

        <div className='header-circle-div'>
        <div className={`${mediComplete ? 'header-circle-completed' : openPetMedical ? 'header-circle-curr' :'header-circle'}`}>{mediComplete ? <FaCheck className='check-icon' />:<span>4</span>} </div>
        </div>

        <div className={`${mediComplete ? 'header-line-completed' :'header-line'}`}></div> */}

            <div className="header-circle-div">
              <div
                className={`${
                  termsComplete
                    ? "header-circle-completed"
                    : openTerms
                    ? "header-circle-curr"
                    : "header-circle"
                }`}
              >
                {termsComplete ? (
                  <p className="signupcheckicon">
                    <DogPettingIcon name={"signupcheckicon"} />
                  </p>
                ) : (
                  <span>
                    <DogPettingIcon name={"termsicon"} />
                  </span>
                )}{" "}
              </div>
            </div>
          </div>
          <div className="circle-name-main">
            <div
              className={`${
                ownerComplete
                  ? "circle-name-header-name-completed"
                  : "circle-name-header-name"
              }`}
            >
              <p>
                Pet Owner
                <br /> Details
              </p>
            </div>
            <div
              className={`${
                infoCompleted
                  ? "circle-name-header-name"
                  : "circle-name-header-name-completed"
              }`}
            >
              <p>
                Pet <br /> Information
              </p>
            </div>
            {/* <div className='circle-name header-name'><p>Pet<br/> Identity</p></div>
        <div className='circle-name header-name'><p>Pet Medical<br/> Record</p></div> */}
            <div
              className={`${
                termsComplete
                  ? "circle-name-header-name"
                  : "circle-name-header-name-completed"
              }`}
            >
              <p>
                Terms &<br /> Conditions
              </p>
            </div>
          </div>
        </div>

        <>
          {openPetInfo ? (
            <PetInfo
              setopenPetInfo={setopenPetInfo}
              setopenPetIdentity={setopenPetIdentity}
              setinfoCompleted={setinfoCompleted}
              setopenTerms={setopenTerms}
            />
          ) : // openPetIdentity ? <PetIdentity setidenComplete={setidenComplete} setopenPetIdentity={setopenPetIdentity} setopenPetMedical={setopenPetMedical} />:
          // openPetMedical ?<PetMedical setmediComplete={setmediComplete} setopenTerms={setopenTerms} setopenPetMedical={setopenPetMedical} />:
          openTerms ? (
            <Terms settermsComplete={settermsComplete} />
          ) : (
            <PetOwnerDetails
              setopenPetInfo={setopenPetInfo}
              setownerComplete={setownerComplete}
              setopenOwner={setopenOwner}
            />
          )}
        </>
      </div>
    </>
  );
}

export default RegHeader;
