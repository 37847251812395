import React, { useState } from "react";
import { FaBirthdayCake } from "react-icons/fa";
import { BsGenderAmbiguous } from "react-icons/bs";
import { AiOutlineCloseCircle } from "react-icons/ai";
import DogPettingIcon from "../../../../Homepage/DogPettingIcon";
import "./InvitePetFamily.css";
import axios from "../../../../../axios";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

export const InvitePetFamily = ({
  isOpen,
  isClose,
  petId,
  refreshInviteList,
}) => {
  const loginToken = Cookies.get("loginToken");

  const [familyName, setFamilyName] = useState("");
  const [familyEmail, setFamilyEmail] = useState("");
  const [familyRelationship, setFamilyRelationship] = useState("");
  const [familyMobile, setFamilyMobile] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const dispatch = useDispatch();
  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let years = today.getFullYear() - birth.getFullYear();
    let months = today.getMonth() - birth.getMonth();
    let days = today.getDate() - birth.getDate();

    if (days < 0) {
      months--;
      days += new Date(today.getFullYear(), today.getMonth(), 0).getDate();
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    if (years === 0 && months === 0) {
      days = Math.floor((today - birth) / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }

    return { years, months, days };
  };

  // Usage in the component
  const getPetAge = (birthDate) => {
    const age = calculateAge(birthDate);
    if (age.years > 0) {
      return `${age.years} Years ${
        age.months > 0 ? age.months + " Months" : ""
      }`;
    } else if (age.months > 0) {
      return `${age.months} Month ${age.days > 0 ? age.days + " Day" : ""}`;
    } else {
      return `${age.days} Days`;
    }
  };

  const trimBreed = (breed) => {
    return breed.length > 12 ? breed.slice(0, 12) + " .." : breed;
  };

  const handleInvite = async () => {
    if (
      !familyName.trim() ||
      !familyEmail.trim() ||
      !familyRelationship.trim() ||
      !familyMobile.trim()
    ) {
      setError("Please fill out all fields");
      setTimeout(() => setError(""), 3500);
      return;
    }

    try {
      const response = await axios.post(
        "/sharepet/invite",
        {
          Name: familyName,
          Email: familyEmail,
          relationship: familyRelationship,
          petid: petId.id,
          petName: petId.petName,
          Mobile: familyMobile,
          petBreed: petId.petBreed,
        },
        {
          headers: {
            Authorization: loginToken,
          },
        }
      );
      console.log(
        "response: ",
        response.data.message,
        "status: ",
        response.data.status,
        "data: ",
        response.data
      );
      if (response.data.status === 402) {
        setError(response.data.message);
        setTimeout(() => setError(""), 2000);
        return;
      }

      setSuccess("Invite sent successfully");
      setTimeout(() => setSuccess(""), 1500);
      setFamilyName("");
      setFamilyEmail("");
      setFamilyRelationship("");
      setFamilyMobile("");

      // Call the refreshInviteList function after successfully sending invite
      refreshInviteList();
      dispatch({ type: "refresh" });
      isClose();
    } catch (error) {
      console.error("Error sending invite:", error);
      setError("Failed to send invite. Please try again.");
      setTimeout(() => setError(""), 1500);
    }
  };
  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      isClose();
    }, 1000); // Match the duration of the closing animation
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      handleClose();
    }
  };

  return (
    <div
      className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
      onClick={handleOverlayClick}
    >
      <div className={`modal-container ${isClosing ? "close" : ""}`}>
        <div className="modal-background">
          <img
            src={
              petId.petProfileImage ||
              "https://images.unsplash.com/photo-1543466835-00a7907e9de1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8ZG9nfGVufDB8fDB8fHww"
            }
            alt=""
            className="normal"
          />

          <div className="modal-close" onClick={handleClose}>
            <DogPettingIcon name={"closemodal"} className="modal-close-icon" />
          </div>
        </div>
        <div className="modal-description">
          <div className="pet-description normal">
            <div className="pet-name">
              <p style={{ textTransform: "capitalize" }}>{petId.petName}</p>
              <span>{trimBreed(petId.petBreed)}</span>
            </div>
            <div className="pet-info">
              <p className="birthday">
                <span>
                  <FaBirthdayCake className="cake-icon" />
                </span>
                <span>{getPetAge(petId.birthDate)}</span>
              </p>
              <p className="gender">
                <span>
                  <BsGenderAmbiguous className="gender-icon" />
                </span>
                <span>{petId.sex}</span>
              </p>
            </div>
          </div>
          <span>Invite Someone Special to Help Care for {petId.petName}</span>
          <div className="input-group">
            <input
              type="text"
              placeholder="Family Member’s Name"
              value={familyName}
              onChange={(e) => setFamilyName(e.target.value)}
            />
            <input
              type="email"
              placeholder="Family Member’s Email"
              value={familyEmail}
              onChange={(e) => setFamilyEmail(e.target.value)}
            />
            <input
              type="text"
              placeholder="Family Member’s Relationship"
              value={familyRelationship}
              onChange={(e) => setFamilyRelationship(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Family Member’s Mobile"
              value={familyMobile}
              onChange={(e) =>
                setFamilyMobile(e.target.value.replace(/[^0-9]/g, ""))
              }
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          {success && <p className="rule-valid">{success}</p>}
          <div className="invite-btn">
            <button onClick={handleInvite}>
              <DogPettingIcon name={"invite"} /> Invite
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
