import React, { useState } from "react";
import Product from "./components/Product";
import ProductActions from "./components/CartWishlist/ProductActions";
export default function Demoshop() {
  const [ProductOpen, SetProductOpen] = useState(false);

  const handleProduct = () => {
    SetProductOpen((prev) => !prev);
  };
  return (
    <>
      <div
        onClick={handleProduct}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "30vh",
          fontSize: "clamp(3rem,3vw,6rem)",
        }}
      >
        demoshop
      </div>
      {ProductOpen && (
        // <Product isOpen={handleProduct} isClose={handleProduct} />
        <ProductActions isOpen={handleProduct} isClose={handleProduct} />
      )}
    </>
  );
}
