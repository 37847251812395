import React from "react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaClock } from "react-icons/fa";
import { BsCalendarDateFill } from "react-icons/bs";
import "./HomePageTab.css";
import Failedgif from "../Failed/Failedgif";
import { useBookmark } from "../../context/BookmarkContext";
import DogPettingIcon from "./DogPettingIcon";
import Toast from "../../Components/Toast/Toast";

export default function Bookmark({ onBookmarkChange }) {
  const { bookmarks, addBookmark, deleteBookmark, isLoading, error } =
    useBookmark();
  const [toast, setToast] = useState(null);

  console.log("bookmarks from bookmark", bookmarks);

  const bookmarkId = bookmarks.map((bookmark) => bookmark.id);
  console.log("bookmarkId", bookmarkId);

  const handleToggleBookmark = async (article) => {
    const isBookmarked = bookmarks.some(
      (bookmark) => bookmark.articleId === article.id
    );
    try {
      if (isBookmarked) {
        const bookmarkToDelete = bookmarks.find(
          (bookmark) => bookmark.articleId === article.id
        );
        if (bookmarkToDelete) {
          await deleteBookmark(bookmarkToDelete.id);
          setToast("Bookmark removed");
        }
      } else {
        await addBookmark(article.id);
        setToast("Bookmark added");
      }
    } catch (error) {
      console.error("Error toggling bookmark:", error);
      setToast("Error updating bookmark");
    }
  };

  const trimDesc = (summary) => {
    if (Array.isArray(summary) && summary.length > 0) {
      const firstSummary = summary[0];
      return firstSummary.length > 62
        ? firstSummary.slice(0, 62) + " ..."
        : firstSummary;
    }
    return "No description available";
  };

  const formatDuration = (duration) => {
    const roundedDuration = parseFloat(duration).toFixed(1);
    return `${roundedDuration} min read`;
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="bookmark-cards" style={{ margin: "auto" }}>
        {bookmarks.length === 0 ? (
          <div style={{ margin: "auto" }}>
            <Failedgif message="No bookmarks Articles found" />
          </div>
        ) : (
          <div className="tab-cards" style={{ gap: ".82vw" }}>
            {bookmarks.map((bookmark) => {
              const article = bookmark.articles[0];
              const isBookmarked = bookmarks.some(
                (bookmark) => bookmark.articleId === article.id
              );
              return (
                <div
                  className="tab-card"
                  key={bookmark.id}
                  id={`#${article.category || ""}`}
                >
                  <Link to={`/article/${article.id}`}>
                    <div className="tab-card-img">
                      <img
                        src={article.webImage || "/path/to/default/image.jpg"}
                        alt={article.title || "Article image"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "/path/to/fallback/image.jpg";
                        }}
                      />
                    </div>
                    <div className="card-data-content">
                      <div className="tab-card-content">
                        <h3>{article.title || "Untitled Article"}</h3>
                        <p>{trimDesc(article.summary)}</p>
                      </div>
                    </div>
                  </Link>
                  <div>
                    <div className="tab-card-footer">
                      <p>
                        <FaClock /> {formatDuration(article.duration || "0")}
                      </p>
                      <p>
                        <BsCalendarDateFill />
                        {formatDate(article.date || new Date())}
                      </p>
                      <div className={`bookmark-container`}>
                        <button
                          className="bookmark-button"
                          disabled={isLoading}
                          onClick={() => handleToggleBookmark(article)}
                        >
                          <DogPettingIcon
                            name={isBookmarked ? "bookmarked" : "bookmark"}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {toast && <Toast message={toast} onClose={() => setToast(null)} />}
    </>
  );
}
