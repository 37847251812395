import React, { useState } from "react";
import "../Login/Login.css";
import TextField from "@mui/material/TextField";
import ConfirmVerification from "../ConfirmVerification/ConfirmVerification";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlertBox from "../../../../Components/Alert/Alert";
import axios from "../../../../axios";

import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import Axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import RegHeader from "../Registration/RegHeader/RegHeader";
import { useNavigate } from "react-router-dom";
import { LoginSocialFacebook } from "reactjs-social-login";
import { FacebookLoginButton } from "react-social-login-buttons";
import { useEffect } from "react";

function Signup({ setShowTogglebtn }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConPassword, setshowConPassword] = useState(false);
  const [openConfirmVF, setopenConfirmVF] = useState(false);
  const [googleAuthStatus, setgoogleAuthStatus] = useState(false);
  const [googleData, setgoogleData] = useState("");

  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() + 6 * 60 * 60 * 1000);

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    mobile: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process.env.REACT_APP_APP_ID,
        cookie: true,
        xfbml: true,
        version: "v12.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);
  const handleFacebookLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          window.FB.api(
            "/me",
            { fields: "id,name,email,picture,short_name,last_name" },
            async (fbResponse) => {
              console.log(fbResponse);
              dispatch({
                type: "socialData",
                payload: { googleData: fbResponse },
              });
              try {
                const resp = await axios.post("/auth/socialregister", {
                  email: fbResponse.email,
                  username: fbResponse.name,
                  password: fbResponse.id,
                  Login_os: null,
                  profileImage: fbResponse.picture.data.url,
                  loginType: "Facebook",
                });
                console.log(resp);
                if (resp.data.status == "200") {
                  Cookies.set("signupToken", resp.data.message.userInfo.token, {
                    expires: expirationDate,
                  });
                  if (resp.data.message.userExist) {
                    Cookies.set(
                      "loginToken",
                      resp.data.message.userInfo.token,
                      { expires: expirationDate }
                    );
                    dispatch({
                      type: "login",
                      login: { ...resp.data.message },
                    });
                    dispatch({
                      type: "cart_details",
                      cart_details: resp.data.message.cartdetails,
                    });
                    dispatch({ type: "refresh" });
                    window.location.reload();
                  } else {
                    dispatch({
                      type: "user",
                      payload: { signupData: resp.data.message.userInfo },
                    });
                    setgoogleAuthStatus(true);
                    setShowTogglebtn(false);
                  }
                }
              } catch (error) {
                console.log(error);
              }
            }
          );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email,public_profile" }
    );
  };
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        const res = await Axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );
        if (res.status == "200") {
          dispatch({ type: "socialData", payload: { googleData: res.data } });

          axios
            .post("/auth/socialregister", {
              email: res.data.email,
              username: res.data.name,
              password: res.data.sub,
              Login_os: null,
              profileImage: res.data.picture,
              loginType: "Google",
            })
            .then((resp) => {
              if (resp.data.status == "200") {
                Cookies.set("signupToken", resp.data.message.userInfo.token, {
                  expires: expirationDate,
                });
                if (resp.data.message.userExist == true) {
                  Cookies.set("loginToken", resp.data.message.userInfo.token, {
                    expires: expirationDate,
                  });
                  dispatch({ type: "login", login: { ...resp.data.message } });
                  dispatch({
                    type: "cart_details",
                    cart_details: resp.data.message.cartdetails,
                  });
                  dispatch({ type: "refresh" });
                  window.location.reload();
                  // navigate('/')
                } else {
                  dispatch({
                    type: "user",
                    payload: { signupData: resp.data.message.userInfo },
                  });
                  setgoogleAuthStatus(true);
                  setShowTogglebtn(false);
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickshowConPassword = () => setshowConPassword(!showConPassword);
const handleInputChange = (e) => {
  const { name, value } = e.target;

  // Restrict the first name field to only allow letters and spaces
  if (name === "firstName" && !/^[a-zA-Z\s]*$/.test(value)) {
    return; // Do not update state if invalid input
  }

  // Restrict the mobile number field to only allow digits
  if (name === "mobile" && !/^\d*$/.test(value)) {
    return; // Do not update state if invalid input
  }

  setFormData((prevData) => ({
    ...prevData,
    [name]: value,
  }));
};

const handleSubmit = (e) => {
  e.preventDefault();

  // Check for empty fields
  if (
    formData.email.trim() === "" ||
    formData.firstName.trim() === "" ||
    formData.mobile.trim() === "" ||
    formData.password.trim() === "" ||
    formData.confirmPassword.trim() === ""
  ) {
    setopenAlert(!openAlert);
    setErrMessage("All fields are required");
    return;
  }

  // Email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {
    setopenAlert(!openAlert);
    setErrMessage("Invalid email format.");
    return;
  }

  // Domain validation
  const validDomains = [
    "gmail.com",
    "yahoo.com",
    "outlook.com",
    "hotmail.com",
    "aol.com",
    "protonmail.com",
    "zoho.com",
    // Add more valid domains as needed
  ];

  const commonMisspellings = {
    "gamil.com": "gmail.com",
    "yaho.com": "yahoo.com",
    "hotmial.com": "hotmail.com",
    "outlooko.com": "outlook.com",
    "protonmail.con": "protonmail.com",
    "zohoo.com": "zoho.com",
    // Add more common misspellings as needed
  };

  const emailDomain = formData.email.split("@")[1];

  // Check if the domain is valid or if it has common misspellings
  if (!validDomains.includes(emailDomain) && !commonMisspellings[emailDomain]) {
    setopenAlert(!openAlert);
    setErrMessage("Invalid email domain. Please use a valid email provider.");
    return;
  }

  // Check for common misspellings
  if (commonMisspellings[emailDomain]) {
    setopenAlert(!openAlert);
    setErrMessage(`Did you mean ${commonMisspellings[emailDomain]}?`);
    return;
  }

  // First Name Validation: No special characters or numbers allowed
  const nameRegex = /^[a-zA-Z\s]+$/; // Only allows letters and spaces
  if (!nameRegex.test(formData.firstName)) {
    setopenAlert(!openAlert);
    setErrMessage("First name can only contain letters.");
    return;
  }

  // Mobile Number Validation: Only digits and exactly 10 digits
  const mobileRegex = /^\d{10}$/; // Only allows 10 digits
  if (!mobileRegex.test(formData.mobile)) {
    setopenAlert(!openAlert);
    setErrMessage("Invalid mobile number. It must be 10 digits.");
    return;
  }

  // Password validation
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
  if (!passwordRegex.test(formData.password)) {
    setopenAlert(!openAlert);
    setErrMessage(
      "Password must have 8+ characters with uppercase, lowercase, number, and special characters."
    );
    return;
  }

  if (formData.password !== formData.confirmPassword) {
    setopenAlert(!openAlert);
    setErrMessage("Passwords do not match.");
    return;
  }

  // **************API call************
  axios
    .post("/auth/register", { ...formData })
    .then((response) => {
      console.log(response.data);
      if (response.data.status == "402") {
        setopenAlert(!openAlert);
        setErrMessage(response.data.message);
        return;
      }
      if (response.data.status == "200") {
        Cookies.set("signupToken", response.data.message.userInfo.token, {
          expires: expirationDate,
        });
        dispatch({
          type: "login",
          payload: { login: null, details: response.data.message },
        });
        setopenConfirmVF(!openConfirmVF);
        setShowTogglebtn(false);
      }
    })
    .catch((err) => {
      setopenAlert(!openAlert);
      setErrMessage("Internal Server Error Occurred");
    });
};

  return (
    <>
      {openConfirmVF ? (
        <ConfirmVerification />
      ) : googleAuthStatus ? (
        <RegHeader />
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div className="new-user-form">
              <input
                className="textfield-size"
                id=""
                type="email"
                placeholder="Email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              {/* <TextField
                className='textfield-size'
                id=""
                type='email'
                label="Email"
                variant="outlined"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                InputProps={{ className:'textfield-inp' }}
                InputLabelProps={{ className:'textfield-label' }}
              /> */}

              <input
                className="textfield-size"
                id=""
                type="text"
                placeholder="First Name"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              {/* <TextField
                className='textfield-size'
                id=""
                type='text'
                label="firstName"
                variant="outlined"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
                InputProps={{ className:'textfield-inp' }}
                InputLabelProps={{ className:'textfield-label' }}
              /> */}
              {/* <input
                className="textfield-size"
                id=""
                type="text"
                placeholder="Username"
                variant="outlined"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              /> */}
              {/* <TextField
                className="textfield-size"
                id=""
                type="text"
                label="Username"
                variant="outlined"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
                InputProps={{ className: "textfield-inp" }}
                InputLabelProps={{ className: "textfield-label" }}
              /> */}
              <input
                className="textfield-size"
                id=""
                // type="number"
                placeholder="Phone Number"
                variant="outlined"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
              />
              {/* <TextField
                className="textfield-size"
                id=""
                type="number"
                label="Phone Number"
                variant="outlined"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
                InputProps={{ className: "textfield-inp" }}
                InputLabelProps={{ className: "textfield-label" }}
              /> */}

              <div className="textfield-size">
                <div className="textfield-inp-wrapper">
                  <input
                    className="textfield-size"
                    id="password"
                    placeholder="Password"
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                  />
                  <span
                    className="password-icon"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                </div>
              </div>
              {/* <TextField
                className="textfield-size"
                id="password"
                label="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                InputLabelProps={{ className: "textfield-label" }}
                InputProps={{
                  className: "textfield-inp",
                  endAdornment: (
                    <>
                      {showPassword ? (
                        <FaEye
                          onClick={handleClickShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <Visibility />
                        </FaEye>
                      ) : (
                        <FaEyeSlash
                          onClick={handleClickShowPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <VisibilityOff />
                        </FaEyeSlash>
                      )}
                    </>
                  ),
                }}
              /> */}
              <div className="textfield-size">
                <div className="textfield-inp-wrapper">
                  <input
                    className="textfield-size"
                    id="confirmPassword"
                    placeholder="Confirm password"
                    variant="outlined"
                    type={showConPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleInputChange}
                  />
                  <span
                    className="password-icon"
                    onClick={handleClickshowConPassword}
                  >
                    {showPassword ? (
                      <FaEye style={{ cursor: "pointer" }} />
                    ) : (
                      <FaEyeSlash style={{ cursor: "pointer" }} />
                    )}
                  </span>
                </div>
              </div>
              {/* <TextField
                className="textfield-size"
                id="confirmPassword"
                label="Confirm password"
                variant="outlined"
                type={showConPassword ? "text" : "password"}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                InputLabelProps={{ className: "textfield-label" }}
                InputProps={{
                  className: "textfield-inp",
                  endAdornment: (
                    <>
                      {showConPassword ? (
                        <FaEye
                          onClick={handleClickshowConPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <Visibility />
                        </FaEye>
                      ) : (
                        <FaEyeSlash
                          onClick={handleClickshowConPassword}
                          style={{ cursor: "pointer" }}
                        >
                          <VisibilityOff />
                        </FaEyeSlash>
                      )}
                    </>
                  ),
                }}
              /> */}
              <button type="submit">Register</button>
            </div>
          </form>
          <div className="loginsocial" style={{ marginTop: "1px" }}>
            <hr className="line" />
            <p>continue with</p>
            <hr className="line" />
          </div>
          <div className="socialinks2">
            <img
              style={{ cursor: "pointer" }}
              onClick={handleFacebookLogin}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Frame.png"
              alt="facebook"
            />
            <img
              style={{ cursor: "pointer" }}
              onClick={() => login()}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Frame(1).png"
              alt="google"
            />
            <img
              style={{ cursor: "pointer" }}
              src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/regImg/Vector.png"
              alt="apple"
            />
          </div>
        </>
      )}
      {openAlert ? (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default Signup;
