import React, { useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import "./ProductActions.css";

export default function Prescription({ isClose, isOpen }) {
  const [isClosing, setIsClosing] = useState(false);
  const [checkedOption, setCheckedOption] = useState(null); // Track which option is selected

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      isClose();
    }
  };

  const handleClose = () => {
    isClose();
    // setIsClosing(true);
    // setTimeout(() => {
    //   setIsClosing(false);
    //   isClose();
    // }, 1000);
  };

  // Handle checkbox selection, ensuring only one is checked at a time
  const handleCheckboxChange = (option) => {
    setCheckedOption(option);
  };

  return (
    <div>
      <div
        className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
        onClick={handleOverlayClick}
      >
        <div className={`weekly-modal-container ${isClosing ? "close" : ""}`}>
          <div className="weight-picker-title">
            <DogPettingIcon name={"prescription-title"} />
            <h2>Prescription</h2>
          </div>
          <div className="shopcart-prescription">
            <h3>
              You didn't attach prescription, all medicines will move to
              Wishlist?
            </h3>
            <div className="checkbox-shopcart-prescription">
              <label className="checkbox-shopcart-prescriptioncontainer">
                <input
                  type="checkbox"
                  checked={checkedOption === "yes"}
                  onChange={() => handleCheckboxChange("yes")}
                />
                <span> YES, Checkout without medicine. </span>
              </label>
              <label className="checkbox-shopcart-prescriptioncontainer">
                <input
                  type="checkbox"
                  checked={checkedOption === "no"}
                  onChange={() => handleCheckboxChange("no")}
                />
                <span> NO, Go back to cart</span>
              </label>
            </div>
          </div>
          <div className="prescription-confirmbuttons">
            <button className="shop-cart-button" onClick={handleClose}>
              <DogPettingIcon name="check" />
              &ensp; Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
