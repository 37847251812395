import React, { useState } from "react";
import DogPettingIcon from "../../../Pages/Homepage/DogPettingIcon";
import "./ProductActions.css";

export default function WishlistItems({ items }) {
  const [cartData, setCartData] = useState(items);

  const handleWeightChange = (id, selectedWeight) => {
    const newCartData = cartData.map((item) => {
      if (item.id === id) {
        const weightOption = item.weightOptions.find(
          (option) => option.value === selectedWeight
        );
        return {
          ...item,
          selectedWeight,
          price: item.basePrice * weightOption.priceMultiplier, // Update price based on multiplier
        };
      }
      return item;
    });
    setCartData(newCartData);
  };

  return (
    <div className="shopcart-item">
      <div className="shop-cart-items">
        {cartData.map((item) => (
          <div className="shop-cart-item" key={item.id}>
            <div className="shop-cart-item-image">
              <img src={item.src} alt={item.name} />
            </div>
            <div className="shop-cartitemdetails">
              <div>
                <h3>{item.name}</h3>
                <p>{item.description}</p>
              </div>
              <div className="shop-cart-item-price-quantity wishtocart-price">
                {/* Dropdown for selecting quantity (weight) */}
                <select
                  className="shop-cart-item-weight"
                  value={item.selectedWeight}
                  onChange={(e) => handleWeightChange(item.id, e.target.value)}
                >
                  {item.weightOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <p>{item.price}</p>
              </div>
            </div>
            <div>
              {item.category == "Vaccines" ? (
                <button className="shop-wishtocart">
                  <DogPettingIcon name={"shop-wishtobook"} /> &nbsp; Book
                </button>
              ) : (
                <button className="shop-wishtocart">
                  <DogPettingIcon name={"shop-wishtoaddcart"} /> &nbsp; Add
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
