import React, { useEffect, useState } from "react";
import "./Petprofile.css";
import Header from "../../../Components/Header/Header";
import Homenav from "../../../Components/HomeNav/Homenav";
import Cookies from "js-cookie";
import axios from "../../../axios";
import Overview from "./Overview/Overview";
import Records from "./Records/Records";
import Settings from "./Settings/Settings";
import Gallery from "./Gallery/Gallery";
import DogPettingIcon from "../../Homepage/DogPettingIcon";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { TbRefresh, TbCamera } from "react-icons/tb";
import { IoDocumentText } from "react-icons/io5";
import { LuUpload } from "react-icons/lu";
import { AiFillFilePdf } from "react-icons/ai";
import { useSelector } from "react-redux";
import animatedDog from "../../../assets/animated-dog.avif";
import { IoMdImages } from "react-icons/io";
import Success from "../Success/Success";
import { FaCheckCircle } from "react-icons/fa";
import { AddPetModal } from "./Settings/AddPetModal";

export default function Petprofile() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeCategory, setActiveCategory] = useState("Overview");
  const [sliderPosition, setSliderPosition] = useState(0);
  const [sliderWidth, setSliderWidth] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [petName, setPetName] = useState("");
  const [petDescription, setPetDescription] = useState("");
  const [profileimg, setProfileimg] = useState("");
  const [selectedBackground, setSelectedBackground] = useState("");
  const [showBackgroundSelection, setShowBackgroundSelection] = useState(false);
  const [showedit, setShowedit] = useState(false);
  const [showUploadDocuments, setShowUploadDocuments] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [backgroundImages, setBackgroundImages] = useState([]);
  const [isClosing, setIsClosing] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const loginToken = Cookies.get("loginToken");

  const { login } = useSelector((state) => state);
  const petInfo = login.details.petInfo;
  console.log("pets1: ", petInfo);

  console.log("pets: ", login);

  const currentPet = petInfo[currentIndex] || {};
  const currentPetId = currentPet.id;
  console.log("currentPetId: ", currentPetId);

  const selectedBackgroundUrl =
    currentPet.petBackgroundImage ||
    "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/patterns020422_19-_Converted_+3.svg";

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseEditModal();
    }
  };
  useEffect(() => {
    if (uploadStatus) {
      const timer = setTimeout(() => {
        setUploadStatus(null);
        setShowUploadDocuments(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [uploadStatus]);

  useEffect(() => {
    const fetchBackgroundImages = async () => {
      try {
        const response = await axios.get("/petinfo/getbackgroundimages", {
          headers: {
            Authorization: loginToken,
            "Content-Type": "application/json",
          },
        });
        if (Array.isArray(response.data.message)) {
          setBackgroundImages(response.data.message.map((image) => image.url));
          console.log(
            "Background images received:",
            response.data.message.map((image) => image.url)
          );
        } else {
          console.error("Unexpected data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching background images:", error);
      }
    };

    fetchBackgroundImages();
  }, [loginToken]);

  const handlePrev = () => {
    const newIndex = (currentIndex - 1 + petInfo.length) % petInfo.length;
    setCurrentIndex(newIndex);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % petInfo.length);
  };

  const handleCategoryChange = (category, index) => {
    setActiveCategory(category);
    setSliderPosition(index);
  };

  useEffect(() => {
    const categories = ["Overview", "Records", "Gallery", "Settings"];
    const activeIndex = categories.indexOf(activeCategory);
    setSliderPosition(activeIndex);
    const numItems = categories.length;
    const widthPercentage = 100 / numItems;
    setSliderWidth(widthPercentage);
  }, [activeCategory]);

  const navItems = [
    { label: "Overview", key: "Overview" },
    { label: "Records", key: "Records" },
    { label: "Gallery", key: "Gallery" },
    { label: "Settings", key: "Settings" },
  ];

  const handleOpenEditModal = () => {
    setShowEditModal(true);
  };
  const openEditModal = (index) => {
    setCurrentIndex(index);
    setPetName(currentPet?.petName);
    setPetDescription(currentPet?.bio);
    setProfileimg(currentPet?.img);
    setSelectedBackground(currentPet?.petBackgroundImage);
    setShowEditModal(true);
  };

  const handleCloseEditModal = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      setShowEditModal(false);
      setShowBackgroundSelection(false);
      setShowUploadDocuments(false);
      setUploadStatus("");
    }, 1000);
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.patch(
        `/petinfo/updatepetprofile/${currentPet.id}`,
        {
          petProfileImage: currentPet.petProfileImage,
          petName: petName,
          bio: petDescription,
          petBackgroundImage: currentPet.petBackgroundImage,
        },
        {
          headers: {
            Authorization: loginToken,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Profile updated successfully:", response.data);
      const updatedPetInfo = [...petInfo];
      updatedPetInfo[currentIndex].petName = petName;
      updatedPetInfo[currentIndex].bio = petDescription;
      updatedPetInfo[currentIndex].img = profileimg;
      updatedPetInfo[currentIndex].selectedBackground = selectedBackground;
      setShowedit(updatedPetInfo);
      setShowEditModal(false);
      setShowBackgroundSelection(false);
    } catch (error) {
      console.error("Error updating pet profile:", error);
    }
  };

  const handlePetNameChange = (e) => {
    setPetName(e.target.value);
  };

  const handlePetDescriptionChange = (e) => {
    setPetDescription(e.target.value);
  };

  const handleClose = () => {
    setShowBackgroundSelection();
  };

  const handleBackgroundChange = (backgroundUrl) => {
    const updatedPetInfo = [...petInfo];
    currentPet.petBackgroundImage = backgroundUrl;
    setShowedit(updatedPetInfo);
    setSelectedBackground(backgroundUrl);
  };
  const handleSelectBackground = () => {
    setShowBackgroundSelection(true);
    setShowUploadDocuments(false);
  };
  const handleConfirmBackground = () => {
    setShowBackgroundSelection(false);
  };
  const handleCameraClick = () => {
    setShowUploadDocuments(true);
    setShowBackgroundSelection(false);
  };
  const handleBackToDetails = () => {
    setShowUploadDocuments(false);
    setUploadedFile(null);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 3 * 1024 * 1024) {
      setUploadedFile(file);
      setUploadStatus(null);
    } else {
      alert("File size exceeds 3MB");
    }
  };
  const uploadFileToS3 = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.post("/file/uploadFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: loginToken,
        },
      });
      console.log("File uploaded to S3:", response.data);

      return response.data.Message ? response.data.Message.getPeteye : "";
    } catch (error) {
      console.error("Error uploading image to S3:", error);
      return null;
    }
  };

  const handleUpload = async () => {
    if (uploadedFile) {
      const url = await uploadFileToS3(uploadedFile);
      if (url) {
        const updatedPetInfo = [...petInfo];
        updatedPetInfo[currentIndex].petProfileImage = url;
        setShowedit(updatedPetInfo);
        setProfileimg(url);
        setUploadStatus("success");
        setUploadedFile(null);
        setShowBackgroundSelection(false);
        setShowUploadDocuments(false);
      } else {
        setUploadStatus("failed");
      }
    } else {
      setUploadStatus("failed");
    }
  };

  const handleFileEdit = () => {
    setUploadedFile(null);
    document.getElementById("file-input").click();
  };
  const handleuserclose = () => {
    if (isOpen) {
      setIsOpen(false);
    }
  };

  return (
    <div onClick={handleuserclose}>
      <section style={{ marginBottom: "15vh" }}>
        <Header isOpen={isOpen} setIsOpen={setIsOpen} />
      </section>
      {/* <section className="homepage-navspace">
        <Homenav />
      </section> */}
      <section className="profile-pet-home">
        {petInfo.length > 0 ? (
          <>
            <div
              className="profilehome-leftpart"
              style={{
                backgroundImage: `url(${
                  currentPet.petBackgroundImage ||
                  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/patterns020422_19-_Converted_+3.svg"
                })`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div
                className="profile-edit"
                onClick={() => openEditModal(currentIndex)}
              >
                <DogPettingIcon name="edit" />
              </div>
              <div className="carousel-container">
                {petInfo.map(
                  (details, index) =>
                    index === currentIndex && (
                      <div key={details?.id} className="profile-details">
                        <div className="indicators">
                          <button
                            onClick={handlePrev}
                            className="carousel-button left"
                          >
                            <svg
                              width="3vw"
                              height="5vh"
                              viewBox="0 0 30 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="0.5"
                                width="30"
                                height="30"
                                rx="6"
                                fill="#E9E9E9"
                              />
                              <path
                                d="M18 10L12 16L18 22"
                                stroke="#B2B2B2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <div className="profile-image-container">
                            <img
                              src={details?.petProfileImage || animatedDog}
                              alt={details?.petName}
                              title={details?.petName}
                            />
                          </div>
                          <button
                            onClick={handleNext}
                            className="carousel-button right"
                          >
                            <svg
                              width="3vw"
                              height="5vh"
                              viewBox="0 0 30 31"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                y="0.5"
                                width="30"
                                height="30"
                                rx="6"
                                fill="#E9E9E9"
                              />
                              <path
                                d="M12 22L18 16L12 10"
                                stroke="#A4A4A4"
                                stroke-opacity="0.8"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            background:
                              "linear-gradient(to top, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6) 50%, rgba(255, 255, 255, 0) 100%)",
                            position: "absolute",
                            top: "70%",
                            width: "100%",

                            borderRadius: "1.5vw",
                            gap: ".5vw",
                          }}
                        >
                          <h3
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {details?.petName}
                          </h3>
                          <div className="dog-loc">
                            <h5
                              style={{
                                textTransform: "capitalize",
                              }}
                            >
                              {details?.petBreed}
                            </h5>
                            <h5>{details?.location || "Hyderabad"}</h5>
                          </div>
                          <p>
                            {details?.bio ||
                              `I’m ${details?.petName}, a playful, friendly ${details?.petBreed}. I love outdoor adventures and belly rubs`}
                          </p>
                        </div>
                      </div>
                    )
                )}
                {showEditModal && (
                  <div
                    className={`modal-overlay ${isClosing ? "fade-out" : ""}`}
                    onClick={handleCloseEditModal}
                  >
                    <div
                      className={`modal-container ${isClosing ? "close" : ""}`}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="profile-edit-sec">
                        {!showBackgroundSelection &&
                          !showUploadDocuments &&
                          !uploadStatus && (
                            <form className="profile-edit-form">
                              <img
                                src={currentPet?.petProfileImage || animatedDog}
                                className="profile-edit-sec-bg"
                                alt={currentPet.petName}
                                title="Pet"
                              />
                              <div className="profile-edit-sec-cameraimg-container">
                                <TbCamera
                                  className="camera-icon"
                                  onClick={handleCameraClick}
                                />
                              </div>
                              <div
                                className="modal-close"
                                onClick={handleCloseEditModal}
                              >
                                <DogPettingIcon
                                  name={"closemodal"}
                                  className="modal-close-icon"
                                />
                              </div>
                              {/* <p className="modal-close">
                                <AiOutlineCloseCircle
                                  className="modal-close-icon"
                                  onClick={handleCloseEditModal}
                                />
                              </p> */}
                              <input
                                type="text"
                                placeholder="Add Pet Name"
                                value={petName}
                                onChange={(e) => setPetName(e.target.value)}
                              />
                              <input
                                type="text"
                                placeholder="Add Pet Bio"
                                value={petDescription}
                                onChange={(e) =>
                                  setPetDescription(e.target.value)
                                }
                              />
                              <div
                                className="profile-edit-selectbg"
                                onClick={handleSelectBackground}
                              >
                                <p
                                  style={{
                                    marginRight: "auto",
                                  }}
                                >
                                  Select background
                                </p>
                              </div>
                              <button
                                type="button"
                                className="profile-edit-savebutton"
                                onClick={handleSaveChanges}
                              >
                                <TbRefresh /> Save Changes
                              </button>
                            </form>
                          )}
                        {uploadStatus && (
                          <div className="upload-status-message">
                            {uploadStatus === "success" ? (
                              // <div className="uploaded-success">
                              //   <img
                              //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                              //     alt="Upload successful"
                              //   />
                              //   <p>Upload successfully!</p>
                              // </div>
                              <Success
                                message="Upload successfully!"
                                header="Success"
                                gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/profile/success.gif"
                              />
                            ) : (
                              // <div className="uploaded-success">
                              //   <img
                              //     src="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                              //     alt="Upload failed"
                              //   />
                              //   <p>Oops! No files found.</p>
                              // </div>
                              <Success
                                message="Oops! No files found."
                                header="Failed"
                                gif="https://peteye-drive.s3.ap-south-1.amazonaws.com/Supportwebp/DrowningDog.webp"
                              />
                            )}
                          </div>
                        )}
                        {showUploadDocuments && (
                          <div className="add-new-documents-content">
                            <div className="records-Upload-title">
                              <p>
                                <IoDocumentText
                                  style={{
                                    fontSize: "clamp(1.5rem, 1.5vw, 4.5rem",
                                  }}
                                />
                              </p>
                              <h2>Upload Documents</h2>
                            </div>

                            <div
                              className="modal-close"
                              onClick={handleBackToDetails}
                            >
                              <DogPettingIcon
                                name={"closemodal"}
                                className="modal-close-icon"
                              />
                            </div>
                            <div className="upload-doc-files">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={{ display: "none" }}
                                id="file-input"
                              />

                              <label htmlFor="file-input">
                                <p>
                                  <DogPettingIcon name="upload-doc" />
                                </p>
                                <div className="upload-doc-files-title">
                                  <h5>
                                    <LuUpload /> <h6>Upload</h6>
                                  </h5>
                                  <p>Maximum Size: 3MB</p>
                                </div>
                              </label>
                            </div>
                            {uploadedFile && (
                              <div className="uploaded-preview-list">
                                <div className="uploaded-file-preview">
                                  <div className="uploaded-file-preview-title">
                                    <h4>
                                      <IoMdImages
                                        style={{
                                          fontSize: "clamp(2rem , 1.5vw, 4rem",
                                        }}
                                      />
                                    </h4>
                                    <div className="uploaded-file-preview-name">
                                      <h5>{uploadedFile.name}</h5>
                                      <p>
                                        {(
                                          uploadedFile.size /
                                          1024 /
                                          1024
                                        ).toFixed(2)}{" "}
                                        MB
                                      </p>
                                    </div>
                                  </div>
                                  <div
                                    className="uploaded-file-preview-edit"
                                    onClick={() => console.log("Edit File")} // Replace with your handler
                                    style={{ cursor: "pointer" }}
                                  >
                                    <DogPettingIcon name="edit" />
                                  </div>
                                </div>
                              </div>
                            )}
                            {uploadedFile && (
                              <div
                                className="upload-file-btn"
                                onClick={handleUpload}
                              >
                                <p>
                                  <LuUpload
                                    style={{
                                      fontSize: "clamp(1.3rem, 1.5vw,4rem)",
                                    }}
                                  />
                                </p>
                                <p>Upload</p>
                              </div>
                            )}
                          </div>
                        )}
                        {showBackgroundSelection && (
                          <div className="background-selection-overlay">
                            <div className="background-selection-container">
                              <div className="select-background-title">
                                <h2>Select Background</h2>
                              </div>
                              <div
                                className="modal-close"
                                onClick={handleClose}
                              >
                                <DogPettingIcon
                                  name={"closemodal"}
                                  className="modal-close-icon"
                                />
                              </div>
                              <div className="background-options">
                                {backgroundImages.map((url, idx) => (
                                  <div key={idx} className="background-option">
                                    <img
                                      key={idx}
                                      src={url}
                                      alt={`Background ${idx + 1}`}
                                      title={`Background ${idx + 1}`}
                                      onClick={() =>
                                        handleBackgroundChange(url)
                                      }
                                      className={
                                        selectedBackground === url
                                          ? "selected"
                                          : ""
                                      }
                                    />
                                    {selectedBackground === url && (
                                      <div className="check-icon">
                                        <FaCheckCircle
                                          style={{
                                            fontSize:
                                              "clamp(1rem, 1.5vw, 5rem)",
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                              <button
                                className="profile-edit-confirm-button"
                                onClick={handleConfirmBackground}
                              >
                                <DogPettingIcon name={"check"} /> Confirm
                              </button>
                            </div>
                          </div>
                        )}
                        {/* Background Selection UI */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="profilehome-rightpart">
              <div className="profilehome-rightpart-header">
                <div className="profilehome-rightpart-sliderdiv">
                  <div
                    className="profileslider"
                    style={{
                      transform: `translateX(${sliderPosition * 100}%) `,
                      width: "25%",
                      transition: "transform 0.3s ease",
                    }}
                  />
                  {navItems.map((item, index) => (
                    <p
                      key={item.key}
                      className={`top-nav-link2 ${
                        activeCategory === item.key ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange(item.key, index)}
                    >
                      {item.label}
                    </p>
                  ))}
                </div>
              </div>
              <div className="profilehome-rightpart-content">
                {activeCategory === "Overview" && (
                  <Overview
                    petInfo={petInfo[currentIndex]}
                    login={login}
                    currentPetId={currentPetId}
                  />
                )}
                {activeCategory === "Records" && (
                  <Records petId={petInfo[currentIndex]} />
                )}
                {activeCategory === "Gallery" && <Gallery />}
                {activeCategory === "Settings" && <Settings />}
              </div>
            </div>
          </>
        ) : (
          <div
            className="no-pets-message"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "auto",
            }}
          >
            <h2>No pets found</h2>
            <p>
              You haven't added any pets yet. Add a pet to get started by
              deleting the account and creating a new One!
            </p>
            {/* Add a button or link to add a new pet */}
          </div>
        )}
      </section>
      {/* <section>
        <Homenav />
      </section> */}
    </div>
  );
}
