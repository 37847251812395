import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import "./PetOwnerDetails.css";
import axios from "../../../../../axios";
import Axios from "axios";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import SimpleBackdrop from "../../../../../Components/Backdrop/Backdrop";
import { OwnervalidateForm } from "./OwnerValidation";
import AlertBox from "../../../../../Components/Alert/Alert";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
const location_img =
  "https://peteye-drive.s3.ap-south-1.amazonaws.com/Web-App+Images/Home/location_535239.png";

function PetOwnerDetails({ setopenPetInfo, setownerComplete, setopenOwner }) {
  const { socialData, login } = useSelector((state) => state);
  const googleData = socialData?.googleData || "";
  const sigupData = login?.details || "";
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [nationality, setNationality] = useState({ Name: "" });
  const [openBackdrop, setopenBackdrop] = useState(false);
  const [openAlert, setopenAlert] = useState(false);
  const [open, setOpen] = useState(true);
  const [errMessage, setErrMessage] = useState("");

  console.log("signupData", sigupData);

  const [formData, setFormData] = useState({
    firstName:
      googleData?.given_name ||
      sigupData?.userInfo?.firstName ||
      googleData?.short_name ||
      "",
    Mobilenumber: sigupData?.userInfo?.mobile || "",
    lastName: googleData?.family_name || googleData?.last_name || "",
    SecondaryMobilenumber: "",
    State: "",
    City: "",
    Address2: "",
    Pincode: "",
    District: "",
    Area: "",
    HouseFloorNo: "",
  });

  // ********** Form Submission ************

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      let Nationality = nationality?.Name;
      let userInfo = { ...formData, Nationality };
      let formValidation = OwnervalidateForm(userInfo);
      if (formValidation == true) {
        dispatch({ type: "userInfo", payload: { userInfo: userInfo } });
        setopenPetInfo(true);
        setownerComplete(true);
        setopenOwner(false);
      } else {
        setopenAlert(!openAlert);
        setErrMessage(formValidation);
        return;
      }
    } catch (error) {
      console.log(error);
      setopenAlert(!openAlert);
      setErrMessage("error occured");
      return;
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (
      name === "Pincode" &&
      value.length === 6 &&
      nationality?.Name == "India"
    ) {
      fetchPostalCode(value);
      setopenBackdrop(true);
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = () => {
    axios
      .get("/user/country")
      .then((response) => {
        const countriesData = response.data.message;
        // Find the index of India in the countries data
        const indiaIndex = countriesData.findIndex(
          (country) => country.Name === "India"
        );
        // If India is found, move it to the beginning of the array
        if (indiaIndex !== -1) {
          const updatedCountries = [
            countriesData[indiaIndex],
            ...countriesData.slice(0, indiaIndex),
            ...countriesData.slice(indiaIndex + 1),
          ];
          setCountries(updatedCountries);
        } else {
          setCountries(countriesData); // Set the countries as they are
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchPostalCode = (code) => {
    Axios.get(`https://api.postalpincode.in/pincode/${code}`)
      .then((response) => {
        console.log(response.data[0]?.PostOffice[0]);
        const postalData = response?.data[0]?.PostOffice[0];
        setFormData((prevData) => ({
          ...prevData,
          City: postalData?.District || "",
          State: postalData?.State || "",
          District: postalData?.District || "",
          Area: postalData?.Name || "",
          Pincode: code,
        }));
        setopenBackdrop(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="reg-body">
          {/* <div className="reg-body-sub1"> */}
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="First name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="First name"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          /> */}

          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="Mobile number"
            variant="outlined"
            name="Mobilenumber"
            value={formData.Mobilenumber}
            onChange={handleInputChange}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="Mobile number"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="Mobilenumber"
            value={formData.Mobilenumber}
            onChange={handleInputChange}
          /> */}
          {/* </div>
          <div className="reg-body-sub2"> */}

          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="Last name"
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="Last name"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          /> */}
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="2nd Mobile number"
            variant="outlined"
            name="SecondaryMobilenumber"
            value={formData.SecondaryMobilenumber}
            onChange={handleInputChange}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="2nd Mobile number"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="SecondaryMobilenumber"
            value={formData.SecondaryMobilenumber}
            onChange={handleInputChange}
          /> */}
          {/* </div> */}
          {/* </div> */}
          {/* <div className="location-sec">
          <div className="location-sec-inner-div">
            <img src={location_img} alt="location" />
            <p>Address</p>
          </div>
        </div> */}

          {/* <div className="reg-body-2">
          <div className="reg-body-sub1"> */}

          {/* <div className="textfield-size">
            <label
              htmlFor="nationality-select"
              className="textfield-label"
            ></label>
            <select
              id="nationality-select"
              name="nationality"
              value={nationality}
              onChange={(e, newValue) => setNationality(newValue)}
              className="custom-select"
            >
              <option value="" disabled>
                Choose a country
              </option>
              {countries.map((country) => (
                <option key={country.Code} value={country.Name}>
                  {country.Name}
                </option>
              ))}
            </select>
          </div> */}

          <Autocomplete
            id="country-select-demo"
            sx={{ width: "25vw" }}
            options={countries}
            autoHighlight
            getOptionLabel={(option) => option.Name}
            renderOption={(props, option) => (
              <Box
                sx={{ fontSize: "clamp(0.8rem, 0.9vw, 2.2rem)" }}
                component="li"
                {...props}
              >
                {option.Name}
              </Box>
            )}
            value={nationality}
            onChange={(e, newValue) => setNationality(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                name="nationality"
                placeholder="Choose a country"
                InputProps={{
                  ...params.InputProps,
                  style: {
                    fontSize: "14px",
                    border: "none",
                    outline: "none",
                    padding: "0px",
                    margin: "0px",
                    display: "flex",
                    alignItems: "center",
                  },
                  disableUnderline: true, // This removes the underline
                }}
                sx={{
                  ".MuiOutlinedInput-root": {
                    "& fieldset": {
                      border: "none", // Remove the border
                    },
                  },
                  "& .MuiInputBase-root": {
                    "&:hover": {
                      "&:not(.Mui-disabled)": {
                        border: "none",
                      },
                    },
                    "&.Mui-focused": {
                      border: "none",
                    },
                  },
                }}
              />
            )}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="State"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="State"
            value={formData.State}
            onChange={handleInputChange}
          /> */}
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="City"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="City"
            value={formData.City}
            onChange={handleInputChange}
          /> */}
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="House / Floor no"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="HouseFloorNo"
            value={formData.HouseFloorNo}
            onChange={handleInputChange}
          /> */}
          {/* </div>
          <div className="reg-body-sub2"> */}
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="Pincode"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="Pincode"
            value={formData.Pincode}
            onChange={handleInputChange}
          /> */}
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="District"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="District"
            value={formData.District}
            onChange={handleInputChange}
          /> */}
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="Area"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="Area"
            value={formData.Area}
            onChange={handleInputChange}
          /> */}
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="Address "
            variant="outlined"
            name="Address2"
            value={formData.Address2}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="House / Floor no"
            variant="outlined"
            name="HouseFloorNo"
            value={formData.HouseFloorNo}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="Pincode"
            variant="outlined"
            name="Pincode"
            value={formData.Pincode}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="Area"
            variant="outlined"
            name="Area"
            value={formData.Area}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="City"
            variant="outlined"
            name="City"
            value={formData.City}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="District"
            variant="outlined"
            name="District"
            value={formData.District}
            onChange={handleInputChange}
          />
          <input
            className="textfield-size-reg"
            id=""
            type="text"
            placeholder="State"
            variant="outlined"
            name="State"
            value={formData.State}
            onChange={handleInputChange}
          />
          {/* <TextField
            className="textfield-size-reg"
            id=""
            type="text"
            label="Address 2"
            variant="outlined"
            InputProps={{ style: { height: "40px", fontSize: "14px" } }}
            InputLabelProps={{
              style: { fontSize: "14px", marginTop: "-4px" },
            }}
            name="Address2"
            value={formData.Address2}
            onChange={handleInputChange}
          /> */}
          {/* </div> */}
        </div>

        <div className="pet-owner-btn ">
          <button type="submit">Next</button>
        </div>
      </form>
      {openBackdrop ? (
        <SimpleBackdrop open={openBackdrop} setopenBackdrop={setopenBackdrop} />
      ) : (
        ""
      )}
      {openAlert ? (
        <AlertBox
          open={true}
          setOpen={setOpen}
          setopenAlert={setopenAlert}
          errMessage={errMessage}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default PetOwnerDetails;
